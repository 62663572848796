import { errorResponseHandler } from "network/error-handler"
import {
  acceptCollabRequest,
  declineCollabRequest,
  getCollabRequestsReceived,
  getCollabRequestsSent,
} from "network/social"
import { successResponseHandler } from "network/success-handler"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  ResponseInviteCollabUser,
  ResponseReceivedCollaborations,
  ResponseSentCollaborations,
} from "types"

export const useCollabRequestsReceivedService = () => {
  const queryClient = useQueryClient()

  return useQuery<ResponseReceivedCollaborations>(
    "received-collab-requests", // Cache key
    () => getCollabRequestsReceived(),
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData =
          queryClient.getQueryData<ResponseReceivedCollaborations>(
            "received-collab-requests"
          )
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const useCollabRequestsSentService = () => {
  const queryClient = useQueryClient()

  return useQuery<ResponseSentCollaborations>(
    "sent-collab-requests", // Cache key
    () => getCollabRequestsSent(),
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<ResponseSentCollaborations>(
          "sent-collab-requests"
        )
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const useAcceptCollabRequestService = () => {
  return useMutation(
    async (collabId: string) => {
      return acceptCollabRequest(collabId)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}

export const useDeclineCollabRequestService = () => {
  return useMutation(
    async (collabId: string) => {
      return declineCollabRequest(collabId)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}
