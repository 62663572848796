// import EmptyTopTracks from "assets/images/general/empty-top-tracks.svg";
import poprevLogo from "assets/images/general/poprev.png";
import poprevCoin from "assets/images/general/poprev_coin.png";
import uduxConcertLogo from "assets/images/general/udux_concert_logo.png";
import { ArrowCarousel, Button, FlexibleDiv, Section } from "components";
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import Banner from "components/lib/Banner/Banner";
import { Tags } from "components/lib/Tags/Tags";
import { Types, useMainContext } from "context";
import {
  getAllArtist,
  getAllTracks,
  getPlaylistsByQuery,
  instance,
} from "network";
import {
  useFetchPlaylists,
  useFetchPublicAlbum,
  useFetchPublicPlaylists,
} from "network/hooks";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import SwiperCore from "swiper";
import "swiper/css";
import {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Virtual,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import { HomeWrap } from "../home.styles";
import { TrackList } from "./track-list";
import { ArtisteList } from "./ArtisteList";

const tags = [
  {
    title: "Music",
  },
  {
    title: "Artist",
  },
  {
    title: "Playlist",
  },
];
export const HomeActivityPane = () => {
  const el = useRef<HTMLDivElement | null>(null);
  const { dispatch } = useMainContext();
  const [activeTag, setActiveTag] = useState<string>("Music"); // Default active tab is "Phone"
  const [activeSlide] = React.useState(1);
  const [swiper] = useState<SwiperCore | null>(null);
  SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, EffectFade]);
  const {
    state: { users },
  } = useMainContext();
  const userObject = useRef(
    JSON.parse(localStorage.getItem("udx__user") ?? "{}")
  );

  const isLoggedIn =
    Boolean(users?.me?.id) || Boolean(userObject?.current?.user?.id);

  // Function to handle tab changes
  const handleTagChange = (newTag: string) => {
    setActiveTag(newTag);
  };

  const { data: allTracks, isLoading: isTracksLoading } = useQuery(
    ["tracks"],
    getAllTracks
  );

  const { data: allArtists, isLoading: isArtistsLoading } = useQuery(
    ["artists"],
    getAllArtist
  );

  // Fetch playlists managed by ADMIN
  const { data: spData, isLoading: spIsLoading } = useFetchPublicPlaylists(
    ["playlists-admin"], // Unique cache key for admin playlists
    "managedBy=ADMIN" // Query to fetch admin playlists
  );

  // Fetch user-specific playlists with a fallback for user ID
  const { data: userSpData, isLoading: userSpIsLoading } = useQuery(
    ["playlists-user", users?.me?.id],
    () => getPlaylistsByQuery(`user=${users?.me?.id}`)
  );


  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 500);
    }
  }, [swiper, activeSlide]);

  // useEffect(() => {
  //   dispatch({ type: Types.UPDATE_USER_PLAYLISTS, payload: userSpData });
  // }, [userSpIsLoading]);

  // Fetch and store playlist categories in the global state
  useEffect(() => {
    // if (me?.isMTNRegistration) {
    //   setModal(true)
    // }
    const getCategories = async () => {
      try {
        const { data } = await instance.get(`/streaming/categories`);
        dispatch({ type: Types.GET_CATEGORIES, payload: data?.data });
      } catch (error) {
        // Handle the error gracefully
        console.error("Error fetching categories:", error);
        // Optionally dispatch an action or update state to handle UI fallback
        dispatch({ type: Types.GET_CATEGORIES, payload: [] }); // Send empty array if the fetch fails
      }
    };

    getCategories();
  }, [dispatch]);

  return (
    <PlayerLayout>
      <HomeWrap>
        <Section
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          ref={el}
        >
          {isLoggedIn && (
            <FlexibleDiv
              margin="0 0 20px 0"
              alignItems="flex-start"
              flexDir="column"
              justifyContent="flex-start"
            >
              <h4 className="user__mini__subheader">
                Welcome back,{" "}
                <span className="username">{users?.me?.username}</span>
              </h4>
              <Tags
                width={"fit-content"}
                setActiveTag={handleTagChange}
                activeTag={activeTag}
                tags={tags}
              />
            </FlexibleDiv>
          )}
          <Banner />
          <FlexibleDiv
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="playerhome__bodysection"
          >
            {/* Hot Artists */}
            {activeTag === "Artist" && (
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">Hot Artists</h4>
                  <Link to="/all-artists" className="see__more">
                    Show all{" "}
                  </Link>
                </FlexibleDiv>

                <ArrowCarousel
                  list={allArtists?.data}
                  slot={
                    <ArtisteList
                      loading={isArtistsLoading}
                      artists={allArtists?.data}
                    />
                  }
                />
              </FlexibleDiv>
            )}

            {/* Hot Playlists */}
            {activeTag === "Playlist" && (
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">Hot Playlists</h4>
                  <Link to="/all-playlists" className="see__more">
                    Show all{" "}
                  </Link>
                </FlexibleDiv>

                <ArrowCarousel
                  list={spData}
                  slot={<TrackList loading={spIsLoading} playlists={spData} />}
                />
              </FlexibleDiv>
            )}

            {/* Popular Tracks */}
            <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Popular Tracks</h4>
                <Link to="#" className="see__more">
                  Show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={allTracks?.data}
                slot={
                  <TrackList
                    size="small"
                    loading={isTracksLoading}
                    tracks={allTracks?.data}
                  />
                }
              />
            </FlexibleDiv>

            {/* Recommended Tracks */}
            <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Recommended Tracks</h4>
                <Link to="#" className="see__more">
                  show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={allTracks?.data}
                slot={
                  <TrackList
                    loading={isTracksLoading}
                    tracks={allTracks?.data}
                  />
                }
              />
            </FlexibleDiv>

            {/* My Playlists */}
            {isLoggedIn && userSpData && userSpData?.length > 0 && (
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">My Playlist</h4>
                  <Link to="#" className="see__more">
                    show all{" "}
                  </Link>
                </FlexibleDiv>

                <ArrowCarousel
                  list={userSpData}
                  slot={
                    <TrackList
                      loading={userSpIsLoading}
                      playlists={userSpData}
                    />
                  }
                />
              </FlexibleDiv>
            )}

            {/* Top uduX Playlists */}
            <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Top Udux Playlist</h4>
                <Link to="#" className="see__more">
                  show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={spData}
                slot={<TrackList loading={spIsLoading} playlists={spData} />}
              />
            </FlexibleDiv>

            {/* Top Collab Playlists */}
            <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Top Collab Playlist</h4>
                <Link to="#" className="see__more">
                  show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={spData}
                slot={<TrackList loading={spIsLoading} playlists={spData} />}
              />
            </FlexibleDiv>

            {/* Recommended Albums */}
            {/* <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Recommended Albums</h4>
                <Link to="#" className="see__more">
                  show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={albData}
                slot={<TrackList loading={albIsLoading} albums={albData} />}
              />
            </FlexibleDiv> */}

            {/* Nigerian charts */}
            <FlexibleDiv flexDir="column" alignItems="flex-start">
              <FlexibleDiv
                justifyContent="space-between"
                alignItems="flex-end"
                className="header__withlink"
              >
                <h4 className="mini__subheader">Nigerian charts</h4>
                <Link to="#" className="see__more">
                  show all{" "}
                </Link>
              </FlexibleDiv>

              <ArrowCarousel
                list={allTracks?.data}
                slot={
                  <TrackList
                    size="chart"
                    loading={isTracksLoading}
                    tracks={allTracks?.data}
                  />
                }
              />
            </FlexibleDiv>

            <div className="products">
              <Link
                className="poprev"
                target="_blank"
                to={"https://poprev.dev"}
              >
                <div className="poprev__content__left">
                  <div>
                    <div className="image__wrap">
                      <img src={poprevLogo} alt="" />
                    </div>
                    <p>Allow your audience to make profit with you.</p>
                  </div>
                  <Button className="poprev__btn">Find out more</Button>
                </div>
                <div className="poprev__coin__wrap">
                  <img src={poprevCoin} alt="" />
                </div>
              </Link>

              <Link
                className="udux__concerts"
                target="_blank"
                to={"https://uduxconcerts.com"}
              >
                <FlexibleDiv
                  flexDir="column"
                  alignItems="center"
                  className="concert__top"
                >
                  <div className="image__wrap">
                    <img src={uduxConcertLogo} alt="" />
                    <span className="sticky"></span>
                    <span className="coloured">Concerts</span>
                  </div>
                  <p>get Your Backstage Pass to </p>
                </FlexibleDiv>
                <h2>
                  Africa's <br /> Greatest Concerts
                </h2>
              </Link>
            </div>
          </FlexibleDiv>
        </Section>
      </HomeWrap>
    </PlayerLayout>
  );
};
