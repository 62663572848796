import { IconProps } from "types";

export const AppleIcon = ({
  color = "#fff",
  width = "37.547",
  height = "37.547",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2753 10.6924C24.1253 10.6924 22.7941 12.1955 20.6003 12.1955C18.3511 12.1955 16.6355 10.7033 13.9058 10.7033C11.2339 10.7033 8.38468 12.3346 6.57531 15.1135C4.03468 19.0322 4.46593 26.4127 8.58077 32.7002C10.0526 34.951 12.0183 37.4752 14.5964 37.5025H14.6433C16.8839 37.5025 17.5495 36.0354 20.6331 36.0182H20.68C23.7175 36.0182 24.3269 37.4939 26.5581 37.4939H26.605C29.1831 37.4666 31.2542 34.6697 32.7261 32.4275C33.7855 30.815 34.1792 30.0057 34.9917 28.1814C29.0394 25.9221 28.0831 17.4838 33.9698 14.2486C32.173 11.9986 29.648 10.6955 27.2675 10.6955L27.2753 10.6924Z"
      fill={color}
    />
    <path
      d="M26.5823 2.5C24.7073 2.62734 22.5198 3.82109 21.2385 5.37969C20.076 6.79219 19.1198 8.8875 19.4948 10.9195H19.6448C21.6417 10.9195 23.6854 9.71719 24.8792 8.17656C26.0292 6.71016 26.901 4.63203 26.5823 2.5Z"
      fill={color}
    />
  </svg>
);
