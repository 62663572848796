import { IoMdPlay } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { nowPlaying, pauseAudio, playAudio, truncate } from "utils";
import { FlexibleDiv } from "../../Box/styles";
import { PlaylistCardWrap } from "./playlist-card.styles";
import { DominantColors, useMainContext } from "context";
import { memo } from "react";
import { PausePlayingIcon, PlayIcon } from "assets";
import { PlaylistType } from "types/playlist";
import { Picture } from "types";

export type PlaylistCardProps = {
  id?: string;
  coverArt?: Picture;
  coverArtDominantColors?: DominantColors;
  featuredArtists?: Array<string>;
  description?: string;
  title?: string;
  type?: PlaylistType;
};

export const PlaylistCard = memo(({ ...props }: PlaylistCardProps) => {
  const {
    id,
    coverArt,
    coverArtDominantColors,
    featuredArtists,
    description,
    title,
    type,
  } = props;
  const {
    state: { player },
    playerRef,
    dispatch,
  } = useMainContext();

  const { playingList, isPlaying, isPaused } = player;
  const showPausePlay = isPlaying && playingList.id === id;

  const navigate = useNavigate();

  return (
    <PlaylistCardWrap
      dominantColors={coverArtDominantColors?.map((cadc) =>
        cadc.startsWith("#") ? cadc : `#${cadc}`
      )}
    >
      <FlexibleDiv className="card__play__btn">
        <div className="icon__wrap">
          <PlayIcon
            width="50px"
            height="50px"
            color="var(--primaryBlack)"
            // onClick={() => playAudio(playerRef, dispatch)}
          />
        </div>
      </FlexibleDiv>
      <div className="playlist__inner__wrap">
        <div className="playlist__picture__box">
          <div className="playlist__image__wrap">
            <img src={coverArt?.url} alt="playlist cover" />
          </div>
        </div>

        <div className="playlist__name__box">
          <div className="title__and__description">
            <p
              className="name"
              onClick={() =>
                navigate(`/playlist/${id}?t=alb`, {
                  state: {
                    module: "album",
                  },
                })
              }
            >
              {truncate(title)}
            </p>

            {/* This is a delicate algorithm to show artist names separately while truncating excess for consistent display. */}
            <div className="playlist__description">
              {description ? (
                <span className="artist">{truncate(description, 25)}</span>
              ) : (
                featuredArtists?.map((fa, idx) => (
                  <span className="artist" key={idx}>
                    {fa}
                    {featuredArtists.length > 1 &&
                      idx !== featuredArtists.length - 1 &&
                      ","}
                  </span>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </PlaylistCardWrap>
  );
});

PlaylistCard.displayName = "PlaylistCard";
