import { Dropdown, Menu } from "antd";
import { MenuProps } from "antd/lib";
import OrganizeSvg from "assets/images/general/organize.svg";
import { QueueIcon } from "assets/images/player/SVGExports";
import { FlexibleDiv, NoDataDisplay, TrackListItem } from "components";
import { useMainContext } from "context";
import { TrackListWrap } from "pages/Playlist/playlist.styles";
import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { Track } from "types/artist";
import { Playlist } from "types/playlist";
import { addToQueue, playTrack } from "utils";
import { StandardControls } from "../PlaylistJumbotron/components";
import { TrackListItemHeader } from "components/lib/Cards/TrackListItem/TrackListItemHeader";
import { PlaylistOrganizerHelper } from "./components";
// import { TrackListItemHeader } from "components/lib/Cards/TrackListItem/TrackListItemHeader";

export type AlbumOrganizerProps = {
  tracks: Track[];
  module: "album";
  isOwner: boolean;
  loading: boolean;
  coverArt?: string;
  suggestedTracks?: Track[];
  similarFromData?: Playlist[];
};
export const AlbumOrganizer = ({
  tracks,
  // module,
  // isOwner,
  // loading,
  coverArt,
  similarFromData,
  suggestedTracks,
}: // similarFromData,
AlbumOrganizerProps) => {
  const albumTracks = tracks;
  console.log({ albumTracks });
  const [, setOrganizePlaylist] = useState("custom");
  // const [responseBanner, setResponseBanner] = useState<
  //   Omit<ApiResponseBannerProps, "message" | "onClose">
  // >({
  //   open: false,
  //   type: "IDLE",
  //   response: "",
  // });
  // const [atpSearch, ] = useState("");
  const [, setSearchValue] = useState("");
  // const [albumFilter, setAlbumFilter] = useState("all");
  // const [playlistFilter, setPlaylistFilter] = useState("all");
  // const debouncedSearch = useDebounce(atpSearch, 300);
  // const { playlistId } = useParams();
  const { dispatch, playerRef } = useMainContext();

  // const { data: searchData } = useSearch(
  //   debouncedSearch,
  //   atpSearch
  // );

  const handleOrganizePlaylist = (type: string) => {
    setOrganizePlaylist(type);
  };

  const organizeDetailsMenu = (
    <Menu
      style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
      items={[
        {
          key: "1",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("custom")}
            >
              <div>
                <QueueIcon width={18} />
              </div>
              <p>Custom Order</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("title")}
            >
              <QueueIcon width={18} />
              <p>Title</p>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("creator")}
            >
              <QueueIcon width={18} />
              <p>Creator</p>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("album")}
            >
              <QueueIcon width={18} />
              <p>Album</p>
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("duration")}
            >
              <QueueIcon width={18} />
              <p>Duration</p>
            </div>
          ),
        },
      ]}
    />
  );

  // const hasResult =
  //   searchData?.data &&
  //   Object.values(searchData?.data).some((d) => Boolean(d.length));
  // const albumsResult = isOwner && hasResult ? searchData?.data?.albums : [];
  // const artistsResult = isOwner && hasResult ? searchData?.data?.artists : [];
  // const playlistsResult =
  //   isOwner && hasResult ? searchData?.data?.playlists : [];
  // const tracksResult =
  //   isOwner && hasResult ? take(searchData?.data?.tracks, 5) : [];

  // const canShowAtpResult = Boolean(atpSearch) && hasResult;
  // const hasNoAtpResult = Boolean(atpSearch) && !hasResult;
  // for testing
  const tracksAddedToPlaylist = true;
  const searchResults = true;
  // const filteredalbumTracks = searchValue
  //   ? albumTracks.filter(
  //       (fl) =>
  //         fl?.metadata?.title
  //           ?.toLowerCase()
  //           .includes(searchValue?.toLowerCase()) ||
  //         fl?.metadata?.displayArtists?.some((da) =>
  //           da.artist.stageName
  //             .toLowerCase()
  //             .includes(searchValue?.toLowerCase())
  //         )
  //     )
  //   : albumTracks;

  return (
    <React.Fragment>
      {/* ...............Mobile Filter and Controls........ */}
      <FlexibleDiv
        alignItems="center"
        justifyContent="space-between"
        className="mobile__filter__and__controls"
      >
        <FlexibleDiv className="mobile__search__input">
          <BiSearchAlt2 size={20} color="rgba(217, 217, 217, 0.5)" />
          <input
            onChange={({ target }) => setSearchValue(target.value)}
            placeholder="Search this playlist"
          />
        </FlexibleDiv>
        <StandardControls />
      </FlexibleDiv>
      {/*.................... Tracks Added to Playlist................... */}
      {tracksAddedToPlaylist && (
        <div className="tracks__added__wrap">
          <FlexibleDiv
            className="filter__playlist__box"
            justifyContent="space-between"
          >
            <FlexibleDiv className="search__input">
              <BiSearchAlt2 size={20} color="rgba(217, 217, 217, 0.5)" />
              <input
                onChange={({ target }) => setSearchValue(target.value)}
                placeholder=""
              />
            </FlexibleDiv>
          </FlexibleDiv>
          <>
            <TrackListItemHeader />
            <TrackListWrap
              pt="0rem"
              bgImg={coverArt}
              className="tracks__display"
            >
              {albumTracks && albumTracks.length > 0 ? (
                albumTracks.map((track, idx) => (
                  <TrackListItem
                    key={track.id}
                    handlePlayTrack={handlePlayTrack}
                    idx={idx}
                    track={track}
                    fullView
                    {...track}
                  />
                ))
              ) : (
                <FlexibleDiv className="sorry__message">
                  Sorry, This Album has no songs.
                </FlexibleDiv>
              )}
            </TrackListWrap>
          </>
        </div>
      )}

      <PlaylistOrganizerHelper
        loading={false}
        module="playlist"
        tracks={suggestedTracks}
        similarFromData={similarFromData}
      />
    </React.Fragment>
  );

  function handlePlayTrack(payload: any) {
    playTrack(dispatch, playerRef, payload);
    // playTrack(dispatch, playerRef, { track: payload, entity })
    addToQueue(dispatch, tracks);
  }
};
