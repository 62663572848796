import FormItem from "antd/lib/form/FormItem"
import React, { useState } from "react"
import {
  DateSelectorWrap,
  DateSelectorWrapProps,
  StyledDatePicker,
} from "./date-selector.styles"
import { StatusState } from "types"
import { SetStatus, ValidateInputProps } from "utils"
import { Rule } from "antd/lib/form"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"
// import { DatePickerProps } from "antd/lib"

export type DateSelectorProps = {
  picker?: "date" | "week" | "month" | "quarter" | "year"
  rules?: Rule[]
  name?: string
  format?: string
  placeholder?: string
  value?: string
  small?: boolean
  nullable?: boolean
  hasError?: boolean
  hasSuccess?: boolean
  status?: StatusState

  suffixIcon?: React.ReactNode

  setStatus?: React.Dispatch<React.SetStateAction<StatusState>>
  disabledDate?: (value: any) => void
  validator?: (value: ValidateInputProps) => Promise<void>
  handleChange?: (newDate: Dayjs) => void
  checkAvailability?: (value: string) => void
} & DateSelectorWrapProps

const DateSelector = ({
  picker,
  handleChange,
  validator,
  rules,
  name,
  value,
  nullable,
  setStatus,
  placeholder,
  ...props
}: DateSelectorProps) => {
  const [__status, __setStatus] = useState<StatusState>()

  const hasError = props.hasError || Boolean(__status?.err)
  const hasSuccess = props.hasSuccess || Boolean(__status?.success)

  const handleSetStatus = (args: SetStatus) => {
    __setStatus(args)
    setStatus && setStatus(args)
  }

  const dateOnChange = (e: any) => {
    const date = dayjs(e) // Convert to Dayjs instance

    if (date.isValid() && handleChange) {
      handleChange(date) // Pass only valid dates to the handler
    }
  }

  return (
    <DateSelectorWrap hasError={hasError} hasSuccess={hasSuccess} {...props}>
      <FormItem
        // name={name}
        rules={
          validator
            ? [
                {
                  validator: (rules, value: string) =>
                    validator({
                      rules,
                      value,
                      nullable,
                      setStatus: handleSetStatus,
                    }),
                },
              ]
            : rules
        }
        className="date__input__form__item"
      >
        <StyledDatePicker
          onChange={dateOnChange}
          picker={picker}
          placeholder={placeholder}
          value={value ? dayjs(value) : null}
        />
      </FormItem>

      {props?.status?.err && (
        <div className="input_error_text">
          <p className="">{props?.status?.err}</p>
        </div>
      )}
    </DateSelectorWrap>
  )
}

export default DateSelector
