import { memo, useMemo } from "react";
import { Album, Track } from "types";
import { Playlist } from "types/playlist";
import { MediaCardWrap } from "./media-card.styles";
import { LikeIcon, MoreIcon, PlayIcon, PlusIcon } from "assets";
import { FlexibleDiv } from "components/lib/Box";
import { Dropdown } from "antd";
import { getAlbumTracksById } from "network";
import { useQuery } from "react-query";
import { formatReleaseDate } from "utils";

export type MediaCardProps = {
  type: "collab" | "album" | "songs";
  album?: Album;
  track?: Track;
  handleNavigate?: () => void;
  collabPlaylist?: Playlist;
  rank?: number;
};

export const MediaCard = memo(
  ({ type = "album", rank, album, collabPlaylist, track, handleNavigate }: MediaCardProps) => {
    const handleReleaseDate = (date: string) => `Released ${formatReleaseDate(date)}`;

    const handleContributors = (collaborators: number, media?: Playlist) => {
      if (!media) return "udux";
      if (collaborators > 1) {
        return `${media.editors[0]?.username} and ${collaborators - 1} others`;
      }
      return `${media.editors[0]?.username}`;
    };

    const { data: albumTracks } = useQuery(["album-tracks", album?.id], {
      enabled: !!album?.id,
      queryFn: () => getAlbumTracksById(album?.id),
    });

    const { coverArt, title, creator, releaseInfo } = useMemo(() => {
      switch (type) {
        case "collab":
          return {
            coverArt: collabPlaylist?.coverArt?.url || "",
            title: collabPlaylist?.title || "Unknown Playlist",
            creator: "Created By",
            releaseInfo: handleContributors(
              Number(collabPlaylist?.statistics?.collaborators),
              collabPlaylist
            ),
          };
        case "songs":
          return {
            coverArt: track?.metadata?.coverArt?.url || "",
            title: track?.metadata?.title || "Unknown Track",
            creator: track?.metadata?.displayArtists[0]?.artist?.stageName || "Unknown Artist",
            releaseInfo: track?.metadata?.originalReleaseDate
              ? handleReleaseDate(track.metadata.originalReleaseDate)
              : "Unknown Release Date",
          };
        case "album":
        default:
          return {
            coverArt: album?.metadata?.coverArt?.url || "",
            title: album?.metadata?.title || "Unknown Album",
            creator: album?.metadata?.displayArtists[0]?.artist?.stageName || "Unknown Artist",
            releaseInfo: album?.metadata?.originalReleaseDate
              ? handleReleaseDate(album.metadata.originalReleaseDate)
              : "Unknown Release Date",
          };
      }
    }, [type, album, track, collabPlaylist]);

    return (
      <MediaCardWrap onClick={handleNavigate}>
        {rank && <div className={`rank__badge ${type==="songs" ? "song__rank__badge" : ""}`}>{rank}</div>}

        {/* Image Wrapper */}
        <div className={`media__image__wrapper ${type}`}>
          {type === "album" && albumTracks ? (
            [0,1,2].map((i) => (
              <img
                key={i}
                src={albumTracks[i]?.track?.coverArt?.url || coverArt}
                alt={`Track Cover ${i + 1}`}
                className={`media__image${i + 1}`}
              />
            ))
          ) : (
            <img
              src={coverArt}
              alt="Media Cover"
              className="media__image"
            />
          )}
        </div>

        {/* Information and Actions */}
        <FlexibleDiv className="information__and__actions">
          <FlexibleDiv className="media__info">
            <h3 className="media__title">{title}</h3>
            <p className="media__creator">{creator}</p>
            <p className="media__release__date__and__creator">{releaseInfo}</p>
          </FlexibleDiv>

          <FlexibleDiv className="media__actions">
            <div className="icon__wrap hover__fill">
              <LikeIcon color="#fff" height={"20"} />
            </div>
            <div className="icon__wrap hover__fill">
              <PlusIcon color="#fff" height={"20"} />
            </div>
            <div className="icon__wrap hover__fill">
              <Dropdown
                trigger={["click"]}
                overlay={<div />}
                placement="bottomRight"
              >
                <MoreIcon color="#fff" height="19" />
              </Dropdown>
            </div>
          </FlexibleDiv>
          <div className="play__btn">
            <PlayIcon width="25px" height="25px" color="var(--primaryBlack)" />
          </div>
        </FlexibleDiv>
      </MediaCardWrap>
    );
  }
);

MediaCard.displayName = "MediaCard";

