import styled from "styled-components"

export interface HeaderWrapProps {
  isSearchOpen?: boolean
  footerOnly?: boolean
}

export const HeaderWrap = styled.div<HeaderWrapProps>`
  height: var(--headerHeight);
  overflow: hidden;
  margin-bottom: 40px;
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  padding: 0 40px;
  border-bottom: 0.2px solid #44444499;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  -ms-backdrop: blur(25px);
  @media screen and (max-width: 950px) {
    padding: 0 20px;
    border-bottom: none;
  }

  .web__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu__logo__wrap {
      flex: 1;
      max-width: 262px;
      flex-wrap: nowrap;
      .menu__svg__wrap {
        .menu__svg {
          margin-right: 10px;
          font-size: 25px;
          :hover {
            color: var(--uduxYellowPrimary);
          }
        }
      }

      .udux__logo__wrap {
        max-width: fit-content;
        cursor: pointer;
        @media (max-width: 950px) {
          gap: 5px;
        }
        .logo {
          fill: var(--primaryWhite);
          width: 4rem;
          height: 2rem;

          @media only screen and (max-width: 600px) {
            width: 3.1rem;
            height: 1.8rem;
          }
        }
      }
    }

    .routechange__wrap {
      display: flex;
      flex: 1;
      visibility: ${({ isSearchOpen }) =>
        isSearchOpen ? "hidden" : "visible"};
      @media (max-width: 950px) {
        justify-content: flex-end;
      }

      .playing__check {
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 950px) {
          display: none !important;
        }
        &.active {
          color: var(--uduxYellowPrimary);
        }
        a {
          cursor: pointer;
          margin-right: 15px;
          text-decoration: none;
          font-size: 1rem;
          line-height: 20px;
          font-family: "Inter", sans-serif;
          white-space: nowrap;
          color: var(--uduxWhitePrimary);

          :hover {
            color: var(--uduxYellowPrimary);
          }
        }
      }
    }

    .header__actions {
      gap: 16px;
      flex: 1;
      justify-content: flex-end !important;
      @media (max-width: 950px) {
        gap: 5px;
      }
      .action__search {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: fit-content !important;
      }
      .action__btns {
        gap: 15px;
        visibility: ${({ isSearchOpen }) =>
          isSearchOpen ? "hidden" : "visible"};
        @media (max-width: 950px) {
          display: none !important;
        }
        .artist__btn {
          white-space: nowrap;
          border-style: solid;
          width: 135px;
          border-image-slice: 1;
          font-family: "Inter", sans-serif;
          padding: 10px 20px 10px 20px;
          border-image-source: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
          border-radius: 0;
        }
        .premium__btn {
          font-weight: 700;
          width: 135px;
          border-style: solid;
          border-image-slice: 1;
          padding: 10px 20px 10px 20px;
          font-family: "Inter", sans-serif;
          background: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
          border-image-source: linear-gradient(90deg, #ec1b52 0%, #fcb816 100%);
          white-space: nowrap;
          border-radius: 0;
          color: var(--black);
        }
      }

      .action__account {
        position: relative;
        max-width: fit-content;
        gap: 5px;
        @media (max-width: 950px) {
          display: none !important;
        }
        a {
          white-space: nowrap;
          text-decoration: none;
          color: var(--uduxWhitePrimary);
        }
        .stick {
          width: 1px;
          height: 15px;
          margin: 0 5px;
          background-color: var(--uduxYellowPrimary);
        }

        .notifications__wrap {
          gap: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .action__account__loggedIn {
        position: relative;
        max-width: fit-content;
        gap: 5px;
        .notifications__wrap {
          gap: 10px;
          display: flex;
          align-items: center;
          @media (max-width: 950px) {
            display: none !important;
          }
        }
      }
    }
  }
`
