import { notification } from "antd"
import classNames from "classnames"
import { ContainerBox, FlexibleDiv, Loader, StyledMenu } from "components"
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { Types, User, useMainContext } from "context"
import {
  errorResponseHandler,
  getPreSignedUrl,
  getUserProfile,
  successResponseHandler,
  updateUserProfile,
} from "network"
import { UserProfileJumbotron } from "pages/Profile/components"
import React from "react"
import { useMutation, useQuery } from "react-query"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { processImage, settingsTabRoutes, topSlideInFrom } from "utils"
import { DeleteIcon, UploadIcon } from "../../assets/images/general/SVGExports"
import { MyCompass } from "./components/MyCompass"
import { MyInformation } from "./components/MyInformation"
import { MySubscription } from "./components/MySubscription"
import {
  SettingsSubSectionWrap,
  SettingsWrap,
  TabSelectionWrap,
} from "./settings.styles"
import { UploadCategory } from "types"
import { MySettings } from "./components/MySettings"

export const Settings = () => {
  const navigate = useNavigate()
  const [fetching, setFetching] = React.useState<"cover" | "profile" | "">()
  const { pathname, hash } = useLocation()
  const fullPath = `${pathname}${hash}`
  const {
    state: { users },
    dispatch,
  } = useMainContext()
  const { me } = users
  const [coverImage, setCoverImage] = React.useState<string>()
  const [profileImage, setProfileImage] = React.useState<string>()
  const mainContentRef = React.useRef(null)

  React.useLayoutEffect(() => {
    topSlideInFrom(mainContentRef, { y: 10 })
  }, [fullPath])

  const { mutate: mutatePreSignUpload } = useMutation(getPreSignedUrl)
  const { mutate: mutateUserProfile } = useMutation(updateUserProfile, {})

  const { isLoading } = useQuery(["user-profile"], () => getUserProfile(), {
    onSuccess: res => {
      const { data: userProfile } = res.data

      dispatch({
        type: Types.CURRENT_USER,
        payload: { ...me, ...userProfile },
      })
    },
  })

  React.useEffect(() => {
    if (!hash) {
      navigate(`${pathname}#my-information`)
    }
  }, [hash, pathname, navigate])

  const statistics = me?.statistics
  const coverArtDominantColors = me?.profile?.coverArtDominantColors

  React.useEffect(() => {
    if (me && me.profile) {
      setCoverImage(me.profile.coverArt?.url)
      setProfileImage(me.profile.profilePicture?.url)
    }
  }, [me?.profile?.coverArt?.url, me?.profile?.profilePicture?.url, me])

  const coverArtMenu = (
    <StyledMenu
      className="upload__menu"
      top="0.3rem"
      style={{
        background: "var(--uduxBlackSecondary)",
        border: "0.5px solid var(--uduxGrayLightAlpha)",
        width: 160,
      }}
      items={[
        {
          key: "1",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
            >
              <label htmlFor="coverArt">
                <UploadIcon />
                Change Picture
              </label>
              <input
                type="file"
                name="coverArt"
                id="coverArt"
                onChange={handleCoverArtChange}
                accept="image/*"
                hidden
              />
            </FlexibleDiv>
          ),
        },
        {
          key: "2",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
            >
              <DeleteIcon width="13" /> Delete Picture
            </FlexibleDiv>
          ),
        },
      ]}
    />
  )

  const profileAvatarMenu = (
    <StyledMenu
      top="0.3rem"
      style={{
        background: "var(--uduxBlackSecondary)",
        border: "0.5px solid var(--uduxGrayLightAlpha)",
        width: 160,
      }}
      items={[
        {
          key: "1",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
            >
              <input
                type="file"
                name="profileImage"
                id="profileImage"
                onChange={handleProfileImageChange}
                accept="image/*"
                hidden
              />
              <label htmlFor="profileImage">
                <UploadIcon />
                Change Picture
              </label>
            </FlexibleDiv>
          ),
        },
        {
          key: "2",
          label: (
            <FlexibleDiv
              justifyContent="flex-start"
              className="file__uploader__input__wrap"
            >
              <DeleteIcon width="13" /> Delete Picture
            </FlexibleDiv>
          ),
        },
      ]}
    />
  )

  return (
    <PlayerLayout>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <ContainerBox>
          <SettingsWrap flexDir="column">
            <UserProfileJumbotron
              isUploadingProfile={fetching === "profile"}
              dominantColors={coverArtDominantColors}
              isUploadingCover={fetching === "cover"}
              profileAvatarMenu={profileAvatarMenu}
              handleClick={() => navigate("/me")}
              profilePicture={profileImage}
              coverArtMenu={coverArtMenu}
              statistics={statistics}
              bg={coverImage}
              user={me as User}
              isEditMode
              isOwner
            />

            <TabSelectionWrap>
              <FlexibleDiv className="tab__holder">
                <FlexibleDiv
                  className="navigation__routes__web"
                  justifyContent="space-between"
                  flexWrap="nowrap"
                >
                  {settingsTabRoutes.map((route, index) => (
                    <Link
                      to={route.path}
                      key={index}
                      className={classNames("tab__links", {
                        ["active__tab"]: fullPath === route.path,
                      })}
                    >
                      <p>{route.title}</p>
                    </Link>
                  ))}
                </FlexibleDiv>
              </FlexibleDiv>
            </TabSelectionWrap>

            <SettingsSubSectionWrap ref={mainContentRef}>
              <FlexibleDiv className="action__wrap">
                {hash === "#my-details" && <MyInformation />}
                {hash === "#my-subscriptions" && <MySubscription />}
                {hash === "#compass" && <MyCompass />}
                {hash === "#my-settings" && <MySettings />}
              </FlexibleDiv>
            </SettingsSubSectionWrap>
          </SettingsWrap>
        </ContainerBox>
      )}
    </PlayerLayout>
  )

  async function handleCoverArtChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    if (target.files) {
      const file = target.files[0]
      const oldCoverImage = me?.profile?.coverArt.url

      if (validateFileInput(file)) {
        setFetching("cover")

        const tempCoverImage = URL.createObjectURL(file)
        setCoverImage(tempCoverImage)

        const payload = {
          fileName: file.name,
          category: UploadCategory.ARTWORKS,
        }

        const dominantColors = await processImage(tempCoverImage)

        const formData = new FormData()
        formData.append("file", file)
        const uploadFile = formData.get("file")

        mutatePreSignUpload(payload, {
          onSuccess: async ({ data }) => {
            await fetch(data.presignedUrl, {
              method: "PUT",
              body: uploadFile,
            })

            mutateUserProfile(
              {
                coverArt: data.fileId,
                coverArtDominantColors: dominantColors,
              },
              {
                onSuccess: async ({ data: mupData }) => {
                  await dispatch({
                    type: Types.CURRENT_USER,
                    payload: { ...me, profile: mupData },
                  })

                  successResponseHandler({ message: "Cover picture uploaded." })
                  setFetching("")
                },
                onError: error => {
                  setFetching("")
                  dispatch({
                    type: Types.CURRENT_USER,
                    payload: {
                      ...me,
                      profile: {
                        ...me.profile,
                        profilePicture: {
                          ...me.profile?.profilePicture,
                          url: oldCoverImage,
                        },
                      },
                    },
                  })
                  errorResponseHandler(error)
                },
              }
            )
          },
          onError: error => {
            setFetching("")
            dispatch({
              type: Types.CURRENT_USER,
              payload: {
                ...me,
                profile: {
                  ...me.profile,
                  profilePicture: {
                    ...me.profile?.profilePicture,
                    url: oldCoverImage,
                  },
                },
              },
            })
            errorResponseHandler(error)
          },
        })
      }
    }
  }

  async function handleProfileImageChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    if (target.files) {
      const file = target.files[0]
      const oldProfileImage = me?.profile?.profilePicture.url

      if (validateFileInput(file)) {
        setFetching("profile")

        const tempProfileImage = URL.createObjectURL(file)
        setProfileImage(tempProfileImage)

        const payload = {
          fileName: file.name,
          category: UploadCategory.PROFILE_PICTURES,
        }

        const dominantColors = await processImage(tempProfileImage)

        const formData = new FormData()
        formData.append("file", file)
        const uploadFile = formData.get("file")

        mutatePreSignUpload(payload, {
          onSuccess: async ({ data }) => {
            await fetch(data.presignedUrl, {
              method: "PUT",
              body: uploadFile,
            })

            mutateUserProfile(
              {
                profilePicture: data.fileId,
                profilePictureDominantColors: dominantColors,
              },
              {
                onSuccess: async ({ data: mupData }) => {
                  await dispatch({
                    type: Types.CURRENT_USER,
                    payload: { ...me, profile: mupData },
                  })

                  successResponseHandler({
                    message: "Profile picture uploaded.",
                  })
                  setFetching("")
                },
                onError: error => {
                  setFetching("")
                  dispatch({
                    type: Types.CURRENT_USER,
                    payload: {
                      ...me,
                      profile: {
                        ...me.profile,
                        profilePicture: {
                          ...me.profile?.profilePicture,
                          url: oldProfileImage,
                        },
                      },
                    },
                  })
                  errorResponseHandler(error)
                },
              }
            )
          },
          onError: error => {
            setFetching("")
            dispatch({
              type: Types.CURRENT_USER,
              payload: {
                ...me,
                profile: {
                  ...me.profile,
                  profilePicture: {
                    ...me.profile?.profilePicture,
                    url: oldProfileImage,
                  },
                },
              },
            })
            errorResponseHandler(error)
          },
        })
      }
    }
  }

  function validateFileInput(file: File) {
    const maxFileLimit = 5000000 // 5mb
    const imageType = /image.*/

    if (!file) {
      return
    }

    if (!file?.type?.match(imageType)) {
      notification.error({
        message: "error",
        description: "Only Images are allowed. Please upload an image instead.",
      })
      return
    }
    if (file?.size > maxFileLimit) {
      notification.error({
        message: "error",
        description: "File is too large, Max file size is 1mb",
      })
      return
    }

    return true
  }
}
