import { LoadingOutlined } from "@ant-design/icons"
import { Form, Select } from "antd"
import {
  BorderedSelectField,
  BorderedTextfield,
  Button,
  FlexibleDiv,
  PhoneInput,
} from "components"
import { useMainContext, Types } from "context"
import dayjs from "dayjs"
import { pickBy } from "lodash"
import {
  errorResponseHandler,
  successResponseHandler,
  updateUserProfile,
} from "network"
import React from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import { capitalize, validateBasicField } from "utils"
import { MyInformationWrap } from "./my-information.styles"
import { UpdateUserPayload } from "types"
import DateSelector from "components/lib/DateInput/DateSelector"
import { useChangeUsernameService } from "network/hooks"

export const MyInformation = () => {
  const [form] = Form.useForm()
  const [username, setUsername] = React.useState<string | undefined>("")
  const [dob, setDob] = React.useState<string | undefined>("")
  const [countryCode, setCountryCode] = React.useState("")
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>("")
  const {
    state: { users },
    dispatch,
  } = useMainContext()
  const { me } = users
  const isMobile = useMediaQuery({ maxWidth: 740 })

  const { isLoading, mutate } = useMutation(updateUserProfile, {})
  const { mutate: mutateUsername, isLoading: isLoadingUsername } =
    useChangeUsernameService()

  React.useEffect(() => {
    setUsername(me?.username)
    setDob(dayjs(me?.profile?.dateOfBirth).format("YYYY-MM-DD"))
    setPhoneNumber(me?.phoneNumber)
  }, [me])

  return (
    <MyInformationWrap>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          bio: me.profile?.bio,
          gender: me.profile?.gender,
          emailAddress: me.emailAddress,
          firstName: me.profile?.firstName,
          lastName: me.profile?.lastName,
          dateOfBirth: dayjs(me?.profile?.dateOfBirth).format("YYYY-MM-DD"), // Or your preferred format
        }}
      >
        <FlexibleDiv className="main__wrap top__layer">
          <FlexibleDiv className="section__info">
            <h2 className="title">Your Details</h2>

            {!isMobile && (
              <React.Fragment>
                <FlexibleDiv className="textarea">
                  <BorderedTextfield
                    small
                    name="bio"
                    textarea
                    height="10em"
                    padding="2px"
                    placeholder="Tell us something about you"
                  />
                </FlexibleDiv>

                <FlexibleDiv className="btn__wrap">
                  <Button
                    background="var(--pinkPrimary)"
                    color="var(--black)"
                    type="submit"
                    radius="0"
                    bold
                  >
                    <span>
                      {isLoading ? <LoadingOutlined /> : "Save Changes"}
                    </span>
                  </Button>
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>

          <FlexibleDiv flexDir="column" className="form__section">
            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                nullable
                name="firstName"
                placeholder="First Name"
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                nullable
                name="lastName"
                placeholder="Last Name"
                validator={validateBasicField}
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedTextfield
                small
                editLocked
                name="emailAddress"
                placeholder="Email Address"
              />
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <BorderedSelectField
                nullable
                name="gender"
                radius="0"
                text_align="left"
                placeholder="Gender"
                validator={validateBasicField}
                small
              >
                {["male", "female", "not-specified"].map((value, idx) => (
                  <Select.Option value={value} key={idx}>
                    {capitalize(value)}
                  </Select.Option>
                ))}
              </BorderedSelectField>
            </FlexibleDiv>

            <FlexibleDiv className="input">
              <DateSelector
                name="dateOfBirth"
                placeholder="Enter your date of birth"
                handleChange={dob => {
                  setDob(dayjs(dob).format("DD/MM/YYYY"))
                }}
                value={dob}
                borderBottom="0.5px solid #707070"
              />
            </FlexibleDiv>

            <FlexibleDiv className="">
              <PhoneInput
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e)
                }}
                onSetCountryCode={code => setCountryCode(code)}
                nullable
                disabled
                small
              />
            </FlexibleDiv>

            {isMobile && (
              <React.Fragment>
                <FlexibleDiv className="textarea">
                  <BorderedTextfield
                    small
                    name="bio"
                    textarea
                    height="8em"
                    padding="2px"
                    placeholder="Tell us something about you"
                  />
                </FlexibleDiv>

                <FlexibleDiv
                  className="btn__wrap__mobile"
                  margin="4rem auto 0"
                  width="50%"
                >
                  <Button
                    background="var(--pinkPrimary)"
                    color="var(--black)"
                    type="submit"
                    width="100%"
                    radius="0"
                    bold
                  >
                    <span>
                      {isLoading ? <LoadingOutlined /> : "Save Changes"}
                    </span>
                  </Button>
                </FlexibleDiv>
              </React.Fragment>
            )}
          </FlexibleDiv>
        </FlexibleDiv>
      </Form>

      <FlexibleDiv className="bottom__layer divider">
        <h2 className="title">Change Your Username</h2>

        <FlexibleDiv className="change__username">
          <p className="info">Create a new unique username</p>

          <FlexibleDiv className="input">
            <BorderedTextfield
              small
              value={username}
              placeholder="Username"
              validator={validateBasicField}
              onChange={e => setUsername(e.target.value)}
            />
          </FlexibleDiv>

          <FlexibleDiv justifyContent="flex-start" className="btn__box">
            <Button
              background="var(--grayMain)"
              color="var(--black)"
              radius="0"
              width="135px"
              onClick={() => {
                if (username) {
                  mutateUsername({ newUsername: username })
                }
              }}
              loading={isLoadingUsername}
              bold
            >
              <span>Update</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </MyInformationWrap>
  )

  function handleSubmit(payload: UpdateUserPayload) {
    payload = {
      ...payload,
      phoneNumber,
      countryCode,
      dateOfBirth: dob,
    }

    mutate(pickBy(payload), {
      onSuccess: async data => {
        dispatch({
          type: Types.CURRENT_USER,
          payload: { ...me, profile: { ...me.profile, ...data.data } },
        })
        successResponseHandler(data)
      },
      onError: error => errorResponseHandler(error),
    })
  }
}
