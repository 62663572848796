export type CreatePlaylistPayloadProps = {
  coverArt?: string;
  description?: string;
  title: string;
  type: string;
};

export type CreatePlaylistModalProps = {
  open?: boolean;
  title?: string;
  content?: string;
  btnContent?: string;
  editMode?: boolean;
  skinBanner?: string;
  playlistData?: {
    title: string;
    description: string;
    type: string;
    coverArt: string;
  };
  isLoading?: boolean;
  handleClose?: () => void;
  handleBtnClick?: (payload: CreatePlaylistPayloadProps) => void;
};

import { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { FlexibleDiv } from "components/lib/Box";
import { BorderedTextarea, BorderedTextfield } from "components/lib/TextField";
import { BorderedSelectField } from "components/lib/Select";
import { NewPlaylistIcon } from "assets/icons/NewPlaylistIcon";
import { capitalize } from "utils";
import { CPMWrap } from "./create-playlist-modal.styles";
import { useImageUpload } from "network/hooks";
import { Button } from "components/lib/Button";

export const CreatePlaylistModal = ({
  handleBtnClick,
  handleClose,
  title = "New Playlist",
  open,
  editMode,
  isLoading,
  playlistData: initialPlaylistData,
}: CreatePlaylistModalProps) => {
  const [playlistData, setPlaylistData] = useState<CreatePlaylistPayloadProps>({
    title: "",
    description: "",
    type: "",
    coverArt: "",
  });

  const { tempCoverArt, setTempCoverArt, handleImageUpload, isUploading } =
    useImageUpload();
  const updateState = (payload: Partial<CreatePlaylistPayloadProps>) => {
    setPlaylistData((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  // If in edit mode, pre-fill the form
  useEffect(() => {
    if (initialPlaylistData) {
      setPlaylistData(initialPlaylistData);
      setTempCoverArt(initialPlaylistData.coverArt); // Set the cover art to the initial value
    }
  }, [initialPlaylistData]);

  return (
    <CPMWrap
      open={open}
      centered
      className="modal__wrap"
      footer={null}
      playlistCover={tempCoverArt || ""}
      closable={false}
      onCancel={handleClose}
    >
      <FlexibleDiv
        className="modal__header"
        alignItems="center"
        justifyContent="space-between"
      >
        <h2>{title}</h2>
        <CloseOutlined onClick={handleClose} className="close__icon" />
      </FlexibleDiv>

      <FlexibleDiv alignItems="center" className="content">
        <div className="playlist__image__wrap">
          {/* {!tempCoverArt && <NewPlaylistIcon width={"80"} height={"60"} />} */}
          {!tempCoverArt && !playlistData.coverArt ? (
            <NewPlaylistIcon width={"80"} height={"60"} />
          ) : (
            <img
              src={tempCoverArt || playlistData.coverArt}
              alt="Cover Art"
              width="80"
              height="60"
            />
          )}
          <FlexibleDiv
            justifyContent="flex-start"
            className="file__uploader__input__wrap"
          >
            <input
              type="file"
              name="playlistImage"
              id="playlistImage"
              onChange={(e) => handleImageUpload(e, updateState)}
              accept="image/*"
              hidden
            />
            <label htmlFor="playlistImage" />
          </FlexibleDiv>
        </div>

        <Form className="form_content">
          <FlexibleDiv className="signup__form__box">
            <FlexibleDiv className="textfield__wrap">
              <BorderedTextfield
                small
                type="text"
                defaultValue={playlistData?.title || ""}
                name="title"
                placeholder="Title"
                paddingLeft="0.5rem"
                hasErrorMessage={"Playlist title is required"}
                onChange={({ target }) => updateState({ title: target.value })}
              />
            </FlexibleDiv>

            <FlexibleDiv className="textfield__wrap">
              <BorderedTextarea
                small
                name="description"
                defaultValue={playlistData?.description || ""}
                placeholder="Description"
                paddingLeft="0.5rem"
                onChange={({ target }) =>
                  updateState({ description: target.value })
                }
              />
            </FlexibleDiv>

            <FlexibleDiv className="select_box gender__input__wrap">
              <BorderedSelectField
                small
                width="120px"
                radius="0px"
                defaultValue={playlistData?.type || ""}
                name="type"
                padding="0.5rem 0.5rem 0rem 0.5rem"
                placeholder="Type"
                onchange={(value) => {
                  updateState({ type: value });
                }}
              >
                {["Public", "Private"].map((value, idx) => (
                  <Select.Option
                    className="gender__dropdown"
                    style={{ width: "200px", borderRadius: "0px" }}
                    value={value}
                    key={idx}
                  >
                    {capitalize(value)}
                  </Select.Option>
                ))}
              </BorderedSelectField>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv className="btn__box" justifyContent="flex-end">
            <Button
              width="115px"
              height="40px"
              font_size="15px"
              type="submit"
              className="cancel__btn"
              color="var(--primaryWhite)"
              radius="0px"
              onClick={handleClose}
              ltr
            >
              Cancel
            </Button>

            <Button
              width="115px"
              className="create__btn"
              height="40px"
              font_size="15px"
              type="submit"
              color="var(--black)"
              contained
              radius="0px"
              bold
              onClick={() => {
                if (handleBtnClick) {
                  handleBtnClick(playlistData);
                }
              }}
              loading={isLoading || isUploading}
              ltr
            >
              <span>
                {isLoading ? <LoadingOutlined /> : editMode ? "Save" : "Create"}
              </span>
            </Button>
          </FlexibleDiv>
        </Form>
      </FlexibleDiv>
    </CPMWrap>
  );
};
