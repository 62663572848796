import styled from "styled-components";

export interface GenreCWrapWrapProps {
  artboard?: {
    url: string;
  };
  gradientBgColor?: string;
}

export const GenreCWrap = styled.div<GenreCWrapWrapProps>`
  width: 185px;
  min-width: 185px;
  height: 90px;
  background: 
    ${props => props.gradientBgColor || 'linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6))'},
    url(${props => props.artboard?.url || ''}) repeat center / contain;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    position: relative;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    z-index: 10;
    color: var(--primaryWhite);
  }

  ::after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: ${({ gradientBgColor }) => gradientBgColor};
  }
`;
