import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import { OAuth } from "network"
import galleryImage from "assets/images/Auth/verification_starting_page.png"
import { Config } from "config"
import { SourceService, Types, useMainContext } from "context"
import { AuthResponseError } from "../AuthResponseError"
import { getErrorMessage } from "utils"
import { OAuthPayload, TokensAndUser } from "types"

const OAuthRedirect = () => {
  const state = useLocation().state as unknown as Record<string, any>
  const { dispatch } = useMainContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const accessId = searchParams.get("accessId")
  const provider = searchParams.get("provider")
  const apiResponseErrorRef = React.useRef(null)
  const [error, setError] = useState("")
  const hasAuthenticatedRef = useRef(false) // Track API call execution

  const handleResponseAuth = useCallback(
    ({ tokens, user }: TokensAndUser) => {
      let pathToReturn = "/"

      if (state?.from) {
        pathToReturn = `${state.from.pathname}${state.from.hash}${state.from.search}`
      }

      localStorage.setItem("udx__user", JSON.stringify(user))
      localStorage.setItem("udx__tokens", JSON.stringify(tokens))

      dispatch({ type: Types.CURRENT_USER, payload: user })
      dispatch({ type: Types.IS_LOGGED_IN, payload: true })

      navigate(pathToReturn)
    },
    [dispatch, navigate, state?.from]
  )

  const authenticateUser = useCallback(async () => {
    if (hasAuthenticatedRef.current) return // Prevent multiple executions
    hasAuthenticatedRef.current = true

    const payload: OAuthPayload = {
      agent: "UDUX_WEB_AGENT",
      accessID: `${accessId}`,
      sourceService: Config.sourceService as SourceService,
      redirectUrl: "https://webdev.udux.com/oauth",
    }

    try {
      if (provider === "facebook") {
        const { data } = await OAuth(
          "/auth/facebook/validate-from-web",
          payload
        )
        const { tokens, user } = data
        handleResponseAuth({ tokens, user })
      } else if (provider === "google") {
        const { data } = await OAuth("/auth/google/validate-from-web", payload)
        const { tokens, user } = data
        handleResponseAuth({ tokens, user })
      } else if (provider === "apple") {
        const { data } = await OAuth("/auth/apple/validate", payload)
        const { tokens, user } = data
        handleResponseAuth({ tokens, user })
      } else {
        console.error("Unsupported provider")
      }
    } catch (error) {
      const message = getErrorMessage(error)
      setError(message)
    }
  }, [accessId, provider, handleResponseAuth]) // Add dependencies here

  useEffect(() => {
    if (accessId && provider) {
      authenticateUser()
    }
  }, [accessId, provider, authenticateUser])

  return (
    <AuthLayout
      title="Authentication"
      subText="You will be redirected shortly"
      galleryImage={galleryImage}
    >
      {error && (
        <AuthResponseError
          ref={apiResponseErrorRef}
          error={getErrorMessage(error)}
        />
      )}
    </AuthLayout>
  )
}

export default OAuthRedirect
