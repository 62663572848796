import styled from "styled-components"
import { FlexibleDiv } from "../../../../components/lib/Box/styles"

export const MyInformationWrap = styled(FlexibleDiv)`
  background-color: var(--black);
  flex-direction: column;

  .main__wrap {
    .section__info {
      width: 50%;
      padding-left: 8%;
      flex-direction: column;
      align-items: flex-start;

      .title {
        margin-bottom: 2rem;
        color: var(--pinkPrimary);
      }

      .btn__wrap {
        width: 80%;
        justify-content: flex-start;
        margin-top: 0;

        button {
          min-width: 140px;
          max-width: 160px;
          width: 100%;
        }
      }

      .textarea {
        width: 80%;
        margin-bottom: 5rem;
      }
    }

    .form__section {
      width: 50%;
      padding: 0 8%;
      justify-content: flex-start;
    }
  }

  .divider {
    margin-top: 2rem;
    border-top: 0.5px solid var(--borderLight);
  }
  .top__layer {
    padding: 5rem 0;
  }

  .bottom__layer {
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem 8%;

    .title {
      margin-bottom: 2rem;
      color: var(--pinkPrimary);
    }

    .change__username {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 0;
    }

    .info {
      padding-top: 1rem;
      color: var(--primaryText);
      font-size: 0.85rem;
    }

    .input {
      width: 50%;
    }

    .btn__box {
      margin-top: 3rem;
    }
  }

  form {
    width: 100%;

    .input:not(:first-child) {
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 741px) and (max-width: 1250px) {
    .main__wrap {
      .section__info {
        width: 50%;
        padding-left: 8%;
        flex-direction: column;
        align-items: flex-start;

        .title {
          font-size: 1.75rem;
        }

        .btn__wrap {
          width: 80%;
          justify-content: flex-start;
          margin-top: 2.65rem;

          button {
            span {
              font-size: 1rem;
            }
            max-width: 150px;
            padding: 0.5rem 1rem;
            width: 80%;
          }
        }
      }

      .form__section {
        width: 50%;
        padding: 2rem 0;
        padding-right: 3.5rem;
        justify-content: flex-start;
      }
    }

    .top__layer {
      padding: 5rem 0;
    }
  }

  @media screen and (max-width: 740px) {
    .main__wrap {
      flex-direction: column;

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .title {
          text-align: center;
        }

        .btn__wrap {
          display: none;
        }

        .textarea {
          margin-bottom: 2rem;
        }
      }

      .form__section {
        width: 100%;
        padding: 2rem;
      }
    }

    .top__layer {
      padding: 3rem 0;
    }

    form {
      width: 100%;

      .input:not(:first-child) {
        margin-top: 2rem;
      }

      .btn__wrap__mobile {
        width: 100%;
        margin-top: 4rem;
      }
    }
  }
`
