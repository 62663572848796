import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"
import {
  FavoriteFilledIcon,
  LikeIcon,
  MoreIcon,
  PausePlayingIcon,
  PlayIcon,
  PlusIcon,
} from "assets"
import { FlexibleDiv } from "components/lib/Box"
import { SpinLoader } from "components/lib/Loader"
import { Types, useMainContext } from "context"
import {
  addReaction,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
  updatePlaylistInfo,
} from "network"
import React, { Fragment } from "react"
import { IoIosAdd } from "react-icons/io"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import {
  cleanSongTitle,
  getTrackDuration,
  pauseAudio,
  playAudio,
  truncate,
} from "utils"
import { MiniTrackListItem } from "./MiniTrackListItem"
import { TLIWrap } from "./track-list-item.styles"
import { PlayTrackProps } from "../SongCard"
import { Album, MetaData, Track } from "types/artist"
import { APIResponseTypes, ReactionType, ResponsePlaylist } from "types"
import { TrackItemMoreMenu } from "./TrackItemMoreMenu"
import { AddToPlaylistAndQueueMenu } from "./AddToPlaylistAndQueue"
import { useAddTrackToCollabPlaylist } from "network/hooks/use-add-track-to-playlist"

export type TrackListItemProps = {
  handlePlayTrack: (props: PlayTrackProps) => void
  idx: number
  track: Track
  id: string
  metadata?: MetaData
  collab?: boolean
  album?: Album
  zIndex?: boolean
  fullView?: boolean
  minimize?: boolean
  playlistId?: string
  collabCover?: string
  playlistName?: string
  dateOfInvite?: string
  invitedByName?: string
  addToPlaylist?: boolean
  suggestionTrackItem?: boolean
  searchTrackListItem?: boolean
  handleApiResponse?: (data: ResponsePlaylist, type: APIResponseTypes) => void
  handleAddToPlaylist?: () => void
}
export const TrackListItem = ({
  idx,
  minimize,
  // fullView,
  // playlistId,
  addToPlaylist,
  handlePlayTrack,
  // handleApiResponse,
  handleAddToPlaylist,
  suggestionTrackItem,
  searchTrackListItem,
  ...props
}: TrackListItemProps) => {
  // console.log({ props })

  // const { track } = props
  // const { metadata, id, userLiked } = Array.isArray(track) ? track[0] : track
  const { id, metadata, album } = props

  const [liked, setLiked] = React.useState(false)
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { mutate: mutateLike } = useMutation(addReaction)
  const { mutate: mutateUnlike } = useMutation(removeReaction)
  const { isLoading: atpLoading } = useMutation(updatePlaylistInfo)
  // const { mutate: mutateAddToPlaylist, isLoading: atpLoading } =
  //   useMutation(updatePlaylistInfo)
  const { mutate: addToPlaylistMutatation } = useAddTrackToCollabPlaylist()

  const {
    state: { player, app },
    dispatch,
    playerRef,
  } = useMainContext()

  const { isLoggedIn } = app
  const { currentTrack, isPlaying, isPaused } = player

  const isPlayingTrack =
    (currentTrack?.track?.id === id || currentTrack?.id === id) && isPlaying

  // const isAdded = current?.tracks?.some(
  //   (t) => addToPlaylist
  //   && t.track.id === id
  // );
  const isAdded = true

  const { invitedByName, playlistName, dateOfInvite, collab, collabCover } =
    props

  const handleSimilarVibes = () => {}
  const handleGoToArtist = () => {}
  const handleGoToAlbum = () => {}
  const handleShowCredits = () => {}
  const handleShareTrack = () => {
    if (!isLoggedIn) {
      return dispatch({
        type: Types.SET_PRIVATE_ACTION,
        payload: true,
      })
    }
  }

  return (
    <TLIWrap minimize={minimize} {...props}>
      <div className="inner__border__bottom" />
      {minimize ? (
        <MiniTrackListItem handlePlayTrack={handlePlayTrack} {...props} />
      ) : (
        <React.Fragment>
          {!collab && !suggestionTrackItem && !searchTrackListItem && (
            <div className="number__idx">{`${idx + 1}`}</div>
          )}

          {isMobile ? (
            <React.Fragment>
              <div className="mobile__display__only">
                <div className="name__and__cover">
                  <div className="cover__art">
                    <div className="player__icon__wrap">
                      {isPlayingTrack ? (
                        <div className="is__playing__track">
                          <PausePlayingIcon
                            width="20px"
                            height="20px"
                            color="var(--uduxYellowPrimary)"
                            onClick={() => pauseAudio(playerRef, dispatch)}
                          />
                        </div>
                      ) : (
                        <div className="player__icon__wrap">
                          {isPaused && currentTrack?.track?.id === id ? (
                            <PlayIcon
                              width="25px"
                              height="25px"
                              color="var(--white)"
                              onClick={() => playAudio(playerRef, dispatch)}
                            />
                          ) : (
                            <PlayIcon
                              width="25px"
                              height="25px"
                              color="var(--uduxYellowPrimary)"
                              onClick={() => handlePlayTrack(props)} // TODO: should pass the Track item to be played
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <img
                      src={metadata?.coverArt?.url || collabCover}
                      alt="cover"
                    />
                  </div>
                </div>
                {!collab && (
                  <div className="mobile__title__and__album">
                    <p className="mobile__title">
                      {truncate(cleanSongTitle(metadata?.title), 30)}
                    </p>
                    <p className="mobile__artist">{getDisplayArtists()}</p>
                  </div>
                )}
                {/* mobile view for collab playlist */}
                {collab && (
                  <div className="mobile__title__and__album">
                    <p className="mobile__title">{playlistName}</p>
                    <p className="mobile__artist">{invitedByName}</p>
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="name__and__cover">
                <div className="cover__art">
                  {isPlayingTrack ? (
                    <div className="is__playing__track">
                      <PausePlayingIcon
                        width="20px"
                        height="20px"
                        color="var(--uduxYellowPrimary)"
                        onClick={() => pauseAudio(playerRef, dispatch)}
                      />
                    </div>
                  ) : (
                    <div className="player__icon__wrap">
                      {isPaused && currentTrack?.track?.id === id ? (
                        <PlayIcon
                          width="25px"
                          height="25px"
                          color="var(--white)"
                          onClick={() => playAudio(playerRef, dispatch)}
                        />
                      ) : (
                        <PlayIcon
                          width="25px"
                          height="25px"
                          color="var(--uduxYellowPrimary)"
                          onClick={() => handlePlayTrack(props)}
                        />
                      )}
                    </div>
                  )}
                  <img
                    src={metadata?.coverArt?.url || collabCover}
                    alt="cover"
                  />
                </div>
                <div className="title__and__album">
                  <p className="title">
                    {truncate(cleanSongTitle(metadata?.title), 30) ||
                      playlistName}
                  </p>
                  {/* {fullView && (
                    <p className="album">{album?.metadata?.title}</p>
                  )} */}
                </div>
              </div>
              {!searchTrackListItem && (
                <div className="artist">
                  {getDisplayArtists() || invitedByName}
                </div>
              )}
              {collab && <div className="artist">{dateOfInvite}</div>}
            </React.Fragment>
          )}

          <div className="album">{album?.metadata?.title}</div>

          {!suggestionTrackItem && !searchTrackListItem && (
            <div className="spins">4,893,4884</div>
          )}
          {!searchTrackListItem && (
            <div className="duration">{getTrackDuration(metadata)}</div>
          )}

          {addToPlaylist && !collab ? (
            <FlexibleDiv className="actions">
              {isAdded ? (
                <div className="add__action added">Added</div>
              ) : (
                <div
                  className="add__action add"
                  onClick={() => console.log("hello")}
                >
                  {atpLoading ? (
                    <SpinLoader />
                  ) : (
                    <Fragment>
                      Add <IoIosAdd />
                    </Fragment>
                  )}
                </div>
              )}
            </FlexibleDiv>
          ) : (
            <>
              {!collab && (
                <FlexibleDiv className="actions">
                  {liked ? (
                    <div
                      className="icons__wrap"
                      onClick={() => toggleLike(id, "Track")}
                    >
                      <FavoriteFilledIcon width={21} height={20} />
                    </div>
                  ) : (
                    <div
                      className="icons__wrap"
                      onClick={() => toggleLike(id, "Track")}
                    >
                      <LikeIcon width={21} height={20} />
                    </div>
                  )}
                  {(suggestionTrackItem || searchTrackListItem) && (
                    <span className="suggestion__add__btn">Add</span>
                  )}

                  {!suggestionTrackItem && !searchTrackListItem && (
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <AddToPlaylistAndQueueMenu
                          handleAddToPlaylist={handleAddToPlaylist}
                          handleAddToQueue={handleGoToAlbum}
                          top="0.5rem"
                        />
                      }
                      placement="bottomRight"
                    >
                      <div className="icons__wrap">
                        <PlusIcon
                          width={20}
                          height={20}
                          className="hover__fill"
                        />
                      </div>
                    </Dropdown>
                  )}
                  {!suggestionTrackItem && !searchTrackListItem && (
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <TrackItemMoreMenu
                          handleShare={handleShareTrack}
                          handleGotoAlbum={handleGoToAlbum}
                          handleGotoArtistPage={handleGoToArtist}
                          handleSimilarVibes={handleSimilarVibes}
                          handleShowCredits={handleShowCredits}
                          top="0.5rem"
                        />
                      }
                      placement="bottomRight"
                    >
                      <div className="icons__wrap">
                        <MoreIcon className="hover__fill" height="18" />
                      </div>
                    </Dropdown>
                  )}
                </FlexibleDiv>
              )}
              {/* If collab re-uses this component */}
              {collab && (
                <FlexibleDiv className="actions">
                  <div className="icons__wrap">
                    <div className="collab_action_icon">
                      <CheckOutlined className="collab_icon" />
                    </div>
                  </div>
                  <div className="icons__wrap">
                    <div className="collab_action_icon red_collab_action_icon">
                      <CloseOutlined className="collab_icon" />
                    </div>
                  </div>
                </FlexibleDiv>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </TLIWrap>
  )

  function getDisplayArtists() {
    const da = metadata?.displayArtists

    if (da && Array.isArray(da)) {
      const threeArtistsPlus = da.length > 2
      const twoArtists = da.length === 2

      let names = da.map(d => d.artist.stageName).join(", ")

      if (twoArtists) {
        names = names.split(",").join(", ")
      } else if (threeArtistsPlus) {
        names = [...names.split(",").slice(0, 2), " & others"].join(", ")
      }

      return isMobile ? truncate(names, 24) : truncate(names, 40)
    }

    return da
  }

  function toggleLike(entity: string, entityType: string) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    const payload = { entity, entityType }

    if (liked) {
      setLiked(false)
      mutateUnlike(payload, {
        onSuccess: () => {
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          })
        },
        onError: error => errorResponseHandler(error),
      })
    } else {
      setLiked(true)
      mutateLike(
        { ...payload, type: ReactionType.LIKE },
        {
          onSuccess: () => {
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            })
          },
          onError: error => errorResponseHandler(error),
        }
      )
    }
  }

  // function handleAddToPlaylist() {
  //   const payload = {
  //     id: current?.id,
  //     data: {
  //       tracks: [
  //         {
  //           track: id,
  //           sequence: current?.tracks?.length + 1,
  //         },
  //       ],
  //     },
  //   };

  //   mutateAddToPlaylist(payload, {
  //     onSuccess: (res) => {
  //       if (handleApiResponse) {
  //         handleApiResponse(res, "SUCCESS");
  //       }
  //       dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: res.data });
  //     },
  //     onError: (err) => {
  //       // if (handleApiResponse) {
  //       //   handleApiResponse(err, "ERROR");
  //       // }

  //       console.log(err)
  //     },
  //   });
  // }
}
