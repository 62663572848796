import { FlexibleDiv } from "components";
import styled from "styled-components";

export interface SearchFilterWrapProps {
  mini?: boolean;
}

export const SearchFilterWrap = styled(FlexibleDiv)<SearchFilterWrapProps>`
  .filter__list {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter__list__item {
      color: #7c7c7c;
      white-space: nowrap;
      cursor: pointer;
      width: fit-content;
      text-align: center;
      border: 1px solid #282828;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      padding: ${({ padding }) => padding || "8px 16px"};
      transition: all 0.8s ease;

      :not(.active__tag):hover {
        color: var(--uduxYellowPrimary);
      }

      font-family: "Inter", sans-serif;
      font-size: 14px;
      margin: 0;
    }

    .active__item {
      color: var(--uduxYellowPrimary);
      position: relative;
      cursor: pointer;
    }
  }
`;
