import { errorResponseHandler } from "network/error-handler"
import {
  createCollabPlaylist,
  createSoloPlaylist,
  createStandardPlaylist,
  editStandardPlaylist,
} from "network/playlist"
import { inviteCollabExistingUser, inviteCollabUser } from "network/social"
import { successResponseHandler } from "network/success-handler"
import { useMutation } from "react-query"
import {
  CollabPlaylistPayload,
  InviteCollabUserPayload,
  SoloPlaylistPayload,
  StandardPlaylistPayload,
} from "types"

export const useCreateSoloPlaylistService = () => {
  return useMutation(
    async (payload: SoloPlaylistPayload) => {
      return createSoloPlaylist(payload)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}

export const useCreateCollabPlaylistService = () => {
  return useMutation(
    async (payload: CollabPlaylistPayload) => {
      return createCollabPlaylist(payload)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}

export const useEditStandardPlaylistService = () => {
  return useMutation(
    async (data: { payload: StandardPlaylistPayload; playlistId: string }) => {
      const { payload, playlistId } = data
      return editStandardPlaylist(payload, playlistId)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}



export const useCreateStandardPlaylistService = () => {
  return useMutation(
    async (payload: StandardPlaylistPayload) => {
      return createStandardPlaylist(payload)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}

export const useInviteCollabUserService = () => {
  return useMutation(
    async (payload: InviteCollabUserPayload) => {
      const { emailAddress, userId, ...rest } = payload

      return emailAddress
        ? inviteCollabUser({ ...rest, emailAddress })
        : inviteCollabExistingUser({ ...rest, userId })
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}
