import { FlexibleDiv } from "components"
import { StatsCardWrap } from "./stats-card.styles"

export type StatsCardProps = {
  total: number
  bg?: string
  hoverColor?: string
  chart?: React.ReactElement
  hideDescription?: boolean
}
export const StatsCard = ({
  total,
  bg,
  hoverColor,
  chart,
  hideDescription = false,
}: StatsCardProps) => {
  return (
    <StatsCardWrap bg={bg} hoverColor={hoverColor} hideDesc={hideDescription}>
      {hideDescription ? undefined : (
        <FlexibleDiv className="header">
          <div className="title__desc">
            <h2 className="total">{total}</h2>
            <span className="subtitle">
              X'ers have listened to this playlist in the past 12 months
            </span>
          </div>
        </FlexibleDiv>
      )}

      <FlexibleDiv className="chart__layer">{chart}</FlexibleDiv>
    </StatsCardWrap>
  )
}
