import { FlexibleDiv } from "components"
import styled from "styled-components"

interface GeneralSearchBarWrapProps {
  active?: boolean
  isSearchOpen?: boolean
}

export const GeneralSearchBarWrap = styled(
  FlexibleDiv
)<GeneralSearchBarWrapProps>`
  position: relative;
  .searchicon__wrap {
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      transform: scale(1.1);
    }
  }
  .searchinput__wrap {
    height: 45px;
    overflow: hidden;
    width: ${({ active, isSearchOpen }) =>
      active || isSearchOpen ? "420px" : ""};
    position: relative;
    align-items: center;
    padding: 0 0.5rem;
    transition: all 0.3s ease;
    justify-content: flex-start;
    transition: all 0.3s ease;
    border: ${({ active, isSearchOpen }) =>
      active || isSearchOpen ? "0.5px solid var(--uduxYellowPrimary)" : "none"};

    @media (max-width: 950px) {
      flex-wrap: nowrap;
      height: 35px;
      margin-left: 10px;
      width: ${({ active, isSearchOpen }) =>
        active || isSearchOpen ? "100%" : "0"};
    }
    .searchinput__icon__wrap {
      cursor: pointer;
      transition: all 0.3s ease;
      width: max-content;
      margin-right: ${({ active, isSearchOpen }) =>
        active || isSearchOpen ? "0.5rem" : "0"};
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        transform: scale(1.1);
      }
    }
  }

  .search__input {
    flex: 1;
    outline: 0;
    height: 100%;
    border: none;
    font-size: 0.85rem;
    padding-left: 0.5rem;
    color: var(--primaryText);
    background-color: transparent;
    font-family: "Bw Modelica", sans-serif;
  }

  .clear__search {
    position: absolute;
    cursor: pointer;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`
