import { FlexibleDiv, PlayTrackProps } from "components";
import { VibesTrackItemWrap } from "../history.styles";
import { Track } from "types";
import { truncate } from "utils";

export type VibesTrackItemProps = {
  handlePlayTrack?: (props: PlayTrackProps) => void;
  track: Track;
  idx: number;
};
export const VibesTrackItem = ({ track, idx }: VibesTrackItemProps) => {
  return (
    <VibesTrackItemWrap>
      <FlexibleDiv className="image__info__wrap wrap--layer">
        <div className="image__wrap">
          <img src={track?.metadata?.coverArt?.url || ""} alt={track?.title} />
        </div>

        <FlexibleDiv className="info__wrap">
          <small className="id">{idx}.</small>
          <p className="title">{truncate(track?.metadata?.title)}</p>
          <small className="artist">
            {track?.metadata?.displayArtists[0]?.artist?.stageName}
          </small>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="no__of__plays">
        <small>430,000</small>
      </FlexibleDiv>

      <FlexibleDiv className="track__duration">
        <small>33:34</small>
      </FlexibleDiv>
    </VibesTrackItemWrap>
  );
};
