import { Track } from "types/artist";
import { FlexibleDiv } from "components/lib/Box";
import { pauseAudio, playAudio, truncate } from "utils";
import { Dropdown } from "antd";
import { FsePlayingIcon, MoreIcon, PlayIcon } from "assets";
import { CloseOutlineIcon } from "assets/images/player/SVGExports";
import { useMainContext } from "context";
import { HTLIWrap } from "../history.styles";
import { TrackItemMoreMenu } from "components";

export type HistoryTrackListItemProps = {
  handlePlayTrack?: () => void;
  track: Track;
};
export const HistoryTrackListItem = ({
  handlePlayTrack,
  track,
  ...props
}: HistoryTrackListItemProps) => {
  const {
    dispatch,
    playerRef,
    state: { player },
  } = useMainContext();
  const { currentTrack, isPlaying, isPaused } = player;
  const isPlayingTrack =
    (currentTrack?.track?.id === track?.id || currentTrack?.id === track?.id) &&
    isPlaying;


  return (
    <HTLIWrap>
      <FlexibleDiv className="image__info__wrap wrap--layer">
        <div className="image__wrap">
          <img src={track?.metadata?.coverArt?.url || ""} alt={track?.title} />
        </div>

        <FlexibleDiv className="info__wrap">
          <p className="title">{truncate(track?.metadata?.title)}</p>
          <small className="artist">
            {track?.metadata?.displayArtists[0]?.artist?.stageName}
          </small>
          <small className="type">Single</small>
        </FlexibleDiv>
      </FlexibleDiv>

      <FlexibleDiv className="last__played__date">
        <small>3 days ago</small>
      </FlexibleDiv>

      <FlexibleDiv className="play__btn">
        {isPlayingTrack ? (
          <div className="icon__wrap">
            <FsePlayingIcon
              onClick={() => pauseAudio(playerRef, dispatch)}
              width={20}
              height={20}
              color="var(--primaryBlack)"
            />
          </div>
        ) : (
          <div className="icon__wrap">
            {isPaused && currentTrack?.track?.id === track?.id ? (
              <PlayIcon
                width="50px"
                height="50px"
                color="var(--primaryBlack)"
                onClick={() => playAudio(playerRef, dispatch)}
              />
            ) : (
              <PlayIcon
                width="50px"
                height="50px"
                color="var(--primaryBlack)"
                //   onClick={() => handlePlayTrack(props)}
              />
            )}
          </div>
        )}
      </FlexibleDiv>

      <FlexibleDiv className="actions__wrap">
        <FlexibleDiv className="remove__icon">
          <CloseOutlineIcon />
        </FlexibleDiv>

          <Dropdown
            trigger={["click"]}
            overlay={
              <TrackItemMoreMenu
                handleShare={() => {}}
                handleGotoAlbum={() => {}}
                handleGotoArtistPage={() => {}}
                handleSimilarVibes={() => {}}
                handleShowCredits={() => {}}
                top="0.5rem"
              />
            }
            placement="bottomRight"
          >
            <div className="icons__wrap">
              <MoreIcon className="hover__fill" height="18" />
            </div>
          </Dropdown>
      </FlexibleDiv>
    </HTLIWrap>
  );
};
