/**
 * Determines the key with the highest value and returns it as an object.
 * @param genderDist - An object containing male, female, and not_specified values.
 * @returns An object with the key (`label`) and value of the largest property.
 */

export const getHighestValue = (genderDist: {
  male: number
  female: number
  not_specified: number
}): { label: string; value: number } => {
  const entries = Object.entries(genderDist)
  const [label, value] = entries.reduce(
    (maxEntry, currentEntry) =>
      currentEntry[1] > maxEntry[1] ? currentEntry : maxEntry,
    ["", 0] // Initial maxEntry: [label, value]
  )
  return { label, value }
}
