import { FlexibleDiv } from "components"
import React, { useEffect, useState } from "react"
import { DoughnutChart } from "../charts/doughnut-chart"
import { PlaylistGenderDistribution } from "types"
import { getHighestValue } from "./get-highest-value"

export type GenderBreakdownChartProps = {
  genderDist?: PlaylistGenderDistribution
}
export const GenderBreakdownChart: React.FC<GenderBreakdownChartProps> = ({
  genderDist = { male: 0, female: 0, not_specified: 0 },
}) => {
  const { label, value } = getHighestValue(genderDist)
  const [chartData, setChartData] = useState({
    labels: ["Female", "Male", "Others"],
    datasets: [
      {
        data: [genderDist.female, genderDist.male, genderDist.not_specified],
        backgroundColor: ["#fff", "#5F2A29", "#FBBA12"],
        borderWidth: 0,
      },
    ],
  })

  const options = {
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "60%",
  }

  useEffect(() => {
    const handleResize = () => {
      // Force a re-render on resize
      setChartData({ ...chartData })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [chartData])

  return (
    <FlexibleDiv height="200px" width="60%">
      <DoughnutChart
        data={chartData}
        options={options}
        labelText={`${value}%`}
        labelDescription={value > 0 ? label.toUpperCase() : "NO DATA"}
      />
    </FlexibleDiv>
  )
}
