import { Campaign } from "types/campaign";
import { LotteryCPWrap } from "./lottery-campaign-card.styles";

export type LotteryCampaignCardProp = {
  banner: { src: string };
  campaign?: Campaign;
  size?: "large" | "small"; // Add 'chart' to the size prop
};
export const LotteryCampaignCard = ({
  banner,
  campaign,
  size,
}: LotteryCampaignCardProp) => {
  return (
    <LotteryCPWrap size={size}>
      <div className="image__wrap">
        <img
          src={banner.src}
          alt={`Banner`}
          className="banner__image" // Add class for styling
        />
      </div>
    </LotteryCPWrap>
  );
};
