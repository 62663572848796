import { FlexibleDiv } from "components/lib/Box"
import styled from "styled-components"

export interface ArtisteCardProps {
  dominantColors?: Array<string>
}

export const ArtisteCardWrap = styled(FlexibleDiv)<ArtisteCardProps>`
  max-width: 150px;
  min-width: 150px;
  height: 150px;
  cursor: pointer;
  flex-direction: column;

  .picture__box {
    width: 100%;
    height: 100%;
    border-bottom: 0;
    display: flex;
    align-items: center;
    border-radius: 139px;
  }

  .image__wrap {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 95%;
      border-radius: 139px;
      object-fit: cover;
      transition: all 0.2s ease-in;
    }
  }

  

  :hover {
    .image__wrap {
      img {
        filter: opacity(0.5);
      }
    }
  }

  .name__box {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    .name {
      text-align: center;
      margin: 0;
      font-family: "Bw Modelica", sans-serif;
      text-transform: capitalize;
    }
  }
`
