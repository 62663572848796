import { CollabPlaylistTabHeader, TabContent } from "components"
import { TheCollabLineupWrap } from "../styles"
import { PiMusicNotesPlusBold } from "react-icons/pi"
import { IoMusicalNotes } from "react-icons/io5"
import { useMemo, useState } from "react"
import { CollaboratorsListTabDisplay } from "./CollaboratorsListTabDisplay"
import { InvitesTabDisplay } from "./InvitesTabDisplay"
import { Collaboration, CollabRequestStatus } from "types"

export type CollabLineupTabRoutes = typeof collabLineupTabRoutes
const collabLineupTabRoutes = [
  {
    icon: <PiMusicNotesPlusBold />,
    title: "Collaborators",
    hash: "#collaborators",
  },
  {
    icon: <IoMusicalNotes />,
    title: "Invites",
    hash: "#invites",
  },
]
export type TheCollabLineupProps = {
  invites?: Collaboration[]
}

export const TheCollabLineup = ({ invites }: TheCollabLineupProps) => {
  const [activeTab, setActiveTab] = useState("#collaborators")

  // Memoize filtered accepted invites for the collaborators list
  const acceptedInvites = useMemo(
    () =>
      invites?.filter(invite => invite.status === CollabRequestStatus.ACCEPTED),
    [invites]
  )

  // Memoize non-accepted invites for the invites list
  const pendingOrRejectedInvites = useMemo(
    () =>
      invites?.filter(invite => invite.status !== CollabRequestStatus.ACCEPTED),
    [invites]
  )

  return (
    <TheCollabLineupWrap>
      <header>
        <h2 className="title">The Collab Lineup</h2>
        <p className="description">All X'ers contributing to this playlist</p>
      </header>

      <CollabPlaylistTabHeader
        width="100%"
        activeTab={activeTab}
        tabs={collabLineupTabRoutes}
        padding="0.2rem"
        setActiveTab={tab => setActiveTab(tab)}
      />

      <TabContent>
        {activeTab === "#collaborators" && (
          <CollaboratorsListTabDisplay collaborators={acceptedInvites} />
        )}
        {activeTab === "#invites" && (
          <InvitesTabDisplay invites={pendingOrRejectedInvites} />
        )}
      </TabContent>
    </TheCollabLineupWrap>
  )
}
