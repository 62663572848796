import { CaretRightOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import {
  AddToQueueIcon,
  EditPlaylistIcon,
  LibraryIcon,
  ShareIcon2,
  SimilarVibesIcon,
} from "assets";
import { DeleteIcon } from "assets/images/general/SVGExports";
import { StyledMenu } from "components";
import { Config } from "config";
import { useState } from "react";
import { useParams } from "react-router-dom";

export type PlaylistMoreMenuProps = {
  isOwner?: boolean;
  handleDelete?: () => void;
  handleEditPlaylist?: () => void;
};

export const PlaylistMoreMenu = ({
  handleEditPlaylist,
  handleDelete,
}: PlaylistMoreMenuProps) => {
  const [copied, setCopied] = useState(false);
  const { playlistId } = useParams();
  // Nested Dropdown for "Share Playlist"
  const sharePlaylistMenu = (
    <Menu
      style={{
        background: "#0f0f0f",
        borderRadius: "none !important",
        border: "1px solid #e6e6e63d",
      }}
    >
      <Menu.Item key="copy-link">
        <span
          style={{ color: "#fff" }}
          onClick={() => {
            const protocol = Config.prod
              ? `udux.com/playlist/${playlistId}`
              : `http://localhost:3000/playlist/${playlistId}`;
            const href = protocol;
            navigator.clipboard.writeText(href);
            setCopied(true);
          }}
        >
          {copied ? "Link Copied" : "Copy link to playlist"}
        </span>
      </Menu.Item>
    </Menu>
  );

  const items = [
    {
      label: (
        <div className="menu_box" onClick={handleEditPlaylist}>
          <EditPlaylistIcon width={18} />
          <p>Edit Details</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <SimilarVibesIcon width={18} />
          <p>Explore Similar Vibes</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <AddToQueueIcon width={18} />
          <p>Add to Queue</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box">
          <LibraryIcon width={18} />
          <p>Save to library</p>
        </div>
      ),
    },
    {
      label: (
        <div className="menu_box" onClick={handleDelete}>
          <DeleteIcon width={18} />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  items.push({
    label: (
      <Dropdown
        autoAdjustOverflow
        overlay={sharePlaylistMenu} // Make sure this is a Menu for proper dropdown behavior
        trigger={["hover"]} // Trigger the dropdown on hover
        placement="topRight"
      >
        <div className="menu_box">
          <ShareIcon2 width={18} />
          <p>Share Playlist</p>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <CaretRightOutlined color="#fff" />
          </div>
        </div>
      </Dropdown>
    ),
  });

  return (
    <StyledMenu
      top="0.5rem"
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
    />
  );
};
