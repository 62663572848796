import { Skeleton } from "antd"
import { ArtisteCard, FlexibleDiv} from "components"
import { Artist } from "types"
import { useNavigate } from "react-router-dom"

export type ArtistsListProps = {
  loading: boolean
  artists?: Artist[]
}

export const ArtisteList = ({
  loading,
  artists,
}: ArtistsListProps) => {
  const navigate = useNavigate()

  return loading ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={loading} />
    </FlexibleDiv>
  ) : (
    <>
      {artists !== undefined &&
        artists?.map((item, idx) => {
          return (
            <FlexibleDiv
              className="playercard"
              width="max-content"
              key={idx}
              margin={idx !== 0 ? "0 0 0 30px" : "0"}
            >
              <ArtisteCard
                {...item}
              />
            </FlexibleDiv>
          )
        })}
    </>
  )
}
