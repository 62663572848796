import { FlexibleDiv } from "components"
import { LeaderboardEngagementCardWrap } from "./leaderboard-engagement-card.styles"
import { Avatar } from "antd"

export type EngagementCardUserDetailsProps = {
  username: string
  followers: number
  total: number
  profilePic: string
  id: string
}

export type LeaderboardEngagementCardProps = {
  bg?: string
  hoverColor?: string
  spinList: EngagementCardUserDetailsProps[]
}
export const LeaderboardEngagementCard = ({
  bg,
  hoverColor,
  spinList,
}: LeaderboardEngagementCardProps) => {
  const winner = spinList[0]

  return (
    <LeaderboardEngagementCardWrap bg={bg} hoverColor={hoverColor}>
      <FlexibleDiv className="header">
        <div className="title__desc">
          <h2 className="total">{winner.total}</h2>
          <span className="subtitle">
            X'ers have listened to this playlist in the past 30 days
          </span>
        </div>

        <div className="user__avatar">
          <p className="winner">Winner</p>
          <Avatar className="winner__avatar" src={winner.profilePic} />
        </div>
      </FlexibleDiv>

      <FlexibleDiv className="list__items">
        {spinList.map((spin, idx) => (
          <FlexibleDiv className="list__item" key={idx}>
            <div className="user__data">
              <span className="number">{idx + 1}.</span>
              <Avatar className="avatar" src={spin.profilePic} />
              <p className="username">{spin.username}</p>
            </div>

            <div className="info__stats">
              <p className="info">{spin.followers} Followers</p>
              <p className="info">{spin.total} Spins</p>
            </div>
          </FlexibleDiv>
        ))}
      </FlexibleDiv>
    </LeaderboardEngagementCardWrap>
  )
}
