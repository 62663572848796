import { Modal } from "antd";
import styled from "styled-components";


export const ConfirmDMWrap = styled(Modal)`
  height: 200px;
  min-width: fit-content;
  .ant-modal-content {
    background-color: #212121;
    border-radius: 0;
  }
  .modal__wrap {
    background-color: #212121;
  }
  .modal__header {
    margin-bottom: 20px;
    h2 {
      color: var(--primaryWhite);
      font-weight: 500;
      font-size: 1.8rem;
      font-family: "Inter", sans-serif;
      line-height: 33px;
      @media screen and (max-width: 765px) {
        font-size: 1.4rem;
      }
    }
  }

  .content{
    margin-bottom: 20px;
    .warning__text{
      text-align: left;
      font-family: "Inter", sans-serif;
      color: var(--primaryWhite);
      font-weight: 400;
      font-size: 1rem;
      line-height: 33px;
      @media screen and (max-width: 765px) {
        font-size: 0.8rem;
      }
    }
  }


  .btn__box {
      margin-top: 30px;
      gap: 10px;
      flex-wrap: nowrap;

      .cancel__btn {
        border: none;

        &:hover {
          background-color: var(--uduxYellowPrimary);
          color: var(--black);
        }
      }

      .create__btn {
        font-weight: 600;
      }
    }

`;
