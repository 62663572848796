import { Avatar } from "antd"
import { CollabRequestItemWrap } from "./collab-request-tab.styles"
import { Button, FlexibleDiv } from "components"
import {
  useAcceptCollabRequestService,
  useDeclineCollabRequestService,
} from "network/hooks"
import { CollabRequestStatus } from "types"

export type CollabRequestItemProps = {
  text: React.ReactElement
  timestamp: string
  expiry: string
  collabId: string
  avatar?: string
  status: CollabRequestStatus
}

export const CollabRequestItem = ({
  text,
  timestamp,
  expiry,
  avatar,
  collabId,
  status,
}: CollabRequestItemProps) => {
  const { mutate, isLoading } = useAcceptCollabRequestService()
  const { mutate: dMutate, isLoading: dIsLoading } =
    useDeclineCollabRequestService()

  return (
    <CollabRequestItemWrap>
      <FlexibleDiv className="info">
        <div className="avatar__icon">
          <Avatar src={avatar} />
        </div>
        <p className="notification__text">{text}</p>
        <p className="timestamp">{timestamp}</p>
      </FlexibleDiv>

      <FlexibleDiv className="action__wrap">
        {status !== CollabRequestStatus.ACCEPTED && (
          <Button
            contained
            color="var(--primaryBlack)"
            onClick={() => mutate(collabId)}
            loading={isLoading}
          >
            <span>Accept</span>
          </Button>
        )}
        <Button onClick={() => dMutate(collabId)} loading={dIsLoading}>
          <span>Decline</span>
        </Button>

        <p className="timestamp__expiry">{expiry}</p>
      </FlexibleDiv>
    </CollabRequestItemWrap>
  )
}
