import { FlexibleDiv, Loader } from "components"
import { InActivePlaylist } from "../InActivePlaylist"
import { StatsCard } from "./StatsCard"
import { CollabStatsTabDisplayWrap } from "./collab-stats-tab-display.styles"
import { TotalPlayChart } from "./components/TotalPlay.chart"
import { PlaylistLikeChart } from "./components/PlaylistLikes.chart"
import { TotalFollowersChart } from "./components/TotalFollowers.chart"
import { GenderBreakdownChart } from "./components/GenderBreakdown.chart"
import { useCollabPlaylistStatsService } from "network/hooks/use-collab-playlist-stats"
import { useParams } from "react-router-dom"

export const CollabStatsTabDisplay = () => {
  const { playlistId } = useParams() as { playlistId: string }
  const { data, isLoading } = useCollabPlaylistStatsService(playlistId)

  const genderDist = data?.data.genderDistributionOfPlaylistStream
  const totalPlay = data?.data.totalPlay
  const totalLikes = data?.data.totalLikes
  const totalFollowers = data?.data.totalFollowers

  return isLoading ? (
    <Loader />
  ) : (
    <CollabStatsTabDisplayWrap>
      {/* <InActivePlaylist
        title="There are no stats to show"
        description="To see stats you need people"
      /> */}

      <FlexibleDiv className="central__data__display">
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Play</h3>
          <StatsCard
            total={
              totalPlay
                ? totalPlay.reduce((total, item) => total + item.count, 0)
                : 0
            }
            bg="#5f2a29"
            hoverColor="#542424"
            chart={<TotalPlayChart list={totalPlay} />}
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Playlist Likes</h3>
          <StatsCard
            total={
              totalLikes
                ? totalLikes.reduce((total, item) => total + item.count, 0)
                : 0
            }
            bg="#B77F3F"
            hoverColor="#a67339"
            chart={<PlaylistLikeChart list={totalLikes} />}
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Gender Breakdown</h3>
          <StatsCard
            total={
              totalPlay
                ? totalPlay.reduce((total, item) => total + item.count, 0)
                : 0
            }
            bg="#B77F3F"
            hoverColor="#a67339"
            chart={<GenderBreakdownChart genderDist={genderDist} />}
            hideDescription
          />
        </FlexibleDiv>
        <FlexibleDiv className="data__block">
          <h3 className="lead__title">Total Followers</h3>
          <StatsCard
            total={
              totalFollowers
                ? totalFollowers.reduce((total, item) => total + item.count, 0)
                : 0
            }
            bg="#5f2a29"
            hoverColor="#542424"
            chart={<TotalFollowersChart list={totalFollowers} />}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </CollabStatsTabDisplayWrap>
  )
}
