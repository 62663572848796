import { BillingPlan, TransactionStatus } from "types"

export enum Types {
  // appTypes
  GET_CATEGORIES = "GET_CATEGORIES",
  SCROLL_TO_BOTTOM = "SCROLL_TO_BOTTOM",
  SIGN_UP_FORM_STEP = "SIGN_UP_FORM_STEP",
  API_RESPONSE_ERROR = "API_RESPONSE_ERROR",
  HE_SERVICE = "HE_SERVICE",
  SET_BILLING_PLANS = "SET_BILLING_PLANS",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
  HEADER_ENRICHMENT = "HEADER_ENRICHMENT",
  MSISDN_USER_HAS_ACCOUNT = "MSISDN_USER_HAS_ACCOUNT",
  AUTH_STREAMING_WARNING = "AUTH_STREAMING_WARNING",
  SET_PRIVATE_ACTION = "SET_PRIVATE_ACTION",
  IS_LOGGED_IN = "IS_LOGGED_IN",

  // artistTypes
  ACTIVE_ARTIST_PROFILE = "ACTIVE_ARTIST_PROFILE",

  // playerTypes
  REPEAT = "REPEAT",
  SHUFFLE = "SHUFFLE",
  SET_FSEM = "SET_FSEM",
  IS_PLAYING = "IS_PLAYING",
  MUTE_PLAYER = "MUTE_PLAYER",
  ADD_TO_QUEUE = "ADD_TO_QUEUE",
  ACTIVE_TRACKS = "ACTIVE_TRACKS",
  CURRENT_VOLUME = "CURRENT_VOLUME",
  SET_PLAY_TRACK = "SET_PLAY_TRACK",
  SET_STOP_TRACK = "SET_STOP_TRACK",
  PLAY_NEXT_TRACK = "PLAY_NEXT_TRACK",
  PLAY_PREV_TRACK = "PLAY_PREV_TRACK",
  SET_PAUSE_TRACK = "SET_PAUSE_TRACK",
  IS_PLAYER_ACTIVE = "IS_PLAYER_ACTIVE",
  UPDATE_PREV_TRACKS = "UPDATE_PREV_TRACKS",
  SET_CURRENT_PLAYING = "SET_CURRENT_PLAYING",
  REPLACE_PREV_TRACKS = "REPLACE_PREV_TRACKS",
  SET_PLAYING_PLAYLIST = "SET_PLAYING_PLAYLIST",
  CURRENT_PLAYING_TIME = "CURRENT_PLAYING_TIME",
  REPLACE_QUEUED_TRACKS = "REPLACE_QUEUED_TRACKS",
  PLAYING_LIST_ACTIVE_TRACKS = "PLAYING_LIST_ACTIVE_TRACKS",

  // playlistTypes
  CREATING_PLAYLIST = "CREATING_PLAYLIST",
  UPDATE_RECENTLY_PLAYED = "UPDATE_RECENTLY_PLAYED",
  UPDATE_CURRENT_PLAYLIST = "UPDATE_CURRENT_PLAYLIST",
  UPDATE_USER_PLAYLISTS = "UPDATE_USER_PLAYLISTS",

  // searchTypes
  FILTER_SEARCH = "FILTER_SEARCH",
  FILTER_ACTIVE_SEARCH = "FILTER_ACTIVE_SEARCH",
  SAVE_SEARCH_TRACK_RESULT = "SAVE_SEARCH_TRACK_RESULT",

  // userTypes
  CURRENT_USER = "CURRENT_USER",
  UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
  UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE",
  ACTIVE_USER_PROFILE = "ACTIVE_USER_PROFILE",

  // albumTypes
  SET_CURRENT_ALBUM = "SET_CURRENT_ALBUM",
  UPDATE_CURRENT_ALBUM = "UPDATE_CURRENT_ALBUM",
}

export type Transaction = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  userId: string
  status: TransactionStatus
  paymentProvider: string
  billingPlan: BillingPlan
}

export type Subscription = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  startDate: string
  endingAt: string
  transaction: Transaction
  userId: string
  status: string
  subscriptionType: string
  managedBy: string
  isGracePeriodActive: boolean
  gracePeriodEndDate: string
  autoRenews: boolean
}

export type FileProps = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  key: string
  category: string
  url: string
}

export type SourceService = "udux" | "livestream"
