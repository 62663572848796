import { Collaboration } from "types"
import { UserListItem } from "./UserListItem"
import { UserInvitationItem } from "./UserInvitationItem"
import { NoDataDisplay } from "components"

export type InvitesTabDisplayProps = {
  invites?: Collaboration[]
}

export const InvitesTabDisplay = ({ invites }: InvitesTabDisplayProps) => {
  return (
    <>
      {invites?.length ? (
        invites?.map(invite => (
          <UserInvitationItem
            key={invite.id}
            id={invite.id}
            emailAddress={invite.potentialNewUserEmail}
            receiver={invite.receiver}
            expires={invite.expires}
            message={invite.message}
            status={invite.status}
          />
        ))
      ) : (
        <NoDataDisplay text="No pending invites" height="300px" />
      )}
    </>
  )
}
