import { errorResponseHandler } from "network/error-handler"
import { addTrackToCollabPlaylist } from "network/playlist"
import { successResponseHandler } from "network/success-handler"
import { useMutation, useQueryClient } from "react-query"
import { AddTrackToCollabPlaylistPayload } from "types"

interface useAddTrackToCollabPlaylistProps
  extends AddTrackToCollabPlaylistPayload {
  collabId: string
}

export const useAddTrackToCollabPlaylist = () => {
  return useMutation(
    async (payload: useAddTrackToCollabPlaylistProps) => {
      const { collabId, ...rest } = payload
      return addTrackToCollabPlaylist(collabId, rest)
    },
    {
      onSuccess: res => {
        successResponseHandler(res)
      },
      onError: error => {
        errorResponseHandler(error)
      },
    }
  )
}
