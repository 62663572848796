import { FlexibleDiv, FlexibleDivProps } from "components"
import { DominantColors } from "context"
import styled from "styled-components"
import playlistTypePrint from "assets/images/player/playlist-type-print.png"

interface CPTWrapProps extends FlexibleDivProps {
  bng: string
  collabColors: DominantColors
  soloColors: DominantColors
}
export const CPTWrap = styled(FlexibleDiv)<CPTWrapProps>`
  width: 100%;
  overflow-y: scroll;
  .title__wrap {
    margin: 1rem auto 0;

    p {
      margin-bottom: 0;
      font-family: var(--mBold);
      line-height: 12px;
      letter-spacing: 4%;
      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 765px) {
        font-size: 0.8rem;
      }
    }

    .go__back__box {
      outline: 0;
      border: none;
      display: flex;
      cursor: pointer;
      padding: 0px 4px;
      width: max-content;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      cursor: pointer;

      span {
        color: var(--primaryWhite);
        opacity: 0.8;
        font-size: 0.6rem;
      }
    }
  }

  .options__wrap {
    row-gap: 30px;
    margin-top: 20px;
    overflow-y: auto;
  }

  .pl__option {
    width: 100%;
    flex: 1;
    height: 140px;
    flex-wrap: nowrap;
    cursor: pointer;
    display: flex;
    position: relative;
    column-gap: 40px;
    border: 0.5px solid var(--uduxGrayLightAlpha);
    align-items: center;
    padding: 15px 10px 15px 20px;

    &:hover {
      border: 1px solid var(--primaryYellow);

      .indicator {
        .checkbox {
          border: 4px solid var(--primaryYellow);
        }
      }

      .icon {
        svg {
          path {
            fill: var(--uduxYellowPrimary);
          }
        }
      }
    }

    &.selected {
      border: 1px solid var(--primaryYellow);

      .indicator {
        .checkbox {
          border: 4px solid var(--primaryYellow);
        }
      }

      .icon {
        svg {
          path {
            fill: var(--uduxYellowPrimary);
          }
        }
      }
    }

    .icon {
      position: absolute;
      top: 20px;
      right: 10px;
      width: fit-content;
      height: fit-content;
    }

    .indicator {
      column-gap: 10px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;

      .checkbox {
        width: 15px;
        height: 15px;
        border: 0.5px solid var(--uduxGrayLightAlpha);
        border-radius: 50%;
        margin-right: 20px;
      }

      .print__box {
        p {
          text-transform: capitalize;
          font-size: 2.2rem;
          z-index: 10;
          font-family: "Bw Modelica", sans-serif;
          font-weight: 900;
          color: var(--primaryWhite);
        }
      }
    }

    .collab__print {
      height: 140px;
      min-height: 140px;
      width: 230px;
      min-width: 230px;
      position: relative;
      background: url(${playlistTypePrint}) repeat center / contain;

      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fbba12b2;
        opacity: 0.95;
        z-index: -1;
      }
    }

    .solo__print {
      height: 140px;
      min-height: 140px;
      width: 230px;
      min-width: 230px;
      position: relative;
      background: url(${playlistTypePrint}) repeat center / contain;

      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #feb802;
        opacity: 0.9;
        z-index: -1;
      }
    }

    .content {
      flex: 1;
      row-gap: 10px;
      max-width: 200px;

      .title {
        font-family: "Bw Modelica", sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: #d9d9d9e5;
        text-align: left;
        width: 100%;
      }

      .desc {
        font-family: "Bw Modelica", sans-serif;
        font-size: 0.9rem;
        text-align: left;
        font-weight: 500;
        width: 100%;
        line-height: 16px;
        letter-spacing: 4%;
        color: var(--primaryWhite);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .options__wrap {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
      overflow: auto;
      width: 100%;
    }
    .pl__option {
      flex-direction: column;
      padding: 0;
      width: 300px;
      height: 192px;
      flex: auto;
      margin: 0 auto;

      .icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 100;
        top: auto;
      }

      .indicator {
        width: 100%;
        .checkbox {
          position: absolute;
          bottom: 10px;
          left: 10px;
        }

        .collab__print,
        .solo__print {
          width: 100%;
        }
      }

      .content {
        width: 100%;
        max-width: 100%;
        height: 150px;
        min-height: 150px;

        .desc {
          max-width: 300px;
          text-align: center;
        }

        .title {
          display: none;
        }
      }
    }
  }
`

interface EPNWrapProps extends FlexibleDivProps {
  bng?: string
}
export const EPNWrap = styled(FlexibleDiv)<EPNWrapProps>`
  flex-direction: column;
  justify-content: flex-start;
  height: 260px;
  padding-top: 2rem;
  position: relative;

  & > div {
    z-index: 1;
  }

  .title__wrap {
    margin: 1rem auto;
    position: relative;

    p {
      margin-bottom: 0;
    }

    .subtext {
      color: var(--grayPrimary);
    }

    .go__back__box {
      outline: 0;
      border: none;
      display: flex;
      cursor: pointer;
      padding: 0px 4px;
      width: max-content;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      cursor: pointer;

      span {
        color: var(--primaryWhite);
        opacity: 0.8;
        font-size: 0.6rem;
      }
    }
  }

  .input__wrap {
    flex: 1;
    flex-direction: row;
    column-gap: 2%;
    align-items: flex-start;
    padding-top: 3%;
    width: 100%;

    input {
      outline: 0;
      border: 0;
      border-bottom: 0.2px solid var(--borderLighter);
      width: 60%;
      padding-left: 0.5rem;
      height: 45px;
      font-size: large;
      font-family: "Baguede", sans-serif;
      color: var(--primaryText);
      background-color: transparent;
    }
  }

  .btn__wrap {
    flex: 1;

    button {
      width: 100%;
    }
  }

  .collab__warn__wrap {
    p {
      display: flex;
      align-items: center;

      small {
        margin-left: 0.3rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    height: fit-content;
    padding: 10px 0;
  }
`
