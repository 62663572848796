import {
  AddSongIcon,
  AddToQueueIcon,
  ExpandOutlineIcon,
  FavoriteFilledIcon,
  FsePausePlayIcon,
  FsePlayingIcon,
  LikeIcon,
  NextOutlineIcon,
  PrevOutlineIcon,
  RepeatOneIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets";
import {
  AddToPlaylistModal,
  FlexibleDiv,
  TrackProgressControl,
  VolumeControl,
} from "components";
import { Types, useMainContext } from "context";
import { isEmpty } from "lodash";
import {
  addReaction,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
} from "network";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addToQueue,
  getDisplayArtist,
  getTrackID,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
  shuffleTrack,
  unShuffleTrack,
} from "utils";
import { useMutation } from "react-query";
import { ReactionType, Track } from "types";
import { MiniPlayerWrap } from "./mini-player.styles";
import { useMediaQuery } from "react-responsive";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { MobileMiniPlayer } from "./MobileMiniPlayer";

export const MiniPlayer = React.memo(() => {
  const { playlistId } = useParams() as { playlistId: string };
  const navigate = useNavigate();
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();
  const [liked, setLiked] = useState(false);
  const [playerMode] = useState(false);
  const [openAddToPlaylistModal, setOpenAddToPlaylistModal] = useState(false);
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [addedToQueue, setAddedToQueue] = useState(false);
  const { isLoggedIn } = app;
  const {
    isPlaying,
    shuffle,
    playingList,
    currentTrack,
    queuedTracks,
    prevTracks,
    fsem,
  } = player;
  const [playerModeActivated] = useState(fsem);
  const trackId = getTrackID(currentTrack);
  const domColors = currentTrack?.metadata?.coverArtDominantColors;
  const [repeatMode, setRepeatMode] = useState<
    "default" | "loopQueue" | "repeatOne"
  >("default");
  const handleRepeatClick = () => {
    let updatedRepeatMode: "default" | "repeatOne" | "loopQueue";
    // Determine the updated repeatMode based on the current state
    if (repeatMode === "default") {
      updatedRepeatMode = "repeatOne";
    } else if (repeatMode === "repeatOne") {
      updatedRepeatMode = "loopQueue";
    } else {
      updatedRepeatMode = "default";
    }
    // Immediately call repeatTrack with the updated repeatMode and loopMode
    repeatTrack(
      dispatch,
      updatedRepeatMode // Pass the updated repeatMode
    );

    // Set the new repeatMode state after calling repeatTrack
    setRepeatMode(updatedRepeatMode);
  };

  const handlePlayerMode = () => {
    dispatch({ type: Types.SET_FSEM, payload: true });
    navigate(`/player/${trackId}/playlist/${playlistId}`);
  };

  const exitPlayerMode = () => {
    dispatch({ type: Types.SET_FSEM, payload: false });
    navigate(`/`);
  };

  const handleFullScreenMode = () => {
    const statePayload = {
      prevTracks: prevTracks,
      playingList: playingList,
      queuedTracks: queuedTracks,
    };
    dispatch({ type: Types.SET_FSEM, payload: true });
    navigate(`/full-screen-player/${trackId}`, {
      state: statePayload,
    });
    // Request fullscreen
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  useEffect(() => {
    if (currentTrack && currentTrack.userLiked !== undefined) {
      setLiked(currentTrack.userLiked);
    }
  }, [currentTrack]);

  return (
    <>
      {isMobile ? (
        <MobileMiniPlayer />
      ) : (
        <MiniPlayerWrap playerMode={playerMode} dominantColors={domColors}>
          <FlexibleDiv alignItems="center" className="fsem__wrap">
            {currentTrack && (
              <FlexibleDiv className="track__image__wrap">
                <div className="name__and__cover">
                  <div className="cover__art">
                    <img
                      src={getImageDisplay()}
                      alt={`${getTrackTitle(currentTrack, true)} cover`}
                    />
                  </div>
                  <div className="title__and__album__artist">
                    {currentTrack && (
                      <p className="title">
                        {getTrackTitle(currentTrack, true)}
                      </p>
                    )}

                    <p className="album">Album</p>
                    <p className="artist">{getDisplayArtist(currentTrack)}</p>
                  </div>
                  {playerModeActivated ? (
                    <div className="caret" onClick={exitPlayerMode}>
                      <CaretUpOutlined
                        className="activePlayerMode"
                        color="#fff"
                        width={30}
                        height={30}
                      />
                    </div>
                  ) : (
                    <div className="caret" onClick={handlePlayerMode}>
                      <CaretDownOutlined width={30} height={30} />
                    </div>
                  )}
                </div>
              </FlexibleDiv>
            )}

            <FlexibleDiv
              alignItems="center"
              className="track__controls__wrap"
              flexDir="column"
            >
              {!playerModeActivated && (
                <FlexibleDiv justifyContent="center" className="controls__main">
                  <div
                    className="control__icons repeat__icon"
                    onClick={handleRepeatClick}
                  >
                    {repeatMode === "loopQueue" ? (
                      <RepeatOutlineIcon
                        width={25}
                        height={25}
                        color={"var(--uduxYellowPrimary)"}
                      />
                    ) : repeatMode === "repeatOne" ? (
                      <RepeatOneIcon width={25} height={25} />
                    ) : (
                      <RepeatOutlineIcon
                        width={25}
                        height={25}
                        color={"#D9D9D9"}
                      />
                    )}
                  </div>

                  <div
                    className="control__icons"
                    onClick={() => {
                      if (currentTrack) {
                        playPreviousTrack(dispatch, {
                          track: currentTrack,
                        });
                      }
                    }}
                  >
                    <PrevOutlineIcon width={25} height={25} />
                  </div>

                  {isPlaying ? (
                    <div
                      className="control__icons pause__icon"
                      onClick={() => pauseAudio(playerRef, dispatch)}
                    >
                      <FsePlayingIcon width={25} height={25} />
                    </div>
                  ) : (
                    <div
                      className="control__icons play__icon"
                      onClick={() => playAudio(playerRef, dispatch)}
                    >
                      <FsePausePlayIcon width={25} color="#fff" height={25} />
                    </div>
                  )}

                  <div
                    className="control__icons"
                    onClick={() => {
                      playNextTrack(dispatch, {
                        track: currentTrack,
                      });
                    }}
                  >
                    <NextOutlineIcon width={25} height={25} />
                  </div>
                  {shuffle ? (
                    <div
                      className="control__icons shuffle__icon"
                      onClick={() => unShuffleTrack(queuedTracks, dispatch)}
                    >
                      <ShuffleOutlineIcon
                        width={25}
                        height={25}
                        color="var(--yellowPrimary)"
                      />
                    </div>
                  ) : (
                    <div
                      className="control__icons shuffle__icon"
                      onClick={() => shuffleTrack(queuedTracks, dispatch)}
                    >
                      <ShuffleOutlineIcon width={25} height={25} />
                    </div>
                  )}
                </FlexibleDiv>
              )}

              <FlexibleDiv className="song__progress__wrap" flexDir="column">
                {!isMobile && <TrackProgressControl displayProgressTime />}
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="visual__controls">
              <div className="v__icon__wrap">
                {liked ? (
                  <div onClick={handleUnlikeTrack}>
                    <FavoriteFilledIcon width={25} height={25} />
                  </div>
                ) : (
                  <div onClick={handleLikeTrack}>
                    <LikeIcon width={25} height={25} />
                  </div>
                )}
              </div>
              <div className="v__icon__wrap" onClick={handleFullScreenMode}>
                <ExpandOutlineIcon width={25} height={25} />
              </div>
              <div
                className="v__icon__wrap"
                onClick={() => {
                  if (isLoggedIn) {
                    setOpenAddToPlaylistModal(true);
                  } else {
                    dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
                  }
                }}
              >
                <AddSongIcon width={25} height={25} />
              </div>

              {currentTrack && (
                <>
                  {addedToQueue ? (
                    <div
                      onClick={() => removeTrackFromQueue(currentTrack)}
                      className="v__icon__wrap"
                    >
                      <AddToQueueIcon
                        color={"var(--uduxYellowPrimary)"}
                        width={25}
                        height={25}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => addTrackToQueue(currentTrack)}
                      className="v__icon__wrap"
                    >
                      <AddToQueueIcon color={"#fff"} width={25} height={25} />
                    </div>
                  )}
                </>
              )}
              <div>
                <VolumeControl />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
          {openAddToPlaylistModal && (
            <AddToPlaylistModal
              open={openAddToPlaylistModal}
              handleClose={() => setOpenAddToPlaylistModal(false)}
            />
          )}
        </MiniPlayerWrap>
      )}
    </>
  );

  function getImageDisplay() {
    if (!isEmpty(currentTrack)) {
      if (currentTrack?.track?.metadata) {
        return currentTrack.track.metadata.coverArt.url;
      }

      if (currentTrack?.metadata) {
        return currentTrack.metadata.coverArt.url;
      }
    }
  }

  function addTrackToQueue(track: Track) {
    const tracksToAdd = [track];
    addToQueue(dispatch, tracksToAdd);
    setAddedToQueue(true);
    successResponseHandler({ message: "Track added to queue successfully" });
  }

  function removeTrackFromQueue(track: Track) {
    setAddedToQueue(false);
    successResponseHandler({
      message: "Track removed from queue successfully",
    });
  }

  function handleUnlikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(false);
    const payload = {
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateUnlike(payload, {
      onSuccess: (res) => {
        successResponseHandler(res);
      },
      onError: (err) => {
        setLiked(false);
        errorResponseHandler(err);
      },
    });
  }

  function handleLikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(true);
    const payload = {
      type: ReactionType.LIKE,
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateLike(payload, {
      onSuccess: (res) => {
        successResponseHandler(res);
      },
      onError: (err) => {
        setLiked(false);
        errorResponseHandler(err);
      },
    });
  }
});

MiniPlayer.displayName = "MiniPlayer";
