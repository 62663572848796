import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { HistoryWrap } from "./history.styles";
import { FlexibleDiv } from "components";
import { useState } from "react";
import { SearchIcon } from "assets";
import { getAllTracks } from "network";
import { useQuery } from "react-query";
import { Skeleton } from "antd";
import { ExploreVibes } from "./components";
import { take } from "lodash";
import { HistoryTrackListItem } from "./components/HistoryTrackListItem";

export const History = () => {
  const [, setSearchValue] = useState("");

  const { data: allTracks, isLoading: isTracksLoading } = useQuery(
    ["tracks"],
    getAllTracks
  );
  return (
    <PlayerLayout>
      <HistoryWrap>
        <FlexibleDiv
          className="history__header"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <h4 className="subheader">Playback History</h4>
        </FlexibleDiv>
        <FlexibleDiv
          width="100%"
          className="filter__history__box"
          justifyContent="flex-end"
          alignItems="center"
        >
          <FlexibleDiv className="searchinput__wrap">
            <div className="searchinput__icon__wrap">
              <SearchIcon color={"var(--uduxYellowPrimary)"} />
            </div>
            <input
              name="searchQuery"
              //   value={searchQuery}
              //   onChange={handleChange}
              placeholder="Search Plackback History"
              className="search__input"
              autoComplete="off"
            />
          </FlexibleDiv>
        </FlexibleDiv>

        <div className="content__wrap">
          <div className="history__track__list__wrap">
            {isTracksLoading ? (
              <FlexibleDiv minHeight="300px">
                <Skeleton active={isTracksLoading} />
              </FlexibleDiv>
            ) : (
              take(allTracks?.data, 7)?.map((trk) => (
                <HistoryTrackListItem key={trk?.id} track={trk} />
              ))
            )}
          </div>

          <FlexibleDiv className="explore__vibes__wrap">
            <ExploreVibes
              isLoading={isTracksLoading}
              tracks={allTracks?.data}
            />
          </FlexibleDiv>
        </div>
      </HistoryWrap>
    </PlayerLayout>
  );
};
