import { Types, useMainContext } from "context"
import { deactivateAccount } from "network/user"
import { useMutation } from "react-query"
import { DeactivateAccountPayload } from "types"

// Custom hook for login service
export const useDeactivateAccountService = () => {
  const { dispatch } = useMainContext()

  return useMutation(
    async (payload: DeactivateAccountPayload) => {
      return deactivateAccount({
        ...payload,
      })
    },
    {
      onSuccess: res => {
        if (res) {
          // Store user and tokens
          localStorage.removeItem("udx__user")
          localStorage.removeItem("udx__tokens")

          window.location.href = "/login"
        }
      },
      onError: error => {
        // Dispatch the error to the global state
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )
}
