import { FavoriteFilledIcon, FavoriteIcon, PausePlayingIcon } from "assets"
import { PlayIcon } from "assets/images/player/SVGExports"
import { FlexibleDiv } from "components/lib/Box"
import { Types, useMainContext } from "context"
import {
  addReaction,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
} from "network"
import React from "react"
import { useMutation } from "react-query"
import { useMediaQuery } from "react-responsive"
import {
  cleanSongTitle,
  getTrackDuration,
  getTrackID,
  pauseAudio,
  playAudio,
  truncate,
} from "utils"
import { PlayTrackProps } from "../SongCard"
import { MetaData, Track } from "types/artist"
import { ReactionType } from "types"

export type MiniTrackListItemProps = {
  handlePlayTrack: (props: PlayTrackProps) => void
  track?: Track
  metadata?: MetaData
}

export const MiniTrackListItem = ({
  handlePlayTrack,
  ...props
}: MiniTrackListItemProps) => {
  const { track } = props
  const metadata = track ? track.metadata : props.metadata
  const id = track ? track.id : ""
  const [liked, setLiked] = React.useState(false)
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { mutate: mutateLike } = useMutation(addReaction)
  const { mutate: mutateUnlike } = useMutation(removeReaction)

  const {
    state: { player, app },
    dispatch,
    playerRef,
  } = useMainContext()

  const { isLoggedIn } = app
  const { currentTrack, isPlaying, isPaused } = player
  const trackId = getTrackID(currentTrack)

  const isPlayingTrack = trackId === id && isPlaying

  return (
    <React.Fragment>
      <div className="name__and__cover">
        <div className="cover__art">
          <div className="">
            {isPlayingTrack ? (
              <div className="is__playing__track">
                <PausePlayingIcon
                  width="20px"
                  height="20px"
                  color="var(--uduxYellowPrimary)"
                  onClick={() => pauseAudio(playerRef, dispatch)}
                />
              </div>
            ) : (
              <div className="player__icon__wrap">
                {isPaused && trackId === id ? (
                  <PlayIcon
                    width="25px"
                    height="25px"
                    color="var(--white)"
                    onClick={() => playAudio(playerRef, dispatch)}
                  />
                ) : (
                  <PlayIcon
                    width="25px"
                    height="25px"
                    color="var(--uduxYellowPrimary)"
                    onClick={() => {
                      // handlePlayTrack(props)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <img src={metadata?.coverArt?.url} alt="cover" />
        </div>
        <div className="title__and__album__mini">
          <p className="title">
            {truncate(cleanSongTitle(metadata?.title), 30)}
          </p>
          <small className="artist-mini">{getDisplayArtists()}</small>
        </div>
      </div>

      <div className="duration">{getTrackDuration(metadata)}</div>

      <FlexibleDiv className="actions">
        {liked ? (
          <div className="icons__wrap" onClick={() => toggleLike(id, "Track")}>
            <FavoriteFilledIcon width={21} height={20} />
          </div>
        ) : (
          <div className="icons__wrap" onClick={() => toggleLike(id, "Track")}>
            <FavoriteIcon width={21} height={20} />
          </div>
        )}
      </FlexibleDiv>
    </React.Fragment>
  )

  function getDisplayArtists() {
    const da = metadata?.displayArtists

    if (da && Array.isArray(da)) {
      const threeArtistsPlus = da.length > 2
      const twoArtists = da.length === 2

      let names = da.map(d => d.artist.stageName).join(", ")

      if (twoArtists) {
        names = names.split(",").join(", ")
      } else if (threeArtistsPlus) {
        names = [...names.split(",").slice(0, 2), " & others"].join(", ")
      }

      return isMobile ? truncate(names, 24) : truncate(names, 40)
    }

    return da
  }

  function toggleLike(entity: string, entityType: string) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true })
    }

    const payload = { entity, entityType }

    if (liked) {
      setLiked(false)
      mutateUnlike(payload, {
        onSuccess: () => {
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          })
        },
        onError: error => errorResponseHandler(error),
      })
    } else {
      setLiked(true)
      mutateLike(
        { ...payload, type: ReactionType.LIKE },
        {
          onSuccess: () => {
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            })
          },
          onError: error => errorResponseHandler(error),
        }
      )
    }
  }
}
