import React, { useEffect, useState } from "react"
import { LineChart } from "../charts/line-chart"
import { FlexibleDiv } from "components"
import { TotalMonthAndCount } from "types"

export type TotalPlayChartProps = {
  list?: TotalMonthAndCount[]
}
export const TotalPlayChart: React.FC<TotalPlayChartProps> = ({ list }) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const processedData = list
    ? list.map(item => ({
        ...item,
        month: monthNames[item.month - 1] || "Unknown", // Convert month number to name
      }))
    : []

  const [chartData, setChartData] = useState({
    labels: processedData.map(item => item.month),
    datasets: [
      {
        label: "",
        data: processedData.map(item => item.count),
        fill: false,
        borderColor: "#B77F3F",
        borderWidth: 1,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#fff",
        pointBorderWidth: 1,
        pointRadius: 2,
        tension: 0.1,
      },
    ],
  })

  const options = {
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff",
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          color: "#fff",
        },
        grid: {
          display: false,
        },
      },
    },
  }

  useEffect(() => {
    const handleResize = () => {
      // Force a re-render on resize
      setChartData({ ...chartData })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [chartData])

  return (
    <FlexibleDiv>
      {processedData.length > 0 ? (
        <LineChart data={chartData} options={options} />
      ) : (
        <span>No Data</span>
      )}
    </FlexibleDiv>
  )
}
