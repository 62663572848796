import { User } from "context"
import { getAllTracks, getPlaylistById, getPlaylistsByQuery } from "network"
import { useQuery, useQueryClient } from "react-query"
import { ResponseWithTracks } from "types"
import { Playlist } from "types/playlist"

// Hook for fetching playlists
export const useFetchPlaylists = (me: Partial<User>, isLoggedIn: boolean) => {
  const queryClient = useQueryClient()

  return useQuery<Playlist[]>(
    ["my-playlists", me?.id], // Include user id to distinguish caches for different users
    () => getPlaylistsByQuery(`user=${me?.id}`),
    {
      enabled: isLoggedIn, // Only run the query if the user is logged in
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<Playlist[]>([
          "my-playlists",
          me?.id,
        ])
        return cachedData
      },
      // staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      // cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

// Hook for fetching public playlists
// General hook for fetching playlists with dynamic parameters
export const useFetchPublicPlaylists = (queryKey: string[], query: string) => {
  const queryClient = useQueryClient()

  return useQuery<Playlist[]>(
    queryKey, // Cache key
    () => getPlaylistsByQuery(query), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<Playlist[]>(queryKey)
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const useFetchPlaylistById = (playlistId: string) => {
  const queryClient = useQueryClient()

  return useQuery<Playlist>(
    playlistId, // Cache key
    () => getPlaylistById(playlistId), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<Playlist>(playlistId)
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const useQueryPlaylist = (query: string) => {
  const queryClient = useQueryClient()

  return useQuery<Playlist[]>(
    query, // Cache key
    () => getPlaylistsByQuery(query), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData = queryClient.getQueryData<Playlist[]>(query)
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}

export const useGetAllTracks = () => {
  const queryClient = useQueryClient()

  return useQuery<ResponseWithTracks>(
    ["all-tracks"], // Cache key
    () => getAllTracks(), // Query with dynamic filters
    {
      initialData: () => {
        // Check if data is cached already
        const cachedData =
          queryClient.getQueryData<ResponseWithTracks>("all-tracks")
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}
