import { BiSearchAlt2 } from "react-icons/bi"
import { SearchTracksWrap } from "./search-tracks.styles"

export type SearchTracksProps = {
  handleSearch: (searchValue: string) => void
}
export const SearchTracks = ({ handleSearch }: SearchTracksProps) => {
  return (
    <SearchTracksWrap>
      <BiSearchAlt2 size={20} color="var(--uduxYellowPrimary)" />
      <input
        onChange={({ target }) => handleSearch(target.value)}
        placeholder=""
      />
    </SearchTracksWrap>
  )
}
