import { FlexibleDiv } from "components"
import styled from "styled-components"

interface ATPWrapProps {
  isMobile?: boolean
}
export const ATPWrap = styled(FlexibleDiv)<ATPWrapProps>`
  width: 100vw;
  overflow-y: hidden;

  .section__display__controls {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 1.5rem auto 2rem auto;

    .action__btn {
      white-space: nowrap;
    }

    .title {
      margin-bottom: 0;
      font-size: 0.85rem;
      white-space: nowrap;
      color: var(--primaryText);
    }
  }

  .filter__playlist__box {
    margin: 0 auto;
    padding: 0;

    .search__input {
      width: 40%;
      max-width: 380px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 0.5px solid var(--borderLight);

      > input {
        border: none;
        outline: none;
        width: 90%;
        color: var(--primaryWhite);
        padding-left: 5px;
        background-color: transparent;
        font-family: "Bw Modelica", sans-serif;
      }
    }

    .organize__by {
      cursor: pointer;
      border: 0.5px solid var(--borderLight);
      padding: 10px 5px;
      width: 100%;
      max-width: 150px;

      > p {
        margin: 0;
        font-family: "Baguede", sans-serif;
        color: rgba(217, 217, 217, 0.7);
      }

      > img {
        width: 18px;
        margin-right: 15px;
      }
    }
  }

  .playlist__content {
    margin-top: 0.8rem;
    align-items: flex-start;
    overflow-y: auto;
    flex-direction: column;
    max-width: 750px;
    height: 350px;
    min-height: 350px;
  }

  .btn__wrap {
    max-width: 200px;
  }

  @media screen and (max-width: 950px) {
    width: 100%;
    padding: 0 10px 0 0;
    height: 100%;
    align-items: flex-start;
    overflow: hidden;
  }

  @media screen and (max-width: 739px) {
    .filter__playlist__box {
      margin: 1rem auto;
      padding: 1rem;

      .search__input {
        max-width: 280px;
        padding: 5px;

        > input {
          font-size: 0.85rem;
        }
      }

      .organize__by {
        padding: 7px;
        max-width: 150px;

        > p {
          margin: 0;
          font-size: 0.65rem;
          font-family: "Baguede", sans-serif;
          color: rgba(217, 217, 217, 0.7);
        }

        > img {
          width: 13px;
          margin-right: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    overflow-y: auto;
    height: ${({ isMobile }) => (isMobile ? "500px" : "auto")};

    .filter__playlist__box {
      margin: 1rem auto;
      padding: 0;

      .search__input {
        max-width: 280px;
        padding: 5px;

        > input {
          font-size: 0.75rem;
        }
      }
    }
  }
`
