import { FlexibleDiv } from "components"
import styled from "styled-components"
import SubBngImg from "assets/images/general/subscription-image.png"

export const MySubscriptionWrap = styled(FlexibleDiv)`
  background-color: var(--black);
  flex-direction: column;

  .main__wrap {
    .section__info {
      width: 50%;
      padding-left: 8%;
      flex-direction: column;
      align-items: flex-start;

      .details {
        div {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin-top: 3px;
          span {
            display: block;
            white-space: nowrap;
            width: 100px;
          }

          ul {
            list-style: none;
            li {
              text-align: left;
            }
          }
          small {
            width: fit-content;
            white-space: nowrap;
            display: block;
            text-align: right;
            margin-left: 10px;
          }
        }
      }
      .title {
        font-size: 1.5rem;
        margin-bottom: 4rem;
        color: var(--pinkPrimary);
        -webkit-text-stroke: var(--w300);
        font-family: "Baguede", sans-serif;
      }

      .btn__wrap {
        width: 80%;
        justify-content: flex-start;
        margin-top: 4rem;

        button {
          min-width: 140px;
          max-width: 160px;
          width: 100%;
        }
      }

      p {
        width: 70%;
        font-size: 1rem;
        color: var(--primaryText);
      }
    }

    .image__section {
      width: 50%;
      padding-right: 6%;
      justify-content: flex-start;
      height: 500px;

      .subscription__image__wrap {
        width: 80%;
        height: 100%;
        background: url(${SubBngImg}) no-repeat center / cover;
      }
    }
  }

  .top__layer {
    padding: 5rem 0;
  }

  .divider {
    margin-top: 2rem;
    border-top: 0.5px solid var(--borderLight);
  }

  @media screen and (min-width: 750px) and (max-width: 1290px) {
    .main__wrap {
      .section__info {
        width: 50%;
        padding-left: 8%;
        flex-direction: column;
        align-items: flex-start;

        .title {
          font-size: 1.75rem;
        }

        .btn__wrap {
          width: 80%;
          justify-content: flex-start;
          margin-top: 2.65rem;

          button {
            span {
              font-size: 1rem;
            }
            max-width: 150px;
            padding: 0.5rem 1rem;
            width: 80%;
          }
        }

        p {
          width: 80%;
          font-size: 1.3rem;
          color: var(--primaryText);
        }
      }

      .image__section {
        width: 40%;
        padding-right: 6%;
        justify-content: flex-start;
        height: 320px;

        .subscription__image__wrap {
          width: 100%;
          height: 100%;
          background: url(${SubBngImg}) no-repeat center / cover;
        }
      }
    }

    .top__layer {
      padding: 5rem 0;
    }

    .logout {
      .btn__box {
        margin-top: 1rem;

        button {
          span {
            font-size: 1.3rem;
          }
          max-width: 150px;
          padding: 0.5rem 1rem;
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 749px) {
    .main__wrap {
      flex-direction: column;

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .details {
          width: fit-content;
          div {
            span {
              text-align: left;
            }
          }
        }
        .title {
          text-align: center;
        }

        .btn__wrap {
          justify-content: center;
        }

        p {
          text-align: center;
          font-size: 1.35rem;
          color: var(--primaryText);
        }
      }

      .image__section {
        width: 70%;
        height: 400px;
        margin-top: 5rem;
        padding-right: 0;
        justify-content: center;

        .subscription__image__wrap {
          width: 80%;
          height: 100%;
          background: url(${SubBngImg}) no-repeat center / cover;
        }
      }
    }

    .top__layer {
      padding: 3rem 0;
    }
  }

  @media screen and (max-width: 600px) {
    .main__wrap {
      flex-direction: column;

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .title {
          margin-bottom: 2.5rem;
          text-align: center;
          font-size: 2rem;
        }

        .btn__wrap {
          margin-top: 2.5rem;
          justify-content: center;
        }

        p {
          text-align: center;
          font-size: 0.95rem;
          color: var(--primaryText);
        }
      }

      .image__section {
        width: 70%;
        height: 350px;
        margin-top: 5rem;
        padding-right: 0;
        justify-content: center;

        .subscription__image__wrap {
          width: 100%;
          height: 100%;
          background: url(${SubBngImg}) no-repeat center / cover;
        }
      }
    }

    .top__layer {
      padding: 3rem 0;
    }
  }

  @media screen and (max-width: 439px) {
    .main__wrap {
      flex-direction: column;

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .title {
          margin-bottom: 2.5rem;
          text-align: center;
          font-size: 1.5rem;
        }

        .btn__wrap {
          margin-top: 2.5rem;
          justify-content: center;
        }

        p {
          text-align: center;
          font-size: 0.8rem;
          color: var(--primaryText);
        }
      }

      .image__section {
        width: 80%;
        height: 300px;
        margin-top: 5rem;
        padding-right: 0;
        justify-content: center;

        .subscription__image__wrap {
          width: 100%;
          height: 100%;
          background: url(${SubBngImg}) no-repeat center / cover;
        }
      }
    }

    .top__layer {
      padding: 3rem 0;
    }
  }
`
