import { AudioPlayer, Guardian, ProtectedRoute } from "components";
import { MainProvider, UpdateProfileProvider } from "context";
import ConfirmEmail from "pages/Auth/ConfirmEmail/ConfirmEmail";
import { Feed } from "pages/Feed";
import { Home } from "pages/Home";
import { NotFound } from "pages/NotFound";
import {
  ChoosePaymentPlan,
  MtnSubscriptionPollStatus,
  PaymentConfirmation,
  PaymentHome,
  PaymentLoginAuth,
  VerifyPayment,
} from "pages/Payment";
import { PaymentCheckMail } from "pages/Payment/PaymentCheckMail";
import { PaymentForgotPassword } from "pages/Payment/PaymentForgotPassword";
import { FullScreenPlayer, MusicPlayer } from "pages/Player";
import { Playlist } from "pages/Playlist";
import { ArtistProfile, Me, UserProfile } from "pages/Profile";
import { Search, SeeAll } from "pages/Search";
import { Settings } from "pages/Settings";
import { Support } from "pages/Support";
import EmbeddedPlayer from "pages/Widgets/EmbeddedPlayer/embeddedPlayer";
import "react-phone-input-2/lib/style.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "../src/styles/vars.css";
import CheckMailPage from "./pages/Auth/CheckMail/checkMail";
import LoginTypePage from "./pages/Auth/ChooseLoginType/login-type";
import ConfirmPasswordPage from "./pages/Auth/Confirm-password/confirm-password";
import LoginPage from "./pages/Auth/Login/login";
import PhoneNumberLoginPage from "./pages/Auth/LoginWithPhoneNumber/phone";
import VerifyEmailPage from "./pages/Auth/Oauth/VerifyEmail";
import OauthPage from "./pages/Auth/Oauth/oauth";
import OTPFormPage from "./pages/Auth/OtpForm/otp-form";
import ResetPasswordPage from "./pages/Auth/Reset-password/reset-password";
import SignUpPage from "./pages/Auth/Signup/signup";
import PaymentAuthentication from "./pages/Payment/PaymentAuthenticationPage/paymentAuthentication";
import PaymentMTNConfirmationPage from "./pages/Payment/PaymentMTNConfirmationPage/PaymentMTNConfirmationPage";
import PaymentMTNPage from "./pages/Payment/PaymentMTNPage/paymentMTNPage";
import PaymentRegister from "./pages/Payment/PaymentRegisterPage/paymentRegister";
import { Discover } from "pages/Discover";
import { TopAlbum } from "pages/TopAlbum";
import { TopCollab } from "pages/TopCollab";
import { TopSongs } from "pages/TopSongs";
import { AllArtists } from "pages/AllArtists";
import { AllPlaylists } from "pages/AllPlaylists";
import { History } from "pages/History";
import { Genres } from "pages/Genres";

const queryClient = new QueryClient();

function App() {
  return (
    <MainProvider>
      <AudioPlayer />
      {/* <GlobalStyle /> */}

      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <UpdateProfileProvider>
            <Routes>
              <Route path="/payment">
                <Route index element={<PaymentHome />} />
                <Route path="auth" element={<PaymentAuthentication />} />
                <Route path="login" element={<PaymentLoginAuth />} />
                <Route
                  path="forgot-password"
                  element={<PaymentForgotPassword />}
                />
                <Route path="check-mail" element={<PaymentCheckMail />} />
                <Route path="mtn">
                  <Route index element={<PaymentMTNPage />} />
                  <Route
                    path="confirm"
                    element={<PaymentMTNConfirmationPage />}
                  />
                  <Route
                    path="check-phone"
                    element={<MtnSubscriptionPollStatus />}
                  />
                </Route>

                <Route path="register" element={<PaymentRegister />} />
                <Route path="plan" element={<ChoosePaymentPlan />} />
                <Route path="confirm" element={<PaymentConfirmation />} />
                <Route path="complete" element={<VerifyPayment />} />
                <Route path="failed" element={<VerifyPayment />} />
              </Route>

              <Route path="/login" element={<Outlet />}>
                <Route index element={<LoginTypePage />} />
                <Route path="basic" element={<LoginPage />} />
                <Route path="phone" element={<PhoneNumberLoginPage />} />
                <Route path="verify-otp" element={<OTPFormPage />} />
              </Route>
              <Route
                path="/widgets/music-player"
                element={<EmbeddedPlayer />}
              />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/check-mail" element={<CheckMailPage />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route
                path="/confirm-password"
                element={<ConfirmPasswordPage />}
              />
              <Route path="/oauth" element={<OauthPage />} />
              <Route path="/verify" element={<VerifyEmailPage />} />

              <Route path="/support" element={<Support />} />

              <Route
                path="/"
                element={
                  <Guardian>
                    <Home />
                  </Guardian>
                }
              />

              {/* <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            /> */}

              <Route
                path="/me"
                element={
                  <ProtectedRoute>
                    <Me />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/player/:playId/playlist/:playlistId"
                element={
                  <Guardian>
                    <MusicPlayer />
                  </Guardian>
                }
              />

              <Route
                path="/full-screen-player/:playId"
                element={
                  <Guardian>
                    <FullScreenPlayer />
                  </Guardian>
                }
              />

              <Route path="/playlist/:playlistId" element={<Outlet />}>
                <Route
                  index
                  element={
                    <Guardian>
                      <Playlist />
                    </Guardian>
                  }
                />
              </Route>

              <Route path="/artist/:artistId" element={<Outlet />}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <ArtistProfile />
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route path="/all-artists" element={<Outlet />}>
                <Route index element={<AllArtists />} />
              </Route>
              <Route path="/all-playlists" element={<Outlet />}>
                <Route index element={<AllPlaylists />} />
              </Route>

              <Route path="/user/:userId" element={<Outlet />}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/feed"
                element={
                  <ProtectedRoute>
                    <Feed />
                  </ProtectedRoute>
                }
              />
              <Route path="/top-album" element={<TopAlbum />} />
              <Route path="/top-songs" element={<TopSongs />} />
              <Route path="/top-collab" element={<TopCollab />} />
              <Route path="/history" element={<History />} />
              <Route path="/genres" element={<Genres />} />

              <Route path="/search" element={<Outlet />}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <Search />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="see-all"
                  element={
                    <ProtectedRoute>
                      <SeeAll />
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route path="/discover" element={<Outlet />}>
                <Route index element={<Discover />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </UpdateProfileProvider>
        </BrowserRouter>

        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </MainProvider>
  );
}

export default App;
