import { useLeaderboardEngagementService } from "network/hooks/use-leaderboard"
import { InActivePlaylist } from "../InActivePlaylist"
import { CollabLeaderboardTabDisplayWrap } from "./leaderboard-tab.styles"
import { LeaderboardEngagementCard } from "./LeaderboardEngagementCard"
import { FlexibleDiv, Loader } from "components"
import { useParams } from "react-router-dom"

export type CollabLeaderboardTabDisplayProps = {
  isActive: boolean
}
export const CollabLeaderboardTabDisplay = ({
  isActive,
}: CollabLeaderboardTabDisplayProps) => {
  const { playlistId } = useParams()
  const { data, isLoading } = useLeaderboardEngagementService(playlistId)

  const totalSpins = data?.data.totalSpins || []
  const totalTuales = data?.data.totalTuales || []
  const totalStreamTime = data?.data.totalStreamTime || []

  return isLoading ? (
    <Loader />
  ) : (
    <CollabLeaderboardTabDisplayWrap>
      {isActive ? (
        <FlexibleDiv className="central__data__display">
          <FlexibleDiv className="data__block">
            <h3 className="lead__title">Total Spins by Collaborators</h3>
            <LeaderboardEngagementCard
              bg="#5f2a29"
              hoverColor="#542424"
              spinList={totalSpins.map(ts => ({
                id: ts.collaborator._id,
                username: ts.collaborator.username,
                followers: ts.collaborator.statistics.followers,
                profilePic: ts.collaborator.profile.profilePicture,
                total: ts.engagement.totalSpins,
              }))}
            />
          </FlexibleDiv>
          <FlexibleDiv className="data__block">
            <h3 className="lead__title">Total Tuales by Collaborators</h3>
            <LeaderboardEngagementCard
              bg="#B77F3F"
              hoverColor="#a67339"
              spinList={totalTuales.map(tt => ({
                id: tt.collaborator._id,
                username: tt.collaborator.username,
                followers: tt.collaborator.statistics.followers,
                profilePic: tt.collaborator.profile.profilePicture,
                total: tt.engagement.totalSpins,
              }))}
            />
          </FlexibleDiv>
          <FlexibleDiv className="data__block">
            <h3 className="lead__title">Highest Engagement by Collaborators</h3>
            <LeaderboardEngagementCard
              spinList={totalStreamTime.map(tst => ({
                id: tst.collaborator._id,
                username: tst.collaborator.username,
                followers: tst.collaborator.statistics.followers,
                profilePic: tst.collaborator.profile.profilePicture,
                total: tst.engagement.totalSpins,
              }))}
            />
          </FlexibleDiv>
        </FlexibleDiv>
      ) : (
        <InActivePlaylist
          title="No leaders without followers"
          description="To see stats you need people"
        />
      )}
    </CollabLeaderboardTabDisplayWrap>
  )
}
