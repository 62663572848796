import { FlexibleDiv } from "components"
import styled from "styled-components"

export const MySettingsWrap = styled(FlexibleDiv)`
  background-color: var(--black);
  flex-direction: column;

  .main__wrap {
    .section__info {
      width: 100%;
      padding: 0 5%;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: var(--pinkPrimary);
      }

      .info {
        padding-top: 1rem;
        color: var(--primaryText);
        font-size: 0.85rem;
      }

      .textarea {
        width: 80%;
        margin-top: 2rem;
      }

      .btn__wrap {
        width: 100%;
        justify-content: flex-start;
        margin-top: 3rem;

        button {
          min-width: 140px;
          max-width: 250px;
          width: 100%;
        }
      }
    }
  }

  .top__layer {
    padding: 5rem 0;
  }

  .divider {
    margin-top: 2rem;
    border-top: 0.5px solid var(--borderLight);
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    .main__wrap {
      form {
        width: 100%;
      }

      .section__info {
        width: 50%;
        padding-left: 8%;
        flex-direction: column;
        align-items: flex-start;

        .title {
          font-size: 1.75rem;
        }

        .btn__wrap {
          width: 80%;
          justify-content: flex-start;
          margin-top: 2.65rem;

          button {
            span {
              font-size: 1.3rem;
            }
            max-width: 150px;
            padding: 0.5rem 1rem;
            width: 80%;
          }
        }
      }
    }

    .top__layer {
      padding: 5rem 0;
    }
  }

  @media screen and (max-width: 600px) {
    .main__wrap {
      flex-direction: column;

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .title {
          text-align: center;
        }

        .btn__wrap {
          display: none;
        }
      }
    }

    .top__layer {
      padding: 3rem 0 0rem;
    }
  }
`
