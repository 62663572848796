export type ConfirmDeleteModalProps = {
  open?: boolean;
  title?: string;
  warningContent?: string;
  btnContent?: string;
  isLoading?: boolean;
  handleClose?: () => void;
  handleBtnClick?: () => void;
};

import { LoadingOutlined } from "@ant-design/icons";
import { FlexibleDiv } from "components/lib/Box";
import { Button } from "components/lib/Button";
import { ConfirmDMWrap } from "./confirm-delete-modal.styles";

export const ConfirmDeleteModal = ({
  handleBtnClick,
  handleClose,
  warningContent="This will delete playlist from your library",
  title = "New Playlist",
  open,
  isLoading,
}: ConfirmDeleteModalProps) => {
  return (
    <ConfirmDMWrap
      open={open}
      centered
      className="modal__wrap"
      footer={null}
      closable={false}
      onCancel={handleClose}
    >
      <FlexibleDiv
        className="modal__header"
        justifyContent="flex-start"
      >
        <h2>{title}</h2>
      </FlexibleDiv>
      <FlexibleDiv alignItems="center"
        justifyContent="flex-start"
      className="content">
        <p className="warning__text">{warningContent}</p>
      </FlexibleDiv>
      <FlexibleDiv className="btn__box" justifyContent="flex-end">
          <Button
            width="115px"
            height="40px"
            font_size="15px"
            type="submit"
            className="cancel__btn"
            color="var(--primaryWhite)"
            radius="0px"
            onClick={handleClose}
            ltr
          >
            Cancel
          </Button>

          <Button
            width="115px"
            className="create__btn"
            height="40px"
            font_size="15px"
            type="submit"
            color="var(--black)"
            contained
            radius="0px"
            bold
            loading={isLoading}
            ltr
            onClick={handleBtnClick}
          >
            <span>{isLoading ? <LoadingOutlined /> : "Delete"}</span>
          </Button>
        </FlexibleDiv>
    </ConfirmDMWrap>
  );
};
