import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import {  FlexibleDiv, PlaylistCard, Section } from "components";
import { Tags } from "components/lib/Tags/Tags";
import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { AllPlaylistsWrap } from "./all-playlists.styles";
import { useFetchPublicPlaylists } from "network/hooks";

export const AllPlaylists = () => {
    const { data: spData, isLoading: spIsLoading } = useFetchPublicPlaylists(
        ["playlists-admin"], // Unique cache key for admin playlists
        "managedBy=ADMIN" // Query to fetch admin playlists
      );

  const tags = [
    { title: "Hot", href: "#" },
    { title: "Udux", href: "#" },
    { title: "Collabs", href: "#" },
    { title: "Afrobeat", href: "#" },
    { title: "Nigerian Best", href: "#" },
    { title: "Throw Backs", href: "#" },
    { title: "Rap Banger", href: "#" },
    { title: "Ghanaian Best", href: "#" },
  ];
  const [activeTag, setActiveTag] = useState<string>("All");

  const handleTagChange = (newTag: string) => setActiveTag(newTag);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveTag("Hot");
  }, []);
  return (
    <PlayerLayout>
      <AllPlaylistsWrap>
        <Section
          className="playlist__header"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <h4 className="user__mini__subheader">Playlist</h4>
          <Tags
            width="fit-content"
            setActiveTag={handleTagChange}
            activeTag={activeTag}
            tags={tags}
          />
        </Section>
        {activeTag === "Hot" &&
          (spIsLoading ? (
            <FlexibleDiv minHeight="300px">
              <Skeleton active={spIsLoading} />
            </FlexibleDiv>
          ) : (
            <Section className="playlist__grid">
              {spData &&
                spData.map((plt) => {
                  return <PlaylistCard key={plt?.id} {...plt} />;
                })}
            </Section>
          ))}
      </AllPlaylistsWrap>
    </PlayerLayout>
  );
};
