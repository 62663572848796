import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { ArtisteCard, FlexibleDiv, Section } from "components";
import { Tags } from "components/lib/Tags/Tags";
import { useEffect, useRef, useState } from "react";
import { useMainContext } from "context";
import { getAllArtist } from "network";
import { useQuery } from "react-query";
import { AllArtistsWrap } from "./all-artists.styles";
import { Skeleton } from "antd";

export const AllArtists = () => {
  const { data: artists, isLoading: isArtistLoading } = useQuery(
    ["all-artists"],
    getAllArtist
  );

  const tags = [
    { title: "All", href: "#" },
    { title: "Hot", href: "#" },
    { title: "Nigeria", href: "#" },
    { title: "South African", href: "#" },
    { title: "Ghanaian", href: "#" },
  ];
  const [activeTag, setActiveTag] = useState<string>("All");

  const handleTagChange = (newTag: string) => setActiveTag(newTag);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveTag("All");
  }, []);
  return (
    <PlayerLayout>
      <AllArtistsWrap>
        <Section
          className="artist__header"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <h4 className="user__mini__subheader">Artists</h4>
          <Tags
            width="fit-content"
            setActiveTag={handleTagChange}
            activeTag={activeTag}
            tags={tags}
          />
        </Section>
        {activeTag === "All" &&
          (isArtistLoading ? (
            <FlexibleDiv minHeight="300px">
              <Skeleton active={isArtistLoading} />
            </FlexibleDiv>
          ) : (
            <Section className="artist__grid">
              {artists &&
                artists?.data?.map((art) => {
                  return <ArtisteCard key={art?.id} {...art} />;
                })}
            </Section>
          ))}
      </AllArtistsWrap>
    </PlayerLayout>
  );
};
