import { last, uniqBy } from "lodash";
import { Types } from "context/types";
import { ActionProps } from "context/reducer";
import { PlayerState } from "./player.types";
import { Track } from "types";

export const playerReducer = (
  state: PlayerState,
  { type, payload }: ActionProps
) => {
  switch (type) {
    case Types.IS_PLAYER_ACTIVE:
      return {
        ...state,
        isPlayerActive: payload,
      };

    case Types.PLAYING_LIST_ACTIVE_TRACKS:
      return {
        ...state,
        playingList: {
          ...state.playingList,
          tracks: uniqBy(payload, "track.id"),
        },
      };

    case Types.SET_PLAYING_PLAYLIST:
      return {
        ...state,
        playingList: payload,
      };

      case Types.UPDATE_CURRENT_PLAYLIST:
        return {
          ...state,
          currentPlaylist: payload,
        };

      case Types.PLAY_NEXT_TRACK:
        const pnQueuedTracks = Array.from(state.queuedTracks);
        const pnPrevTracks = Array.from(state.prevTracks);
        let curTrack: Track | undefined = undefined;
        // Handle loopQueue behavior with shuffle integration
        if (state.repeat === "loopQueue") {
          // If the queue is empty and there are prevTracks, reset the queue with prevTracks
          if (pnQueuedTracks.length === 0 && pnPrevTracks.length > 0) {
            pnQueuedTracks.push(...pnPrevTracks);
            pnPrevTracks.length = 0; // Clear prevTracks after adding to queue
          }
          // If shuffle is enabled, pick a random track from the queue
          if (state.shuffle) {
            const randomIndex = Math.floor(Math.random() * pnQueuedTracks.length);
            curTrack = pnQueuedTracks[randomIndex];
            pnQueuedTracks.splice(randomIndex, 1); // Remove the track after it's played
          } else {
            // If shuffle is disabled, always play the first track in the queue (for loop behavior)
            if (pnQueuedTracks.length > 0) {
              curTrack = pnQueuedTracks[0];
              const trackToPush = pnQueuedTracks.shift(); // Shift the first track from the queue
              if (trackToPush) {
                pnPrevTracks.push(trackToPush);
              }
            }
          }
        } else {
          // Normal next track behavior (if not looping)
          if (pnQueuedTracks.length > 0) {
            if (state.shuffle) {
              // If shuffle is enabled, pick a random track from the queue
              const randomIndex = Math.floor(Math.random() * pnQueuedTracks.length);
              curTrack = pnQueuedTracks[randomIndex];
              pnQueuedTracks.splice(randomIndex, 1); // Remove the track after it's played
            } else {
              // If shuffle is disabled, follow the normal sequential order
              const curTrackIdx = pnQueuedTracks.findIndex(
                (pnt) => pnt?.track?.id === payload?.track?.id || pnt.id === payload?.id
              );
      
              // Play the next track
              if (pnQueuedTracks.length > 1) {
                curTrack = pnQueuedTracks[curTrackIdx + 1];
                pnPrevTracks.push(pnQueuedTracks[curTrackIdx]); // Add current track to prevTracks
                pnQueuedTracks.shift(); // Remove the first track from the queue
              } else {
                // If there's only one track left in the queue, just move it to prevTracks
                curTrack = pnQueuedTracks[0];
                pnPrevTracks.push(pnQueuedTracks[0]);
                pnQueuedTracks.shift(); // Remove the last track from the queue
              }
            }
          } else {
            // If the queue is empty, use the payload (track passed when clicking "next")
            curTrack = payload;
          }
        }
      
        return {
          ...state,
          currentTrack: curTrack,
          prevTracks: pnPrevTracks,
          queuedTracks: pnQueuedTracks,
          isPlaying: true,
        };
      
    case Types.PLAY_PREV_TRACK:
      const ppPrevTracks = Array.from(state.prevTracks);
      const prevTrackToPlay = last(ppPrevTracks);
      const shouldNotUpdatePrevTrack = prevTrackToPlay === undefined;

      const filteredPrevTracks = ppPrevTracks.filter(
        (pt) => pt.id !== payload.id && pt.id !== prevTrackToPlay?.id
      );
      const ppQueuedTracks = Array.from(state.queuedTracks);
      ppQueuedTracks.unshift(payload);

      return {
        ...state,
        currentTrack: shouldNotUpdatePrevTrack ? payload : prevTrackToPlay,
        prevTracks: filteredPrevTracks,
        queuedTracks: shouldNotUpdatePrevTrack
          ? state.queuedTracks
          : ppQueuedTracks,
        isPlaying: true,
      };

    case Types.IS_PLAYING:
      return {
        ...state,
        isPlaying: payload,
      };

    case Types.REPEAT:
      return {
        ...state,
        repeat: payload,
      };

    case Types.CURRENT_PLAYING_TIME:
      return {
        ...state,
        currentTime: payload,
      };

    case Types.SHUFFLE:
      return {
        ...state,
        shuffle: payload,
      };

    case Types.CURRENT_VOLUME:
      return {
        ...state,
        volume: payload,
      };

    case Types.MUTE_PLAYER:
      return {
        ...state,
        isPlayerMuted: payload,
      };

    case Types.SET_CURRENT_PLAYING:
      return {
        ...state,
        currentTrack: payload,
      };

    case Types.SET_PLAY_TRACK:
      return {
        ...state,
        isPlaying: true,
      };

    case Types.SET_PAUSE_TRACK:
      return {
        ...state,
        isPlaying: false,
        isPaused: true,
      };

    case Types.SET_STOP_TRACK:
      return {
        ...state,
        currentTime: 0,
        isPlaying: false,
        isPaused: false,
      };

    case Types.SET_FSEM:
      return {
        ...state,
        fsem: payload,
      };

    case Types.ADD_TO_QUEUE:
      const atq = Array.isArray(payload) ? payload : [payload];
      const qTracks = Array.from(state.queuedTracks);

      return {
        ...state,
        queuedTracks: qTracks.concat(atq),
      };

    case Types.REPLACE_QUEUED_TRACKS:
      return {
        ...state,
        queuedTracks: payload,
      };

    case Types.REPLACE_PREV_TRACKS:
      return {
        ...state,
        prevTracks: payload,
      };
    default:
      return state;
  }
};
