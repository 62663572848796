import { FlexibleDiv } from "components"
import styled from "styled-components"

export const CollabSongsTabDisplayWrap = styled(FlexibleDiv)`
  .no__tracks__added__wrap {
    width: 100%;

    .filter__playlist__box {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 0;
      padding: 1rem 0;

      .organize__by {
        cursor: pointer;
        display: flex;
        border: 0.5px solid var(--borderLight);
        padding: 10px 2px;
        width: 307px;
        max-width: 150px;

        > p {
          margin: 0;
          font-family: "Bw Modelica", sans-serif;
          color: rgba(217, 217, 217, 0.7);
          white-space: nowrap;
        }

        > img {
          width: 18px;
          margin-left: 15px;
        }
      }
    }

    .suggestion__title,
    .perfect__title {
      font-weight: 700;
      font-weight: "Bw Modelica";
      font-size: 1.2rem;
      text-align: left;
      margin: 3rem 0rem 0.8rem 0rem;
      line-height: 38px;
      width: 100%;
      white-space: nowrap;
      @media screen and (max-width: 765px) {
        font-size: 0.9rem;
        margin: 0.6rem 0rem 0.8rem 0rem;
      }
    }

    .perfect__title {
      margin-bottom: 1rem;
      text-align: center;
    }

    .search__input__no__tracks {
      width: 50%;
      max-width: 550px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      margin: 0 auto;
      flex-direction: row;
      border: 0.5px solid var(--borderMain);
      @media screen and (max-width: 765px) {
        width: 80%;
      }
      > input {
        border: none;
        outline: none;
        width: 90%;
        padding-left: 5px;
        color: var(--primaryWhite);
        background-color: transparent;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }
`

export const CollabTabDisplayWrap = styled(FlexibleDiv)`
  .add__main {
    width: 15%;
    height: 220px;
    padding: 2% 0.5rem;
    flex-wrap: nowrap;
    cursor: pointer;

    background: linear-gradient(0deg, #ffffff54, #0a0a0a);

    &:hover .add__icon {
      svg {
        color: var(--primaryYellow);
      }
    }
  }
  .add__icon {
    height: 80%;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--borderLight);
    font-size: 3rem;
  }

  .add__text {
    font-size: 12px;
  }

  @media screen and (max-width: 1024px) {
    .add__main {
      width: 60%;
      padding: 2%;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    .add__icon {
      height: 70%;
      font-size: 3rem;
    }
    .add__text {
      text-align: center;
    }
  }
`

export const InActivePlaylistWrap = styled(FlexibleDiv)`
  padding: 4rem 0.5rem 1rem;

  h2 {
    color: var(--primaryYellow);
    padding-bottom: 3rem;
  }

  .text {
    font-size: 1rem;
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 2rem 0.5rem 1rem;

    h2 {
      text-align: center;
    }

    .text {
      text-align: center;
    }
  }
`

export const SearchCollabUserWrap = styled(FlexibleDiv)`
  padding: 3rem 0.5rem 1rem;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
  }

  .title {
    color: var(--primaryYellow);
    padding-bottom: 1rem;
  }

  .description {
    font-size: 1rem;
    padding-bottom: 2rem;
    text-align: center;
  }

  .search__wrap {
    padding-left: 0.5rem;
    height: 50px;
    width: 60%;
    border: 1px solid var(--borderLighter);
    justify-content: flex-start;
    cursor: pointer;

    svg {
      font-size: 1.85rem;
      color: var(--primaryYellow);
      margin-right: 0.5rem;
    }

    input.search__field {
      background-color: transparent;
      flex: 1;
      border: none;
      height: 100%;
      color: var(--primaryWhite);
      outline: none;
    }

    .clear__search {
      font-size: 1.85rem;
      color: var(--primaryYellow);
      margin-left: 0.5rem;
    }
  }

  .not__on__udux {
    width: 50%;
    padding-top: 3rem;

    .title {
      color: var(--primaryWhite);
      padding-bottom: 1rem;
    }
  }

  .loader {
    margin-top: 2rem;
  }

  .data__center {
    padding-top: 2rem;
    width: 85%;
  }

  @media screen and (max-width: 1024px) {
    padding: 2rem 0.5rem 1rem;

    header {
      display: flex;
      flex-direction: column;
    }

    .title {
    }

    .description {
      font-size: 0.85rem;
    }

    .search__wrap {
      padding-left: 0.5rem;
      height: 40px;
      width: 100%;

      svg {
        font-size: 1.5rem;
      }

      .clear__search {
        font-size: 1rem;
      }
    }

    .not__on__udux {
      width: 100%;

      .title {
        font-size: 1rem;
      }

      button {
        width: 50%;
      }
    }

    .loader {
      margin-top: 2rem;
    }

    .data__center {
      padding-top: 2rem;
      width: 100%;
    }
  }
`

export const UserListItemWrap = styled(FlexibleDiv)`
  flex-wrap: nowrap;
  width: 70%;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.2rem 0.5rem 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--borderLighter);
  }

  &:hover {
    background-color: var(--secondaryBlack);
  }

  .image__wrap {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0 0.5rem;

    .username {
      font-size: 1.5rem;
    }
    .profile__detail {
      font-size: 0.75rem;
    }
  }

  button {
    border: 1px solid var(--primaryWhite);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0.2rem 0.5rem 0.5rem;

    .image__wrap {
      width: 40px;
      height: 40px;
    }

    .user__details {
      .username {
        font-size: 1.2rem;
      }
      .profile__detail {
        font-size: 0.55rem;
      }
    }
  }
`

export const UserInvitationItemWrap = styled(FlexibleDiv)`
  flex-wrap: nowrap;
  width: 70%;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--borderLighter);
  }

  &:hover {
    background-color: var(--secondaryBlack);
  }

  .image__wrap {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0 0.5rem;

    .username {
      font-size: 1rem;
      padding-bottom: 0.2rem;
    }
    .profile__detail {
      font-size: 0.65rem;
    }
    .expires {
      font-size: 0.65rem;
      color: var(--dampWhiteLight);
    }
    .status {
      font-size: 0.65rem;
      text-transform: capitalize;
    }
  }

  button {
    border: 1px solid var(--primaryWhite);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0.2rem 0.5rem 0.5rem;

    .image__wrap {
      width: 40px;
      height: 40px;
    }

    .user__details {
      .username {
        font-size: 1.2rem;
      }
      .profile__detail {
        font-size: 0.55rem;
      }
    }
  }
`

export const TheCollabLineupWrap = styled(FlexibleDiv)`
  header {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .title {
    color: var(--primaryYellow);
    padding-bottom: 1rem;
  }

  .description {
    font-size: 1rem;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 0rem 0.5rem;

    .title {
    }

    .description {
      font-size: 0.85rem;
    }
  }
`

export const CollaboratorItemWrap = styled(FlexibleDiv)`
  flex-wrap: nowrap;
  width: 70%;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--borderLighter);
  }

  &:hover {
    background-color: var(--secondaryBlack);
  }

  .image__wrap {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0 0.5rem;

    .username {
      font-size: 1rem;
      padding-bottom: 0.2rem;
    }
    .profile__detail {
      font-size: 0.65rem;
    }
    .expires {
      font-size: 0.65rem;
      color: var(--dampWhiteLight);
    }
    .status {
      font-size: 0.65rem;
      text-transform: capitalize;
    }
  }

  .icon__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding: 0 0.5rem;

    .status {
      font-size: 0.65rem;
      text-transform: capitalize;
      margin-right: 0.5rem;
    }

    .ant-dropdown-trigger {
      :hover,
      :focus {
        background: transparent;
        border: none;
        color: "white";
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0.2rem 0.5rem 0.5rem;

    .image__wrap {
      width: 40px;
      height: 40px;
    }

    .user__details {
      .username {
        font-size: 1.2rem;
      }
      .profile__detail {
        font-size: 0.55rem;
      }
    }
  }
`
