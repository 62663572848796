import { ResponseSearch, ResponseSearchTracks, UserResponseSearch } from "types"
import { instance } from "./axios"

export const getSearchHistory = async (userId: string) => {
  const response = await instance.get<ResponseSearch>(
    `/data/search/history?user=${userId}`
  )

  return response.data
}

export const generalSearch = async (
  category: string = "combined",
  query: string
) => {
  const sc = category
  const { data } = await instance.get<ResponseSearch>(
    `/data/search/${sc}?q=${query}`
  )

  return data
}

export const searchTracks = async (query: string) => {
  const { data } = await instance.get<ResponseSearchTracks>(
    `/data/search/tracks?q=${query}`
  )

  return data
}

export const searchUser = async (query: string) => {
  const { data } = await instance.get<UserResponseSearch>(
    `/data/search/users?q=${query}`
  )

  return data
}
