import { Loader, OverflowScroll } from "components"
import { Types, useMainContext } from "context"
import {
  useFetchPlaylistById,
  useGetAllTracks,
  useQueryPlaylist,
} from "network/hooks"
import { PlaylistWrap } from "pages/Playlist/playlist.styles"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { PlaylistJumbotron } from "../PlaylistJumbotron/PlaylistJumbotron"
import { PlaylistOrganizer } from "../PlaylistOrganizer"
import { PlaylistType } from "types/playlist"
import { CollabPlaylistOrganizer } from "../CollabPlaylistOrganizer"

export const PlaylistModule = () => {
  const { playlistId } = useParams() as { playlistId: string }
  const {
    state: { users },
    dispatch,
  } = useMainContext()
  const { data, isLoading } = useFetchPlaylistById(playlistId)
  const { data: spData, isLoading: spIsLoading } =
    useQueryPlaylist("managedBy=ADMIN")
  const { data: trackData } = useGetAllTracks()

  const { me } = users
  const tracks = data?.tracks || []
  const suggestedTracks = trackData?.data
  const coverArt = data?.coverArt?.url
  const isOwner = data?.user?.id === me?.id

  useEffect(() => {
    dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: data })
  }, [dispatch, data])

  const jumboData = {
    domColors: [`${data?.coverArtDominantColors[0]}`, `#000`],
    coverArt: data?.coverArt,
    tracks,
    playlistId,
    isPrivate: data?.private,
    title: data?.title || "",
    description: data?.description || "",
    curator: data?.editors[0].id === me?.id ? "You" : data?.editors[0].username || "",
    stats: {
      likes: data?.statistics?.likes || 0,
      songs: data?.tracks.length || 0,
    },
    type: data?.type,
  }

  return (
    <OverflowScroll>
      {isLoading ? (
        <Loader wrapperHeight="85vh" />
      ) : (
        <PlaylistWrap>
          <PlaylistJumbotron {...jumboData} />

          {data?.type === PlaylistType.COLLABORATIVE ? (
            <CollabPlaylistOrganizer
              playlist={data}
              isOwner={isOwner}
              suggestedTracks={suggestedTracks}
            />
          ) : (
            <PlaylistOrganizer
              module="playlist"
              tracks={tracks}
              isOwner={isOwner}
              coverArt={coverArt}
              loading={spIsLoading}
              similarFromData={spData}
              suggestedTracks={suggestedTracks}
            />
          )}
        </PlaylistWrap>
      )}
    </OverflowScroll>
  )
}
