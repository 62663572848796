import {
  FavoriteFilledIcon,
  FsePlayingIcon,
  LikeIcon,
  MoreIcon,
  PlayIcon,
  ShuffleOutlineIcon,
} from "assets";
import { PlaylistMoreMenu } from "./PlaylistMoreMenu";
import { Dropdown } from "antd";
import { HiOutlineUserAdd } from "react-icons/hi";
import { CgMoreVertical } from "react-icons/cg";
import { BsDownload } from "react-icons/bs";
import {
  CollabControlsWrap,
  StandardControlsWrap,
} from "./jumbotron-controls.styles";
import { useState } from "react";
import { InviteCollabUserModal } from "components/lib/Modals/InviteCollabUserModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  pauseAudio,
  playAudio,
  playTrack,
  shuffleTrack,
  unShuffleTrack,
} from "utils";
import { Types, useMainContext } from "context";
import { ReactionType, Track } from "types";
import { useMutation } from "react-query";
import {
  addReaction,
  deletePlaylist,
  errorResponseHandler,
  removeReaction,
  successResponseHandler,
} from "network";
import { ConfirmDeleteModal } from "components";
import { CreatePlaylistModal } from "components/lib/Modals/CreatePlaylistModal";
import { useEditStandardPlaylistService } from "network/hooks";

export type StandardControlsProps = {
  playlistTracks?: Track[];
  playlistId?: string;
  playlistTitle?: string;
  playlistCoverArt?: string;
  playlistDescription?: string;
};

export const CollabControls = () => {
  const [isSendInvite, setIsSendInvite] = useState(false);
  const { playlistId } = useParams();
  const [likedPlaylist, setLikedPlaylist] = useState(false);
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);
  const { mutate: mutateDeletePlaylist, isLoading: deleteLoading } =
    useMutation(deletePlaylist);
  const navigate = useNavigate();

  const {
    dispatch,
    state: { player, app },
  } = useMainContext();
  const { isLoggedIn } = app;
  const { currentPlaylist } = player;
  const { mutate: mutateStandardPlaylist, isLoading: standardLoading } =
    useEditStandardPlaylistService();
  const [isPlaylistModal, setIsPlaylistModal] = useState<
    "standard" | "collab"
  >();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleEditPlaylist = () => {
    setIsPlaylistModal("collab");
  };
  return (
    <CollabControlsWrap>
      {/* <div className="icon__wrap hover__fill">
        <BsDownload color="#fff" />
      </div> */}
      <div
        className="icon__wrap hover__fill"
        onClick={() => setIsSendInvite(true)}
      >
        <HiOutlineUserAdd color="#fff" />
      </div>
      {currentPlaylist && (
        <div className="icon__wrap hover__fill">
          {likedPlaylist ? (
            <div
              className="icons__wrap"
              onClick={() =>
                toggleLikePlaylist(currentPlaylist?.id, "playlist")
              }
            >
              <FavoriteFilledIcon height="25" />
            </div>
          ) : (
            <div
              className="icons__wrap"
              onClick={() =>
                toggleLikePlaylist(currentPlaylist?.id, "playlist")
              }
            >
              <LikeIcon height="25" />
            </div>
          )}
        </div>
      )}
      <div className="icon__wrap hover__fill">
        <Dropdown
          trigger={["click"]}
          overlay={
            <PlaylistMoreMenu
              handleEditPlaylist={handleEditPlaylist}
              handleDelete={handleDeletePlaylist}
            />
          }
          placement="bottomRight"
        >
          <CgMoreVertical color="#fff" height="19" />
        </Dropdown>
      </div>

      {isSendInvite && (
        <InviteCollabUserModal
          playlistId={playlistId || ""}
          open={isSendInvite}
          handleClose={() => setIsSendInvite(false)}
        />
      )}

      <CreatePlaylistModal
        handleClose={() => setIsPlaylistModal(undefined)}
        open={isPlaylistModal === "collab"}
        title="Edit Playlist"
        isLoading={standardLoading}
        editMode
        playlistData={{
          title: currentPlaylist?.title || "",
          description: currentPlaylist?.description || "",
          type: currentPlaylist?.type || "",
          coverArt: currentPlaylist?.coverArt?.url || "",
        }}
        handleBtnClick={(payload) => {
          mutateStandardPlaylist(
            { payload, playlistId: playlistId || "" },
            {
              onSuccess: ({ data }) => {
                setIsPlaylistModal(undefined);
                navigate(`/playlist/${data.id}?t=pl`);
              },
            }
          );
        }}
      />

      <ConfirmDeleteModal
        handleClose={() => setConfirmDelete(false)}
        open={confirmDelete}
        title="Delet from your Library?"
        warningContent={`This will delete ${currentPlaylist?.title} from your Library.`}
        isLoading={deleteLoading}
        handleBtnClick={() => {
          mutateDeletePlaylist(playlistId, {
            onSuccess: (res) => {
              setConfirmDelete(false);
              dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: {} });
              successResponseHandler(res);
              navigate("/");
            },
            onError: (err) => {
              errorResponseHandler(err);
            },
          });
        }}
      />
    </CollabControlsWrap>
  );

  function toggleLikePlaylist(entity: string, entityType: string) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    const payload = { entity, entityType };

    if (likedPlaylist) {
      setLikedPlaylist(false);
      mutateUnlike(payload, {
        onSuccess: () => {
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          });
        },
        onError: (error) => errorResponseHandler(error),
      });
    } else {
      setLikedPlaylist(true);
      mutateLike(
        { ...payload, type: ReactionType.LIKE },
        {
          onSuccess: () => {
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            });
          },
          onError: (error) => errorResponseHandler(error),
        }
      );
    }
  }
  function handleDeletePlaylist() {
    setConfirmDelete(true);
  }
};

export const StandardControls = () => {
  const { playlistId } = useParams();
  const { mutate: mutateDeletePlaylist, isLoading: deleteLoading } =
    useMutation(deletePlaylist);
  const navigate = useNavigate();

  const {
    dispatch,
    playerRef,
    state: { player, app, playlist },
  } = useMainContext();
  const { isLoggedIn } = app;
  const {
    currentTrack,
    currentPlaylist,
    isPlaying,
    isPaused,
    shuffle,
    queuedTracks,
  } = player;
  const { mutate: mutateStandardPlaylist, isLoading: standardLoading } =
    useEditStandardPlaylistService();
  const isPlayingTrack = isPlaying;
  const [likedPlaylist, setLikedPlaylist] = useState(false);
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);
  const {userPlaylists} = playlist
  const [isPlaylistModal, setIsPlaylistModal] = useState<
    "standard" | "collab"
  >();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const playlistTracks = currentPlaylist?.tracks;
  const hasTracks = playlistTracks && playlistTracks.length > 0;
  const handleEditPlaylist = () => {
    setIsPlaylistModal("standard");
  };

  return (
    <StandardControlsWrap>
      {hasTracks && (
        <div className="icon__wrap hover__fill">
          {isPlayingTrack ? (
            <FsePlayingIcon
              width={20}
              height={20}
              color="#fff"
              onClick={() => {
                pauseAudio(playerRef, dispatch);
              }}
            />
          ) : (
            <>
              {isPaused && currentTrack?.track?.id ? (
                <PlayIcon
                  height={"25"}
                  onClick={() => playAudio(playerRef, dispatch)}
                  color="#fff"
                />
              ) : (
                <>
                  {playlistTracks && (
                    <PlayIcon
                      height="25"
                      color="#fff"
                      onClick={() => handlePlayTrack(playlistTracks[0]?.track)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      {hasTracks && (
        <div className="icon__wrap hover__fill">
          {shuffle ? (
            <ShuffleOutlineIcon
              height={25}
              onClick={() => unShuffleTrack(queuedTracks, dispatch)}
              color="var(--yellowPrimary)"
            />
          ) : (
            <ShuffleOutlineIcon
              color="#fff"
              onClick={() => shuffleTrack(queuedTracks, dispatch)}
              height={25}
            />
          )}
        </div>
      )}
      {currentPlaylist && (
        <div className="icon__wrap hover__fill">
          {likedPlaylist ? (
            <div
              className="icons__wrap"
              onClick={() =>
                toggleLikePlaylist(currentPlaylist?.id, "playlist")
              }
            >
              <FavoriteFilledIcon height={25} />
            </div>
          ) : (
            <div
              className="icons__wrap"
              onClick={() =>
                toggleLikePlaylist(currentPlaylist?.id, "playlist")
              }
            >
              <LikeIcon height={25} />
            </div>
          )}
        </div>
      )}
      {/* <div className="icon__wrap hover__fill">
        <DownloadIcon color="#fff" height={"25"} />
      </div> */}
      <div className="icon__wrap hover__fill">
        <Dropdown
          trigger={["click"]}
          overlay={
            <PlaylistMoreMenu
              handleEditPlaylist={handleEditPlaylist}
              handleDelete={handleDeletePlaylist}
            />
          }
          placement="bottomRight"
        >
          <MoreIcon color="#fff" height="25" />
        </Dropdown>
      </div>

      <CreatePlaylistModal
        handleClose={() => setIsPlaylistModal(undefined)}
        open={isPlaylistModal === "standard"}
        title="Edit Playlist"
        isLoading={standardLoading}
        editMode
        playlistData={{
          title: currentPlaylist?.title || "",
          description: currentPlaylist?.description || "",
          type: currentPlaylist?.type || "",
          coverArt: currentPlaylist?.coverArt?.url || "",
        }}
        handleBtnClick={(payload) => {
          mutateStandardPlaylist(
            { payload, playlistId: playlistId || "" },
            {
              onSuccess: ({ data }) => {
                setIsPlaylistModal(undefined);
                navigate(`/playlist/${data.id}?t=pl`);
              },
            }
          );
        }}
      />

      <ConfirmDeleteModal
        handleClose={() => setConfirmDelete(false)}
        open={confirmDelete}
        title="Delet from your Library?"
        warningContent={`This will delete ${currentPlaylist?.title} from your Library.`}
        isLoading={deleteLoading}
        handleBtnClick={() => {
          mutateDeletePlaylist(playlistId, {
            onSuccess: (res) => {
              setConfirmDelete(false);
              dispatch({
                type: Types.UPDATE_USER_PLAYLISTS,
                payload: userPlaylists.filter(
                  (playlist) => playlist.id !== playlistId
                ),
              });
              dispatch({ type: Types.UPDATE_CURRENT_PLAYLIST, payload: {} });
              successResponseHandler(res);
              navigate("/");
            },
            onError: (err) => {
              errorResponseHandler(err);
            },
          });
        }}
      />
    </StandardControlsWrap>
  );

  function toggleLikePlaylist(entity: string, entityType: string) {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    const payload = { entity, entityType };

    if (likedPlaylist) {
      setLikedPlaylist(false);
      mutateUnlike(payload, {
        onSuccess: () => {
          successResponseHandler({
            message: `${entityType} un-liked successfully.`,
          });
        },
        onError: (error) => errorResponseHandler(error),
      });
    } else {
      setLikedPlaylist(true);
      mutateLike(
        { ...payload, type: ReactionType.LIKE },
        {
          onSuccess: () => {
            successResponseHandler({
              message: `${entityType} liked successfully.`,
            });
          },
          onError: (error) => errorResponseHandler(error),
        }
      );
    }
  }

  function handlePlayTrack(payload: any) {
    playTrack(dispatch, playerRef, payload);
    dispatch({
      type: Types.REPLACE_QUEUED_TRACKS,
      payload: playlistTracks,
    });
  }

  function handleDeletePlaylist() {
    setConfirmDelete(true);
  }
};
