import { FlexibleDiv } from "components";
import styled from "styled-components";

export const GenresWrap = styled(FlexibleDiv)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: 1024px) {
    padding: 0 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 765px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
