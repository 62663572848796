import { Collaboration } from "types"
import { CollaboratorItem } from "./CollaboratorItem"
import { NoDataDisplay } from "components"

export type CollaboratorsTabDisplayProps = {
  collaborators?: Collaboration[]
}

export const CollaboratorsListTabDisplay = ({
  collaborators,
}: CollaboratorsTabDisplayProps) => {
  return collaborators?.length ? (
    collaborators?.map(invite => (
      <CollaboratorItem
        key={invite.id}
        id={invite.id}
        emailAddress={invite.potentialNewUserEmail}
        receiver={invite.receiver}
        expires={invite.expires}
        message={invite.message}
        status={invite.status}
      />
    ))
  ) : (
    <NoDataDisplay text="No pending invites" height="300px" />
  )
}
