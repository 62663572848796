import { FlexibleDiv } from "components"
import styled from "styled-components"

export const CollabRequestTabWrap = styled(FlexibleDiv)`
  @media screen and (max-width: 1024px) {
    padding: 0.5rem 1rem;
  }
`

export const CollabRequestItemWrap = styled(FlexibleDiv)`
  justify-content: flex-start;
  margin-bottom: 1rem;
  align-items: center;
  padding: 0.5rem;

  &:hover {
    transition: all 0.3s ease-in-out;
    background-color: var(--backgroundDarkLight);
  }

  .info {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .avatar__icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    & .ant-avatar {
      background: var(--primaryYellow);
    }
  }

  .notification__text {
    font-size: 0.75rem;
    color: var(--primaryWhite);
  }
  .timestamp {
    margin-left: 1rem;
    font-size: 0.65rem;
    color: var(--primaryWhite);
    opacity: 0.5;
  }

  .action__wrap {
    flex: 1;
    flex-wrap: nowrap;
    justify-content: flex-start;

    button {
      width: 20%;

      span {
        font-size: 0.65rem;
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  .timestamp__expiry {
    font-size: 0.65rem;
    color: var(--primaryWhite);
    opacity: 0.5;
    margin-left: 2rem;
  }

  .playlist__title {
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .action__wrap {
      margin-top: 1rem;
    }

    button {
      width: 50px;
    }
  }
`
