import { Dropdown } from "components"
import { capitalize } from "lodash"
import { useInviteCollabUserService } from "network/hooks"
import { CollaboratorMoreMenu } from "pages/Playlist/components/PlaylistJumbotron/components/collaborator-more-menu"
import { useState } from "react"
import { CgMoreVertical } from "react-icons/cg"
import { CollabRequestRecSen, CollabRequestStatus } from "types"
import { CollaboratorItemWrap } from "../styles"

export type CollaboratorItemProps = {
  // user: User
  expires: number
  id: string
  emailAddress: string
  receiver: CollabRequestRecSen
  status: CollabRequestStatus
  message?: string
}
export const CollaboratorItem = ({
  id,
  emailAddress,
  expires,
  status,
  message,
  receiver,
}: CollaboratorItemProps) => {
  const { mutate, isLoading } = useInviteCollabUserService()
  const username = receiver?.username || emailAddress

  return (
    <CollaboratorItemWrap key={id}>
      {/* <div className="image__wrap">
        <img
          src={user.profile.profilePicture.url}
          alt={`${user.username} photo`}
        />
      </div> */}

      <div className="user__details">
        <p className="username">{username}</p>
        <p className="expires">1 song added</p>
      </div>

      <div className="icon__wrap hover__fill">
        <p className="status">Collaborator</p>
        <Dropdown
          trigger={["click"]}
          overlay={<CollaboratorMoreMenu handleDelete={() => {}} />}
          placement="bottomRight"
        >
          <CgMoreVertical color="#fff" />
        </Dropdown>
      </div>
    </CollaboratorItemWrap>
  )
}
