import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export const SearchTracksWrap = styled(FlexibleDiv)`
  width: 50%;
  max-width: 550px;
  padding: 10px 5px;
  flex-wrap: nowrap;
  margin: 0 auto;
  flex-direction: row;
  border: 0.5px solid var(--borderMain);

  @media screen and (max-width: 765px) {
    width: 80%;
  }

  > input {
    border: none;
    outline: none;
    width: 90%;
    padding-left: 5px;
    color: var(--primaryWhite);
    background-color: transparent;
    font-family: var(--mRegular);
  }
`
