import { AlbumCard, FlexibleDiv } from "components";
import { ExploreVibesWrap } from "../history.styles";
import { Link } from "react-router-dom";
import { VibesTrackItem } from "./VibesTrackItem";
import { Track } from "types";
import { Skeleton } from "antd";
import { take } from "lodash";

export type ExploreVibesProps = {
  tracks?: Track[];
  isLoading?: boolean;
};
export const ExploreVibes = ({ tracks, isLoading }: ExploreVibesProps) => {
  return isLoading ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={isLoading} />
    </FlexibleDiv>
  ) : (
    <ExploreVibesWrap
      dominantColors={tracks ? tracks[5]?.metadata?.coverArtDominantColors : []}
    >
      <h4>Explore Vibes</h4>

      {tracks && <AlbumCard handleNowPlaying={() => {}} {...tracks[0]} />}

      <FlexibleDiv
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexibleDiv alignItems="center" justifyContent="space-between">
          <h5>Vibes Digest</h5>
          <Link to="#">Go to Playlist</Link>
        </FlexibleDiv>

        <FlexibleDiv className="vibes__digest__wrap">
          {tracks &&
            take(tracks, 4)?.map((trk, idx) => {
              return <VibesTrackItem key={idx} idx={idx + 1} track={trk} />;
            })}
        </FlexibleDiv>
      </FlexibleDiv>
    </ExploreVibesWrap>
  );
};
