import { Modal } from "antd"
import { FlexibleDiv } from "components"
import styled from "styled-components"

export const AREWrap = styled(FlexibleDiv)`
  background-color: var(--errorMain);
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 2rem;
  position: relative;

  svg {
    font-size: 1.5rem;
    margin-right: 10%;
  }

  p {
    margin: 0;
  }

  .message {
    width: 75%;
    font-size: 0.85rem;
  }

  .see__more {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem 1rem;

    svg {
      margin-right: 5%;
    }

    .message {
      font-size: 0.65rem;
      width: 80%;
    }

    .see__more {
      font-size: 0.75rem;
    }
  }
`

export const AREModalWrap = styled(Modal)`
  .ant-modal-content {
    color: var(--dampWhiteLight);
    background-color: var(--uduxGraySecondary);

    .ant-modal-close-x {
      color: var(--dampWhiteLight);
    }
  }

  .ant-modal-header {
    color: var(--primaryWhite);
    background-color: var(--uduxGraySecondary);
    border-bottom: 0.5px solid var(--primaryGray);
  }

  .ant-modal-title {
    color: var(--primaryWhite);
  }

  .ant-modal-footer {
    border-top: 0.5px solid var(--primaryGray);

    .ant-btn-primary {
      width: 20%;
      border-color: var(--uduxYellowPrimary);
      background-color: var(--uduxYellowPrimary);
    }
    .ant-btn-default {
      width: 20%;
      border-color: var(--errorPrimary);
      background-color: var(--errorPrimary);

      span {
        color: white;
      }
    }
  }
`
