import { FlexibleDiv } from "components";
import styled from "styled-components";

export const AllPlaylistsWrap = styled(FlexibleDiv)`
  .playlist__header {
    row-gap: 30px;

    .user__mini__subheader {
      flex-basis: 50%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--primaryWhite);
      font-family: "Inter", sans-serif;
      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
  }

  .playlist__grid{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    margin-top: 30px;
    row-gap: 30px;
  }
`;
