import { Skeleton } from "antd";
import { FlexibleDiv, PlaylistCard } from "components";
import { take } from "lodash";
import { Link } from "react-router-dom";
import { Playlist } from "types/playlist";
import { PlOrgHelperWrap } from "./index.styles";
import { Track } from "types";
import { PlaylistOrganizerTrackItem } from "./PlaylistOrganizerTrackItem";

export type PlaylistOrganizerHelperProps = {
  module: "playlist";
  loading: boolean;
  tracks?: Track[];
  similarFromData?: Playlist[];
};
export const PlaylistOrganizerHelper = ({
  module,
  loading,
  tracks,
  similarFromData,
}: PlaylistOrganizerHelperProps) => {
  return loading ? (
    <FlexibleDiv minHeight="300px">
      <Skeleton active={loading} />
    </FlexibleDiv>
  ) : (
    <PlOrgHelperWrap>
      <h3 className="explore__title">Explore More Playlists</h3>
      <FlexibleDiv className="mapped__content">
        {similarFromData &&
          similarFromData?.map((pt) => (
            <div
              className="playlist"
              key={pt?.id}
              style={{
                background: tracks
                  ? `linear-gradient(180deg, #000 0%, #${tracks[5]?.metadata?.coverArtDominantColors[0]} 100%)`
                  : "#0b0b0a", // Fallback color
              }}
            >
              <PlaylistCard {...pt} />
              <FlexibleDiv
                flexDir="column"
                justifyContent="space-between"
                alignItems="center"
                className="vibes__digest__wrap"
              >
                <FlexibleDiv alignItems="center" justifyContent="space-between">
                  <h5>Vibes Digest</h5>
                  <Link to="#">Go to Playlist</Link>
                </FlexibleDiv>

                <div className="vibes__tracks__wrap">
                  {tracks &&
                    take(tracks, 4)?.map((trk, idx) => {
                      return (
                        <PlaylistOrganizerTrackItem key={idx} idx={idx + 1} track={trk} />
                      );
                    })}
                </div>
              </FlexibleDiv>
            </div>
          ))}
      </FlexibleDiv>
    </PlOrgHelperWrap>
  );
};
