import { User } from "context"
import { Artist } from "./artist"
import { Playlist } from "./playlist"
import { TotalMonthAndCount } from "./misc"

export type FollowRecord = {
  id: string
  createdAt: string
  updatedAt: string
  deleted: boolean
  status: string
  entityFollowedType: string
  followedUser: User
  followedArtist: Artist
  follower: User
}

export enum ReactionType {
  TUALE = "TUALE",
  LIKE = "LIKE",
  ADD_TO_LIBRARY = "ADD_TO_LIBRARY",
}

export type InviteCollabUserPayload = {
  playlistId: string
  emailAddress?: string
  userId?: string
  message?: string
}

export type Collaboration = {
  id: string
  createdAt: Date
  updatedAt: Date
  deleted: true
  expires: number
  status: CollabRequestStatus
  message: string
  sender: CollabRequestRecSen
  receiver: CollabRequestRecSen
  potentialNewUserEmail: string
  playlist: Playlist
}

// Collab request receiver or sender
export type CollabRequestRecSen = {
  id: string
  emailAddress: string
  username: string
}

export type CollabPlaylistEngagement = {
  user: User
  totalSpins: number
  totalTuales: number
  totalStreamTime: number
}

export type LeaderboardEngagementData = {
  collaborator: {
    _id: string
    username: string
    profile: {
      profilePicture: string
    }
    statistics: {
      _id: string
      deleted: false
      entityType: string
      entity: string
      followers: number
      collabPlaylist: number
      creatorRankings: number
      collaborators: number
      likes: number
      playlistAdds: number
      spins: number
      tuales: number
      streamTime: number
      createdAt: Date
      updatedAt: Date
      __v: number
    }
  }
  engagement: {
    totalSpins: number
    totalTuales: number
    totalStreamTime: number
    user: string
  }
}

export type CollabPlaylistLeaderboardEngagement = {
  totalSpins: LeaderboardEngagementData[]
  totalTuales: LeaderboardEngagementData[]
  totalStreamTime: LeaderboardEngagementData[]
}

export type CollabPlaylistStat = {
  totalPlay: TotalMonthAndCount[]
  totalLikes: TotalMonthAndCount[]
  totalFollowers: TotalMonthAndCount[]
  genderDistributionOfPlaylistStream: PlaylistGenderDistribution
}

export enum CollabRequestStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

export type PlaylistGenderDistribution = {
  male: number
  female: number
  not_specified: number
}
