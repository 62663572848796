import { Form, Select } from "antd"
import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export interface CountryCodeItemProps {
  width?: string
  height?: string
  borderColor?: string
  bordered?: boolean
  hasError?: boolean
  hasSuccess?: boolean
}
export interface PhoneInputWrapProps {
  small?: boolean
}
export interface PhoneInputInnerWrapProps {
  width?: string
  height?: string
  borderColor?: string
  bordered?: boolean
  hasError?: boolean
  disabled?: boolean
  hasSuccess?: boolean
  borderBottom?: string
  border?: string
}

export const CountryCodeSelectView = styled(Select)`
  display: flex;
  height: 45px;
  color: var(--white);
  padding: 1.6rem;
  background: none;
  width: 100%;
  padding: 0;
  position: relative;
  width: 30%;
  margin-right: 1rem;

  svg {
    transition: 0.3s ease;
  }
  &:hover svg {
    stroke: var(--uduxYellowPrimary);
  }
  .name {
    display: none;
  }

  &:hover {
    border-color: var(--uduxYellowPrimary);
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
    font-size: 0.85rem;
    padding-left: 1rem;

    span {
      font-family: "Baguede", sans-serif;
    }
  }
  .ant-select-selection-search {
    display: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary-color);
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ).ant-select-open
    .ant-select-selector {
    border: none;
    background: none;
    box-shadow: none;
  }
  &.ant-select .ant-select-selector {
    padding: 0;
    height: 100%;
  }

  .search {
  }

  @media only screen and (max-width: 380px) {
    height: 2.5em;
  }
`

export const CountryCodeItem = styled(Form.Item)<CountryCodeItemProps>`
  width: ${({ width }) => width || "100%}"};
  height: ${({ height }) => height || "100%"};
  border: ${({ bordered }) =>
    bordered ? "0.5px solid var(--dampWhiteLight)" : "none"};
  border-color: ${({ borderColor, hasError, hasSuccess }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--primaryDanger)"
      : borderColor || "var(--dampWhiteLight)"
  }};
  position: relative;

  .ant-select-selection-item {
    margin-left: 20%;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
  }
  .ant-select-in-form-item {
    margin-left: 5px;
    margin-right: 0;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
    font-size: 0.85rem;

    span {
      font-family: "Baguede", sans-serif;
    }
  }

  .ant-input-group {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .flags__img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`

export const PhoneInputWrap = styled(FlexibleDiv)<PhoneInputWrapProps>`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0;

  .label {
    justify-content: flex-start;
    align-items: center;

    label {
      color: var(--dampWhiteLight);
      margin-bottom: 0.5rem;
      display: inline-flex;
      font-size: ${({ small }) => (small ? "10px" : "14px")};
      font-family: "Baguede", sans-serif;
    }
  }

  .wrapper__main {
    display: flex;
    width: 100%;
    column-gap: 1rem;
  }

  .group__cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      color: var(--dampWhiteLight);
      margin-bottom: 0.5rem;
      display: inline-flex;
      font-size: ${({ small }) => (small ? "10px" : "14px")};
      font-family: "Baguede", sans-serif;
    }
  }

  .country__code__cell {
    width: 30%;
    height: 45px;

    .country__code__input__wrap {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .flag__wrap {
        width: 20px;
        height: 100%;
        display: flex;
        left: 5px;
        position: absolute;
        align-items: center;
        justify-content: center;

        figure {
          width: 20px;
          /* height: 10px; */
          margin: 0;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      > div {
        width: 100%;
      }
    }
  }

  .phone__cell {
    width: 70%;
  }

  .input__wrapper {
    width: 100%;
    height: 45px;
    position: relative;
  }

  .ant-form-item-explain-error {
    height: auto;
    color: var(--primaryDanger);
    font-size: 0.65rem;
  }

  .ant-form-item-row {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    display: flex;
    height: 100%;
  }

  .ant-input-group {
    display: flex;
    height: 100%;
  }

  .input__error__text {
    margin-top: 0.2rem;
    font-family: "Baguede", sans-serif;
    font-size: 0.75rem;
    text-align: left;
    color: var(--errorPrimary);
  }

  @media only screen and (max-width: 600px) {
    .group__cell {
      label {
        font-size: 8px;
      }
    }

    .country__code__cell {
      width: 35%;
    }

    .phone__cell {
      width: 65%;
    }
  }

  @media only screen and (max-width: 380px) {
    .country__code__cell,
    .input__wrapper {
      height: 2.5em;
    }
  }
`

export const Option = styled(Select.Option)`
  span {
    font-family: "Baguede", sans-serif;
  }

  .ant-select-item.ant-select-item-option {
    color: #fff;
    font-size: 0.75rem;
  }
`

export const SearchInput = styled.div`
  padding: 1rem 0.5rem;
  background: #000;
  border-radius: 0;
  border: none;
  outline: none;
  color: var(--primaryWhite);
  font-family: "Baguede", sans-serif;
  width: 100%;

  input {
    width: 100%;
    height: 100%;
    border: solid 0.1rem var(--primaryGray);
    background: none;
    padding: 0.5rem;
    border-radius: 0;
    outline: none;
  }
`

export const PhoneInputInnerWrap = styled(
  FlexibleDiv
)<PhoneInputInnerWrapProps>`
  border: ${props =>
    props.borderBottom ? "none" : props.border || "1px solid #707070"};
  border-bottom: ${props =>
    props.borderBottom || (props.border ? "inherit" : "1px solid #707070")};
  border-color: ${({ borderColor, hasError, hasSuccess, disabled }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--primaryDanger)"
      : disabled
      ? "var(--borderVLight)"
      : borderColor || "var(--dampWhiteLight)"
  }};

  .react-tel-input {
    width: 100%;
    height: 45px;
  }

  .open {
    background: var(--black) !important;
  }

  .special-label {
    color: var(--dampWhiteLight);
  }

  .form-control {
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-size: 0.85rem;
    color: ${({ disabled }) =>
      disabled ? "var(--dampWhiteLight)" : "var(--primaryText)"};
    background: var(--black);
    font-family: "Baguede", sans-serif;
    border: none;

    &:auto-fill {
      background: var(--black);
    }
    &:-webkit-auto-fill&:-webkit-auto-fills:focus,
    &:-internal-autofill-selected {
      background: var(--black);
    }
  }

  .flag-dropdown {
    border-radius: 0;
    background: var(--black);
    font-family: "Baguede", sans-serif;
    border: none;

    .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
      background: var(--black);
    }
  }

  .dropdown-select {
    color: var(--primaryText);
    background: var(--black);
    border: 0.5px solid var(--borderLighter);
    max-height: 300px;

    .country:not(.highlight) {
      transition: all 0.3s ease-in-out;

      :hover {
        color: var(--textBlack);
      }
    }

    .search-input {
      background: var(--black);
      padding: 0.5rem;

      .search-box {
        width: 90%;
        border-radius: 0;
        padding: 0.5rem 0.5rem;
        background: transparent;
        border: 0.5px solid var(--uduxGrayLightAlpha);
      }
    }

    .country.highlight {
      background: var(--black);
      background: transparent;
    }

    .divider {
      border-bottom: 0.5px solid var(--grayLight);
    }
  }
`
