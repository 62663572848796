import styled from "styled-components"
import upmBng from "../../../../assets/images/general/homepage-bg.png"

export const UpdateProfileWrap = styled.div`
  border: 1px solid var(--borderVLight);
  width: 520px;
  display: flex;
  padding: 1rem;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #212121;
  z-index: 1;

  .update-header {
    h3,
    p {
      color: var(--primaryText);
    }

    h3 {
      font-size: 1.5rem;
      line-height: 1;
      text-transform: capitalize;
    }

    p {
      font-size: small;
    }
  }

  @keyframes inAnimation {
    0% {
      transform: translateY(300px);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes outAnimation {
    20% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(300px);
      opacity: 0;
    }
  }

  @media (max-width: 600px) {
    width: 95%;

    .update-header {
      h3,
      p {
        text-align: center;
      }

      h3 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.65rem;
      }
    }
  }
`

export const UPMPrintBackground = styled.div`
  width: 520px;
  height: 90px;
  background: url(${upmBng}) no-repeat center / cover;
  position: absolute;
  bottom: -20px;
  right: -45px;
  transform: skew(30deg, -25deg);

  @media screen and (max-width: 600px) {
    height: 50px;
    bottom: 0px;
    right: 0;
    width: 100%;
    transform: skew(0deg, 0deg);
  }
`

export const UPPortalWrap = styled.div`
  top: 0;
  z-index: 999;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.9);
`
