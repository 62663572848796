import { FlexibleDiv } from "components"
import styled from "styled-components"

export const ActiveSearchWrap = styled(FlexibleDiv)`
  padding: 1rem 4rem;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .active__search__filter__wrap {
    width: 100%;
    top: 0;
    z-index: 99;
    position: sticky;
    margin-bottom: 3rem;
    background-color: var(--primaryBlack);
    justify-content: flex-start;

    &__content {
      width: 50%;
    }

    .close__active__search {
      position: absolute;
      right: 10px;
      cursor: pointer;

      svg {
        font-size: 1.5rem;
      }
    }
  }

  h2 {
    color: var(--primaryText);
    font-size: 1.35rem;
    text-align: left;
  }

  .result__title {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .tracks,
  .x__profile {
    /* width: 48%; */
    flex: 1; /* when the x__profile data is available, tweak this! */
  }

  .songs__display {
    width: 100%;
    justify-content: space-between;
  }

  .mapped__songs {
    width: 100%;
    column-gap: 30px;
    justify-content: flex-start;
  }
  .mapped__content {
    width: 100%;
    column-gap: 30px;
    overflow-y: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .song__gap {
    row-gap: 8px;
  }

  .display__result {
    justify-content: flex-start;
    margin-bottom: 5rem;
    overflow: hidden;
  }

  .artists__display .result__title {
    width: 100%;
    justify-content: space-between;
  }

  .see__all {
    padding-right: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
    color: var(--uduxYellowPrimary);
  }

  .category__filter {
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    &__content {
      width: max-content;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 1rem 2rem;

    .active__search__filter__wrap {
      width: 100%;
      top: 0;
      z-index: 99;
      position: sticky;
      justify-content: flex-start;

      &__content {
        width: 100%;
      }

      .close__active__search {
        display: none;
      }
    }
  }
`
