import { StyledMenu } from "components"
import { FiEdit } from "react-icons/fi"
import { HiRectangleStack } from "react-icons/hi2"

export type CollaboratorsMoreMenuProps = {
  isOwner?: boolean
  handleDelete?: () => void
}

export const CollaboratorMoreMenu = ({
  // isOwner=false,
  handleDelete,
}: CollaboratorsMoreMenuProps) => {
  const items = [
    {
      label: (
        <div className="menu_box">
          <HiRectangleStack width={18} />
          <p>Remove as collaborator</p>
        </div>
      ),
    },

    {
      label: (
        <div className="menu_box">
          <FiEdit width={18} />
          <p>Remove from playlist</p>
        </div>
      ),
    },
  ]

  return (
    <StyledMenu
      top="0.5rem"
      items={items.map((menuItem, idx) => ({ ...menuItem, key: idx }))}
    />
  )
}
