import hideIcon from "assets/images/Auth/eye-hide.png"
import showIcon from "assets/images/Auth/eye-show.png"
import classNames from "classnames"
import React, { memo, useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { MdCancel as Cancel } from "react-icons/md"
import { StatusState } from "types"
import { ValidateInputProps } from "utils"
import {
  BorderedFormItem,
  BorderedFormItemProps,
  BorderedFormItemWrap,
  BorderedIconWrap,
} from "./bordered-textfield.styles"

export type BorderedTextfieldProps = {
  name?: string
  type?: string
  rows?: number
  cols?: number
  textarea?: boolean
  placeholder?: string
  nullable?: boolean
  hasError?: boolean
  editLocked?: boolean
  hasSuccess?: boolean
  hasErrorMessage?: string | boolean
  noPasswordIcon?: boolean
  defaultValue?: string
  value?: string

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onTextAreaChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  validator?: (value: ValidateInputProps) => Promise<void>
  checkAvailability?: (value: string) => void
} & BorderedFormItemProps

export const BorderedTextfield = memo(
  ({
    name,
    type,
    rows,
    cols,
    textarea,
    onChange,
    onTextAreaChange,
    validator,
    placeholder,
    hasError,
    hasSuccess,
    hasErrorMessage,
    checkAvailability,
    value,
    defaultValue,
    ...props
  }: BorderedTextfieldProps) => {
    const [status, setStatus] = useState<StatusState>()
    const [, setValue] = useState("")

    const [passwordType, setPasswordType] = useState("password")

    useEffect(() => {
      const numberInput = document.querySelector(".numberInput")

      if (numberInput) {
        numberInput.addEventListener("keypress", function (evt) {
          const keyboardEvent = evt as KeyboardEvent

          const key =
            keyboardEvent.key || String.fromCharCode(keyboardEvent.keyCode)

          // Allow backspace
          if (key === "Backspace") {
            return
          }

          // Allow only numeric keys
          if (/[0-9]/.test(key)) {
            return
          }

          evt.preventDefault()
        })
      }
    }, [])

    const styleHasError = checkAvailability ? hasError : Boolean(status?.err)
    const styleHasSuccess = checkAvailability
      ? hasSuccess
      : Boolean(status?.success)

    useEffect(() => {
      if (hasError) {
        setStatus({ err: hasErrorMessage })
      }
    }, [hasError, hasErrorMessage])

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (hasError && hasErrorMessage) {
        return setStatus({ err: hasErrorMessage })
      } else {
        validator && validator({ value: e?.target?.value, setStatus })
      }
    }

    return (
      <BorderedFormItemWrap
        className="form-item-container"
        width={props.width}
        height={props.height}
        hasError={styleHasError}
        hasSuccess={styleHasSuccess}
        {...props}
      >
        <div className="input__wrapper">
          <BorderedFormItem name={name} lightMask={props.lightMask} {...props}>
            {textarea ? (
              <textarea
                defaultValue={defaultValue}
                placeholder={placeholder}
                rows={rows}
                cols={cols}
                onChange={e => {
                  if (onTextAreaChange) {
                    return onTextAreaChange(e)
                  }

                  setValue(e.target.value)

                  if (checkAvailability) {
                    return checkAvailability(e?.target?.value)
                  }

                  validator &&
                    validator({ rules: e, value: e?.target?.value, setStatus })
                }}
              />
            ) : (
              <input
                disabled={props.editLocked}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                className={classNames("", {
                  ["numberInput"]: type === "number",
                })}
                onChange={e => {
                  if (onChange) {
                    return onChange(e)
                  }

                  setValue(e.target.value)

                  if (checkAvailability) {
                    return checkAvailability(e?.target?.value)
                  }

                  validator &&
                    validator({ rules: e, value: e?.target?.value, setStatus })
                }}
                type={type === "password" ? passwordType : type || "text"}
                onBlur={handleBlur}
              />
            )}
          </BorderedFormItem>
          <div className="error__icons">
            <BorderedIconWrap height={props.height}>
              {type === "password" && !props.noPasswordIcon && (
                <div className="password__eye">
                  {passwordType === "password" ? (
                    <img
                      onClick={() => setPasswordType("type")}
                      src={showIcon}
                      alt="password show icon"
                    />
                  ) : (
                    <img
                      onClick={() => setPasswordType("password")}
                      src={hideIcon}
                      alt="password hide icon"
                    />
                  )}
                </div>
              )}
              {status?.err && <Cancel className="cancel" />}
              {status?.success && (
                <BsFillCheckCircleFill
                  className="check"
                  color="var(--successPrimary)"
                />
              )}
            </BorderedIconWrap>
          </div>
        </div>

        <p className="input__error__text">{status?.err}</p>
      </BorderedFormItemWrap>
    )
  }
)

BorderedTextfield.displayName = "BorderedTextfield"
