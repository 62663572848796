import { DatePicker } from "antd"
import styled from "styled-components"
import { FlexibleDiv } from "../Box/styles"

export interface DateSelectorWrapProps {
  width?: string
  color?: string
  border?: string
  height?: string
  margin?: string
  padding?: string
  background?: string
  borderColor?: string
  font_family?: string
  placeholder_color?: string

  small?: boolean
  hasError?: boolean
  hasSuccess?: boolean
  borderBottom?: string
}

export interface StyledDatePickerProps {
  width?: string
  color?: string
  height?: string
  margin?: string
  padding?: string
  borderColor?: string
  borderwidth?: string

  hasError?: boolean
  hasSuccess?: boolean
}

export const DateSelectorWrap = styled(FlexibleDiv)<DateSelectorWrapProps>`
  height: 45px;
  border: ${props =>
    props.borderBottom
      ? "none"
      : props.border ||
        "0.5px solid #707070"}; /* Default border if no borderBottom */
  border-bottom: ${props =>
    props.borderBottom || (props.border ? "inherit" : "0.5px solid #707070")};
  border-color: ${({ borderColor, hasError, hasSuccess }) => {
    return hasSuccess
      ? "var(--successPrimary)"
      : hasError
      ? "var(--primaryDanger)"
      : borderColor || "var(--dampWhiteLight)"
  }};

  .date__input__form__item {
    height: 100%;
    padding: 0 1rem;
    width: 100%;
    margin: 0;

    div {
      height: 100%;
    }
  }

  .ant-picker {
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 100%;
    padding: 0;
    color: ${props => props.color || "var(--primaryWhite)"};
    background: ${props => props.background || "transparent"};
    border: none;
    opacity: 1;
    box-sizing: border-box;
    box-shadow: none !important;
    font-size: 14px;
    border-color: ${props => props.borderColor || "var(--primaryWhite)"};
    text-align: left;
    transition: all 0.3s ease;
    outline: none;
    overflow: hidden;
  }

  .ant-picker-input {
    width: 100%;
    height: ${props => props.height || "100%"};
    background-color: transparent;
    outline: none;
    border: none;
    margin: -3px 0 0 0;
    color: ${props => props.color || "var(--primaryWhite)"};
    font-family: var(--mRegular);
    font-size: 14px;
    text-align: left;
    border: none;
    padding: ${props => props.padding || "0 0px"};

    svg {
      color: var(--primaryWhite);
      font-size: 13px;
    }
    ::before {
      display: none;
    }
    :active,
    :focus {
      ::placeholder {
        color: transparent;
      }
    }

    input {
      color: ${props => props.color || "var(--primaryWhite)"};
      font-family: ${({ font_family }) => font_family || "var(--mRegular)"};
      height: ${({ height }) => height || "100% !important"};
      font-size: ${({ small }) => (small ? "12px" : "14px")};

      &::placeholder {
        color: ${props => props.placeholder_color || "var(--dampWhiteLight)"};
        font-size: ${({ small }) => (small ? "12px" : "14px")};
        text-align: left;
        font-weight: 400;
        font-family: ${({ font_family }) => font_family || "var(--mRegular)"};
      }

      &:hover,
      &:active,
      &:focus,
      &:visited {
        box-shadow: none;
        background-color: transparent;
        border: none;
      }
    }

    .ant-picker-clear {
      background-color: transparent;
      right: 23px;

      svg {
        color: var(--uduxYellowPrimary);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .ant-picker-input {
      input {
        font-size: 0.75rem;

        ::placeholder {
          font-size: 0.75rem;
        }
      }
    }
  }
`

export const StyledDatePicker = styled(DatePicker)<StyledDatePickerProps>`
  width: ${({ width }) => width || "100% "};
  margin: ${({ margin }) => margin || "0 0 20px 0"};
  height: ${({ height }) => height || "40px"};
  padding: ${({ padding }) => padding || "0 5px"};
  /* opacity: 1; */
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;
  color: var(--primaryText);
  border-color: ${({ color, borderColor }) =>
    borderColor ? borderColor : color || "#E0DED3"};

  :hover,
  :active,
  :focus,
  :visited {
    border-color: ${({ color }) => color || "#E0DED3"};
    border-width: ${({ borderwidth }) => borderwidth || ".5px"};
    .ant-picker-clear {
      display: none;
    }
  }
  .ant-picker-clear {
    background: transparent;
    transform: scale(1);
  }
  svg {
    color: ${({ color }) => color || "#E0DED3"};
    margin-bottom: -4px;
    font-size: 18px;
  }
`
