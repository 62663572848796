import { PJWrap } from "./playlist-jumbotron.styles";
import { NewPlaylistIcon } from "assets/icons/NewPlaylistIcon";
import { CollabControls, StandardControls } from "./components";
import { CoverArt, Track } from "types";
import { PlaylistType } from "types/playlist";
import { useMediaQuery } from "react-responsive";

export type PlaylistJumbotronProps = {
  domColors?: string[];
  coverArt?: CoverArt;
  isPrivate?: boolean;
  title?: string;
  playlistId?: string;
  curator?: string;
  tracks?: Track[];
  description?: string;
  stats?: {
    likes: number;
    songs: number;
    id?: string;
    spins?: number;
    followers?: number;
  };
  type?: PlaylistType;
};
export const PlaylistJumbotron = ({
  domColors,
  coverArt,
  isPrivate,
  playlistId,
  title,
  tracks,
  description,
  stats,
  curator,
  type,
  ...props
}: PlaylistJumbotronProps) => {
  const hasCoverArt = !!coverArt?.url;
  const isMobile = useMediaQuery({ maxWidth: 994 });

  return (
    <PJWrap dominantColors={domColors}>
      <section className="playlist__content">
        <div className="jumbottron__top">
          <span className="subtile">
            {isPrivate ? "Private" : "Public"} Playlist
          </span>
          <h2 className="playlist__title">{title}</h2>
          <p className="playlist__description">{description}</p>
        </div>

        <div className="stats__display">
          <p className="curator">
            <span className="info">Curated by {curator ?? "uduX"}</span>
          </p>
          <div className="statistics__wrap">
            <p className="statistics">
              <span className="number">{stats?.songs}</span>
              <span className="info">Songs</span>
            </p>
            <p className="statistics">
              <span className="number">{stats?.likes}</span>
              <span className="info">Likes</span>
            </p>
            <p className="statistics">
              <span className="number">{stats?.spins}</span>
              <span className="info">Spins</span>
            </p>
          </div>
        </div>

        {type === PlaylistType.COLLABORATIVE ? (
          <CollabControls />
        ) : (
          !isMobile && <StandardControls  />
        )}

        <p className="mobile__playlist__description">{description}</p>
      </section>

      {!hasCoverArt ? (
        <div className="cover__art">
          <NewPlaylistIcon width={"100"} height={"80"} />
        </div>
      ) : (
        <aside className="cover__art">
          <img src={coverArt?.url} alt="cover art" />
        </aside>
      )}
    </PJWrap>
  );
};
