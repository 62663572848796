import { GenresData } from "utils";
import { GenresWrap } from "./genres.styles";
import { GenreCard } from "components";
import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";

export const Genres = () => {
  return (
    <PlayerLayout>
      <GenresWrap>
        {GenresData?.map((item, i) => {
          return <GenreCard key={i} {...item} />;
        })}
      </GenresWrap>
    </PlayerLayout>
  );
};
