import { Types, useMainContext } from "context"
import { changeUsername } from "network/user"
import { useMutation } from "react-query"
import { ChangeUsernamePayload } from "types"

// Custom hook for login service
export const useChangeUsernameService = () => {
  const { dispatch } = useMainContext()

  return useMutation(
    async (payload: ChangeUsernamePayload) => {
      return changeUsername({
        ...payload,
      })
    },
    {
      onSuccess: res => {
        if (res) {
          // Store user and tokens
          dispatch({ type: Types.CURRENT_USER, payload: res.data })
        }
      },
      onError: error => {
        // Dispatch the error to the global state
        dispatch({ type: Types.API_RESPONSE_ERROR, payload: error })
      },
    }
  )
}
