import styled from "styled-components"
import { FlexibleDiv } from "../Box"

export interface NDDWrapProps {
  transparentBng?: boolean
  height?: string
  width?: string
  containImage?: boolean
}

export const NDDWrap = styled(FlexibleDiv)<NDDWrapProps>`
  flex-direction: column;
  border: 0.5px solid var(--borderLight);
  height: ${({ height }) => height || "460px"};
  background-color: ${({ transparentBng }) =>
    transparentBng ? "transparent" : "var(--black)"};
  width: 100%;

  transition: all 0.3s ease-in-out;

  .no__display__image {
    width: ${({ width }) => width || "100px"};
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: ${({ containImage }) => (containImage ? "contain" : "cover")};
    }
  }

  .text__content {
    text-align: center;
    margin-top: 2.5rem;
    font-family: "Baguede", sans-serif;
  }

  .button__wrap {
    margin-top: 2rem;
    width: 200px;
  }

  @media screen and (max-width: 600px) {
    .button__wrap {
      width: 70%;

      button {
        padding: 0.4rem 1.5rem;
      }
    }

    .text__content {
      text-align: center;
      font-size: 0.85rem;
      max-width: 300px;
    }
  }
`
