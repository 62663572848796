import { FlexibleDiv } from "components"
import styled from "styled-components"

export const Container = styled(FlexibleDiv)`
  width: 100%;

  .form_content {
    width: 100%;

    .textfield__wrap {
      padding-bottom: 2rem;
    }

    .gender__input__wrap {
      margin-bottom: 1rem;
    }

    .gender__dropdown {
      @media only screen and (max-width: 765px) {
        width: 100% !important;
      }
    }

    .select_box {
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: flex-start;

      /* .input {
        width: calc(100% - 110px);
      } */

      .flags_img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: 23px;
      }

      .country-input {
        display: flex;
      }

      .country-name {
        display: none;
      }
    }
  }

  .btn__box {
    margin-top: 2%;
  }

  .existing__user {
    margin-top: 1rem;
    color: var(--white);
    opacity: 0.7;
    text-decoration: underline;
    font-size: 12px;
  }

  .terms__and__conditions {
    opacity: 0.8;

    label {
      color: var(--dampWhite);
    }

    .tac__text {
      margin-left: 1rem;
    }

    .tac {
      opacity: 0.8;
      font-size: 12px;
      margin-top: 1rem;
      color: var(--dampWhite);

      a {
        font-size: 12px;
        color: var(--dampWhite);
        text-decoration: underline;
      }
    }
  }

  .phone__input__wrap {
    margin: 1rem auto 1rem;
    height: 45px;

    #country__code__error {
      margin-top: 5px;
      color: var(--primaryDanger);
      font-size: 0.75rem;
      line-height: 1.2;
      text-align: left;
      margin: 0;
    }
  }

  .date__input__wrap {
    margin: 1rem auto 1rem;
  }

  @media only screen and (max-width: 649px) {
    .phone__input__wrap {
      margin: 1rem auto;
    }

    .terms__and__conditions {
      margin: 1rem auto;
      margin-top: 0;

      .tac__text {
        margin-left: 5px;
        font-size: 0.75rem;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    .phone__input__wrap {
      margin-bottom: 1rem;
    }

    .btn__box {
      margin-top: 4%;

      button {
        padding: 0.5rem;
        width: 60%;
        height: 40px;

        span {
          font-size: 12px;
        }
      }
    }

    .terms__and__conditions {
      opacity: 0.8;

      label {
        font-size: 8px;
      }

      .tac__text {
        margin-left: 3px;
        font-size: 8px;
      }

      .tac {
        font-size: 0.75rem;
        margin-top: 0.5rem;

        a {
          font-size: 0.75rem;
        }
      }
    }
  }
`
