import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout"
import { DiscoverWrap } from "./discover.styles"
import {
  ArrowCarousel,
  CampaignBanners,
  FlexibleDiv,
  Section,
} from "components"
import { Tags } from "components/lib/Tags/Tags"
import { useEffect, useState } from "react"
import camImage1 from "assets/images/general/campaign1.png"
import camImage2 from "assets/images/general/banner1.png"
import f1 from "assets/images/general/lottery-featured1.png"
import f2 from "assets/images/general/lottery-featured2.png"
import f3 from "assets/images/general/lottery-featured3.png"
import p1 from "assets/images/general/lottery-popular1.png"
import p2 from "assets/images/general/lottery-popular2.png"
import { Link } from "react-router-dom"
import { CampaignList } from "./components/CampaignList"
import { CollabRequestTab } from "./components/CollabRequests"
import { useMainContext } from "context"
import { useQuery } from "react-query"
import { getAllCampaigns } from "network"

const featuredLottery = [{ src: f1 }, { src: f2 }, { src: f3 }]
const popularLottery = [{ src: p1 }, { src: p2 }, { src: p1 }]

const campaigns = [camImage1, camImage2, camImage1]

export const Discover = () => {
  const [activeTag, setActiveTag] = useState<string>("Lottery")
  const {
    state: { app },
  } = useMainContext()
  const { data: lotteryCampaigns, isLoading: isCampLoading } = useQuery(
    ["lottery-campaigns"],
    getAllCampaigns
  )

  const { isLoggedIn } = app

  const handleTagChange = (newTag: string) => setActiveTag(newTag)

  const tags = [
    { title: "Music", href: "/" },
    { title: "Lottery", href: "" },
  ]

  if (isLoggedIn) {
    tags.push({ title: "Collab Request", href: "" })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PlayerLayout>
      <DiscoverWrap>
        <Section
          className="discover__header"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <h4 className="user__mini__subheader">Your Update</h4>
          <p className="discover__description">
            See all your latest collab invites, and lottery alerts in one place.
            Stay connected and never miss a beat!
          </p>
          <Tags
            width="fit-content"
            setActiveTag={handleTagChange}
            activeTag={activeTag}
            tags={tags}
          />
        </Section>
        {activeTag === "Lottery" && (
          <>
            <div className="main__campaigns">
              <CampaignBanners banners={campaigns} />
            </div>
            <div className="campaigns__body__section">
              {/* Featured Lottery */}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    Featured Lottery {lotteryCampaigns?.length}
                  </h4>
                  <Link to="#" className="see__more">
                    Show all{" "}
                  </Link>
                </FlexibleDiv>

                <ArrowCarousel
                  list={lotteryCampaigns}
                  slot={
                    <CampaignList
                      size="large"
                      loading={isCampLoading}
                      campaigns={lotteryCampaigns}
                    />
                  }
                />
              </FlexibleDiv>

              {/* Popular Lottery */}
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="header__withlink"
                >
                  <h4 className="mini__subheader">
                    Popular Right Now {lotteryCampaigns?.length}
                  </h4>
                  <Link to="#" className="see__more">
                    Show all{" "}
                  </Link>
                </FlexibleDiv>

                <ArrowCarousel
                  list={lotteryCampaigns}
                  slot={
                    <CampaignList
                      loading={isCampLoading}
                      campaigns={lotteryCampaigns}
                      size="large"
                    />
                  }
                />
              </FlexibleDiv>
            </div>
          </>
        )}
        {isLoggedIn && activeTag === "Collab Request" && (
          <Section className="campaigns">
            <CollabRequestTab />
          </Section>
        )}
      </DiscoverWrap>
    </PlayerLayout>
  )
}
