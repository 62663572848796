import { FlexibleDiv } from "components"
import styled from "styled-components"

export const MyCompassWrap = styled(FlexibleDiv)`
  background-color: var(--black);
  flex-direction: column;

  .main__wrap {
    form {
      width: 100%;
      display: flex;
      align-items: center;

      .input:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .section__info {
      width: 50%;
      padding-left: 8%;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: var(--pinkPrimary);
        -webkit-text-stroke: var(--w300);
        font-family: "Baguede", sans-serif;
      }

      .btn__wrap {
        width: 80%;
        justify-content: flex-start;
        margin-top: 3rem;

        button {
          min-width: 140px;
          max-width: 160px;
          width: 100%;
        }
      }
    }

    .form__section {
      width: 50%;
      padding: 2rem 8%;
      justify-content: flex-start;
    }
  }

  .top__layer {
    padding: 5rem 0;
  }

  .divider {
    margin-top: 2rem;
    border-top: 0.5px solid var(--borderLight);
  }

  .logout {
    padding: 2rem 0 4rem;

    &__section {
      align-items: flex-start;
      flex-direction: column;
    }

    .btn__box {
      margin-top: 1rem;
    }

    .logout__text {
      font-size: 0.75rem;
      color: var(--primaryText);
      opacity: 0.46;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    .main__wrap {
      form {
        width: 100%;
      }

      .section__info {
        width: 50%;
        padding-left: 8%;
        flex-direction: column;
        align-items: flex-start;

        .title {
          font-size: 1.75rem;
        }

        .btn__wrap {
          width: 80%;
          justify-content: flex-start;
          margin-top: 2.65rem;

          button {
            span {
              font-size: 1.3rem;
            }
            max-width: 150px;
            padding: 0.5rem 1rem;
            width: 80%;
          }
        }
      }

      .form__section {
        width: 50%;
        padding: 2rem 0;
        padding-right: 3.5rem;
        justify-content: flex-start;
      }
    }

    .top__layer {
      padding: 5rem 0;
    }

    .logout {
      .btn__box {
        margin-top: 1rem;

        button {
          span {
            font-size: 1.3rem;
          }
          max-width: 150px;
          padding: 0.5rem 1rem;
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .main__wrap {
      flex-direction: column;

      form {
        width: 100%;
        flex-direction: column;

        .input:not(:first-child) {
          margin-top: 2.5rem;
        }

        .btn__wrap__mobile {
          width: 100%;
          margin-top: 5rem;

          button {
            width: 80%;
          }
        }
      }

      .section__info {
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        align-items: center;

        .title {
          text-align: center;
        }

        .btn__wrap {
          display: none;
        }
      }

      .form__section {
        width: 100%;
        padding: 2rem;
      }
    }

    .top__layer {
      padding: 3rem 0 0rem;
    }

    .logout {
      padding: 3rem 0;

      &__section {
        align-items: center;
        flex-direction: column;
      }

      .btn__box {
        margin-top: 1rem;
        justify-content: center;

        button {
          width: 80%;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
`
