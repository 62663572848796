import GenreOrangePrint from "../../assets/images/general/genre-orange-print.png";
import GenreRedPrint from "../../assets/images/general/genre-red-print.png";

export const GenresData = [
  {
    title: "Afrobeat",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "Afropop",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
  {
    title: "Amapiano",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "Hip-Hop",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
  {
    title: "Rap",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "R&B",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
  {
    title: "Dancehall",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "Reggae",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
  {
    title: "Fuji",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "Pop",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
  {
    title: "Juju",
    gradientBgColor: "linear-gradient(90deg, rgba(150, 100, 0, 0.8), rgba(150, 100, 0, 0.6))",
    artboard: {
      url: GenreOrangePrint,
    },
  },
  {
    title: "Traditional",
    gradientBgColor: "linear-gradient(90deg, rgba(213, 54, 54, 0.8), rgba(213, 54, 54, 0.6))",
    artboard: {
      url: GenreRedPrint,
    },
  },
];

