import { PlayerState, Types, useMainContext } from "context";
import {
  getPlaylistFeatArtist,
  getPlaylistsByQuery,
  sendStreamData,
} from "network";
import React, { useCallback, useEffect } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import { StreamLogPayload, StreamLogSource } from "types";
import {
  addToQueue,
  getCurrentTrackID,
  pauseAudio,
  playNextTrack,
} from "utils";
import { getUserAgent } from "utils/function-helpers/userAgent";

export const AudioPlayer = () => {
  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();

  const {
    repeat,
    isPlaying,
    isPaused,
    playingList,
    prevTracks,
    queuedTracks,
    currentTrack,
    isPlayerMuted,
  }: PlayerState = player;

  const currentTrackTime: number | undefined = playerRef?.current?.currentTime;
  const shouldLoop = repeat === "repeatOne";
  const shouldLoopQueue = repeat === "loopQueue";

  const whilePlaying = () => {
    dispatch({
      type: Types.CURRENT_PLAYING_TIME,
      payload: playerRef?.current?.currentTime,
    });

    if (
      !app.isLoggedIn &&
      !window.location.pathname.includes("widget") &&
      currentTrackTime && // Ensure currentTrackTime is defined
      currentTrackTime > 25
    ) {
      dispatch({ type: Types.AUTH_STREAMING_WARNING, payload: true });
      pauseAudio(playerRef, dispatch);
    }
  };

  const fetchMoreTracks = useCallback(
    async (track: any, module: string) => {
      let tracksToAdd: any[] = [];

      if (module === "album" || module === "track") {
        let artist: any | undefined = undefined;
        track.forEach((t: any) => {
          artist = t?.metadata?.displayArtists.find(
            (da: any) => da?.isMainArtist
          )?.artist;
        });

        try {
          const res = await getPlaylistFeatArtist(
            artist?.id
            // "sortByPopularity=asc"
          );

          tracksToAdd = res.length
            ? res.flatMap((r: any) => r.tracks).slice(0, 10)
            : [];
        } catch (error) {
          console.log(error);
        }
      }
      if (module === "playlist") {
        try {
          const res = await getPlaylistsByQuery("managedBy=ADMIN");

          tracksToAdd = res.length
            ? res.flatMap((r: any) => r.tracks).slice(0, 10)
            : [];
        } catch (error) {
          console.log(error);
        }
      }

      addToQueue(dispatch, tracksToAdd);
    },
    [dispatch]
  );

  return (
    <div style={{ display: "none" }}>
      {currentTrack?.file?.url && (
        // ||
        // currentTrack?.track?.file?.url
        <ReactHlsPlayer
          loop={shouldLoop}
          controls={false}
          // volume={volume ** 2}
          playerRef={playerRef}
          muted={isPlayerMuted}
          onPlay={handleOnPlay}
          onEnded={handleOnEnded}
          onTimeUpdate={whilePlaying}
          autoPlay={isPaused || isPlaying}
          src={
            currentTrack?.file?.url
            // || currentTrack?.track?.file?.url
          }
        />
      )}
    </div>
  );

  async function handleOnPlay() {
    if (isPlaying && queuedTracks.length < 3) {
      const module: string = playingList?.type?.toLowerCase() ?? "track";
      fetchMoreTracks(queuedTracks, module);
    }

    const payload: StreamLogPayload = {
      source: StreamLogSource.WEB,
      userAgent: getUserAgent(),
      track: getCurrentTrackID(currentTrack),
    };

    try {
      await sendStreamData(payload);
    } catch (error) {
      console.error(error);
    }
  }

  

  function handleOnEnded() {
    if (shouldLoopQueue && queuedTracks.length > 0) {
      const isLastTrack = queuedTracks[queuedTracks.length - 1].id === currentTrack?.id;
      const newPrevTracks = [...prevTracks, currentTrack]; // Add current track to prevTracks
  
      // If it's the last track in the queue, replace queued tracks with prev tracks
      if (isLastTrack) {
        // Move all tracks from prevTracks back to the queue
        dispatch({
          type: Types.REPLACE_QUEUED_TRACKS,
          payload: newPrevTracks, // New queued tracks are from prevTracks
        });
  
        // Play the first track from prevTracks (now in queuedTracks)
        playNextTrack(dispatch, {
          track: newPrevTracks[0],
          checkAutoEnd: true,
        });
  
        // Clear prevTracks to avoid infinite loop
        dispatch({
          type: Types.REPLACE_PREV_TRACKS,
          payload: [],
        });
      } else {
        // If it's not the last track, just play the next track in the queue
        playNextTrack(dispatch, {
          track: currentTrack,
          checkAutoEnd: true,
        });
      }
    } else {
      // If not looping, continue to the next track
      playNextTrack(dispatch, {
        track: currentTrack,
        checkAutoEnd: true,
      });
    }
  }
  
};
