import {
  FavoriteFilledIcon,
  FsePausePlayIcon,
  FsePlayingIcon,
  LikeIcon,
  NextOutlineIcon,
  PrevOutlineIcon,
  RepeatOneIcon,
  RepeatOutlineIcon,
  ShuffleOutlineIcon,
} from "assets";
import {
  AddToPlaylistModal,
  FlexibleDiv,
  TrackProgressControl,
} from "components";
import { Types, useMainContext } from "context";
import { isEmpty } from "lodash";
import { addReaction, errorResponseHandler, getTrackById, removeReaction, successResponseHandler } from "network";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getDisplayArtist,
  getImageDisplay,
  getTrackID,
  getTrackTitle,
  pauseAudio,
  playAudio,
  playNextTrack,
  playPreviousTrack,
  repeatTrack,
  shuffleTrack,
  unShuffleTrack,
} from "utils";
import { FSEMPlayerWrap } from "./fsem-player.styles";
import { ReactionType, Track } from "types";
import { PlusIcon } from "assets/images/player/SVGExports";
import { useMediaQuery } from "react-responsive";
import { useMutation } from "react-query";

export type FSEMPlayerProps = {
  width?: string;
};
export const FSEMPlayer = ({ width }: FSEMPlayerProps) => {
  const { pathname, state: rState } = useLocation();
  const trackId = pathname.split("/player/")[1];
  const isMobile = useMediaQuery({ maxWidth: 994 });
  const [liked, setLiked] = useState(false);

  const {
    state: { player, app },
    playerRef,
    dispatch,
  } = useMainContext();
  const { isLoggedIn } = app;
  const [openAddToPlaylistModal, setOpenAddToPlaylistModal] = useState(false);
  const { currentTrack,queuedTracks, isPlaying, shuffle, repeat } = player;
  const shouldGetTrack = isEmpty(currentTrack) && Boolean(trackId);
  const [repeatMode, setRepeatMode] = useState<
    "default" | "loopQueue" | "repeatOne"
  >("default");
  const { mutate: mutateLike } = useMutation(addReaction);
  const { mutate: mutateUnlike } = useMutation(removeReaction);
  const getCurrentTrackById = useCallback(async () => {
    const res = await getTrackById(trackId);
    dispatch({ type: Types.SET_CURRENT_PLAYING, payload: res });
  }, [trackId, dispatch]);

  const handleRepeatClick = () => {
    let updatedRepeatMode: "default" | "repeatOne" | "loopQueue";
    // Determine the updated repeatMode based on the current state
    if (repeatMode === "default") {
      updatedRepeatMode = "repeatOne";
    } else if (repeatMode === "repeatOne") {
      updatedRepeatMode = "loopQueue";
    } else {
      updatedRepeatMode = "default";
    }
    // Immediately call repeatTrack with the updated repeatMode and loopMode
    repeatTrack(
      dispatch,
      updatedRepeatMode // Pass the updated repeatMode
    );

    // Set the new repeatMode state after calling repeatTrack
    setRepeatMode(updatedRepeatMode);
  };

  useEffect(() => {
    if (shouldGetTrack) {
      getCurrentTrackById();

      dispatch({
        type: Types.REPLACE_QUEUED_TRACKS,
        payload: rState?.queuedTracks,
      });
      dispatch({
        type: Types.REPLACE_PREV_TRACKS,
        payload: rState?.prevTracks,
      });
      dispatch({
        type: Types.SET_PLAYING_PLAYLIST,
        payload: rState?.playingList,
      });
    }
  }, [shouldGetTrack, dispatch, rState, getCurrentTrackById]);
  useEffect(() => {
    if (currentTrack && currentTrack.userLiked !== undefined) {
      setLiked(currentTrack.userLiked);
    }
  }, [currentTrack]);

  return (
    <FSEMPlayerWrap width={width} isMobile={isMobile}>
      <FlexibleDiv className="fsem__wrap">
        <FlexibleDiv className="track__image__wrap">
          <img
            src={getImageDisplay(currentTrack as Track)}
            alt={`${getTrackTitle(currentTrack, true)} cover`}
          />
        </FlexibleDiv>

        <FlexibleDiv className="track__title__control">
          <FlexibleDiv flexDir="column" className="track-title">
            <p className="title">{getTrackTitle(currentTrack, true)}</p>
            <p className="artist__wrap">{getDisplayArtist(currentTrack)}</p>
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv flexDir="column" className="overal__controls">
          <FlexibleDiv
            flexWrap="nowrap"
            width="100%"
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div
              className="control"
              onClick={() => {
                setOpenAddToPlaylistModal(true);
                console.log({ openAddToPlaylistModal });
              }}
            >
              <PlusIcon />
            </div>

            <FlexibleDiv
              alignItems="center"
              className="track__controls__wrap"
              flexDir="column"
            >
              <FlexibleDiv justifyContent="center" className="controls__main">
                <div
                  className="control__icons repeat__icon"
                  onClick={handleRepeatClick}
                >
                  {repeatMode === "loopQueue" ? (
                    <RepeatOutlineIcon
                      width={25}
                      height={25}
                      color={"var(--uduxYellowPrimary)"}
                    />
                  ) : repeatMode === "repeatOne" ? (
                    <RepeatOneIcon width={25} height={25} />
                  ) : (
                    <RepeatOutlineIcon
                      width={25}
                      height={25}
                      color={"#D9D9D9"}
                    />
                  )}
                </div>

                <div
                  className="control__icons"
                  onClick={() => {
                    if (currentTrack) {
                      playPreviousTrack(dispatch, {
                        track: currentTrack,
                      });
                    }
                  }}
                >
                  <PrevOutlineIcon width={25} height={25} />
                </div>

                {isPlaying ? (
                  <div
                    className="control__icons pause__icon"
                    onClick={() => pauseAudio(playerRef, dispatch)}
                  >
                    <FsePlayingIcon width={25} height={25} />
                  </div>
                ) : (
                  <div
                    className="control__icons play__icon"
                    onClick={() => playAudio(playerRef, dispatch)}
                  >
                    <FsePausePlayIcon width={25} color="#fff" height={25} />
                  </div>
                )}

                <div
                  className="control__icons"
                  onClick={() => {
                    playNextTrack(dispatch, {
                      track: currentTrack,
                    });
                  }}
                >
                  <NextOutlineIcon width={25} height={25} />
                </div>
                {shuffle ? (
                    <div className="control__icons shuffle__icon"
                    onClick={()=>unShuffleTrack(
                      queuedTracks,
                      dispatch
                    )}
                    >
                      <ShuffleOutlineIcon
                        width={25}
                        height={25}
                        color="var(--yellowPrimary)"
                      />
                    </div>
                  ) : (
                    <div className="control__icons shuffle__icon"
                    onClick={()=>shuffleTrack(
                      queuedTracks,
                      dispatch
                    )}
                    >
                      <ShuffleOutlineIcon width={25} height={25} />
                    </div>
                  )}
              </FlexibleDiv>
            </FlexibleDiv>

            {liked ? (
              <div className="control__icons" onClick={handleUnlikeTrack}>
                <FavoriteFilledIcon width={25} height={25} />
              </div>
            ) : (
              <div className="control__icons" onClick={handleLikeTrack}>
                <LikeIcon width={25} height={25} />
              </div>
            )}
          </FlexibleDiv>

          {isMobile && (
            <FlexibleDiv className="song__progress__wrap">
              <TrackProgressControl displayProgressTime />
            </FlexibleDiv>
          )}
        </FlexibleDiv>
      </FlexibleDiv>

      {openAddToPlaylistModal && (
        <AddToPlaylistModal
          open={openAddToPlaylistModal}
          handleClose={() => setOpenAddToPlaylistModal(false)}
        />
      )}
    </FSEMPlayerWrap>
  );

  // function handleAddToMultiplePlaylists() {
  //   if (!isLoggedIn) {
  //     return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
  //   }

  //   const payload = {
  //     playlists: checkedPlaylists,
  //     tracks: [
  //       {
  //         sequence: 0,
  //         track: currentTrack?.track?.id,
  //       },
  //     ],
  //   };

  //   mutate(payload, {
  //     onSuccess: (res) => {
  //       refetch();
  //       successResponseHandler(res);
  //       setCheckedPlaylists([]);
  //     },
  //     onError: (err) => errorResponseHandler(err),
  //   });
  // }

  function handleUnlikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(false);
    const payload = {
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateUnlike(payload, {
      onSuccess: (res) => {
        successResponseHandler(res);
      },
      onError: (err) => {
        setLiked(false);
        errorResponseHandler(err)
      },
    });
  }

  function handleLikeTrack() {
    if (!isLoggedIn) {
      return dispatch({ type: Types.SET_PRIVATE_ACTION, payload: true });
    }

    setLiked(true);
    const payload = {
      type: ReactionType.LIKE,
      entity: getTrackID(currentTrack),
      entityType: "Track",
    };

    mutateLike(payload, {
      onSuccess: (res) => {
        console.log(res, "liked from mini player");
      },
      onError: (err) => {
        setLiked(false);
        console.error(err, "error liking from mini player");
      },
    });
  }
};
