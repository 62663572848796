import { BorderedTextfield, Button, FlexibleDiv } from "components"
import { MySettingsWrap } from "./my-settings.styles"
import { useState } from "react"
import { useDeactivateAccountService } from "network/hooks/use-deactivate-account"

export const MySettings = () => {
  const [reason, setReason] = useState("")
  const { mutate, isLoading } = useDeactivateAccountService()

  return (
    <MySettingsWrap>
      <FlexibleDiv className="main__wrap top__layer">
        <FlexibleDiv className="section__info">
          <h2 className="title">Account Settings</h2>
          <p className="info">
            Note: You'll logged off the app once this action is complete, to
            re-activate your account please send us an email at your earliest.
          </p>

          <FlexibleDiv className="textarea">
            <BorderedTextfield
              small
              value={reason}
              name="reason"
              textarea
              height="5em"
              padding="2px"
              placeholder="Why do you want to deactivate your account?"
              onTextAreaChange={e => {
                setReason(e.target.value)
              }}
            />
          </FlexibleDiv>

          <FlexibleDiv className="btn__wrap">
            <Button
              background="var(--primaryDanger)"
              color="var(--primaryWhite)"
              radius="0"
              loading={isLoading}
              onClick={() => {
                mutate({ reason })
              }}
            >
              <span>Deactivate Account</span>
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </MySettingsWrap>
  )
}
