import React, { useEffect, useState } from "react"
import { FlexibleDiv } from "components"
import { BarChart } from "../charts/bar-chart"
import { TotalMonthAndCount } from "types"

export const PlaylistLikeChart: React.FC<{ list?: TotalMonthAndCount[] }> = ({
  list,
}) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const processedData = list
    ? list.map(item => ({
        ...item,
        month: monthNames[item.month - 1] || "Unknown", // Convert month number to name
      }))
    : []

  const [chartData, setChartData] = useState({
    labels: processedData.map(item => item.month),
    datasets: [
      {
        label: "",
        data: processedData.map(item => item.count),
        backgroundColor: "#5F2A29",
        borderWidth: 1,
        borderColor: "#5F2A29",
      },
    ],
  })

  const options = {
    responsive: true,
    maintainAspectRation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff",
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          color: "#fff",
        },
        grid: {
          display: false,
        },
      },
    },
  }

  useEffect(() => {
    const handleResize = () => {
      // Force a re-render on resize
      setChartData({ ...chartData })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [chartData])

  return (
    <FlexibleDiv>
      {processedData.length > 0 ? (
        <BarChart data={chartData} options={options} />
      ) : (
        <span>No Data</span>
      )}
    </FlexibleDiv>
  )
}
