import { PlayerLayout } from "components/layouts/PlayerLayout/PlayerLayout";
import { TopAlbumWrap } from "./top-album.styles";
import { CampaignBanners, FlexibleDiv, MediaCard } from "components";
import { useFetchPublicAlbum } from "network/hooks";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import camImage1 from "assets/images/general/campaign2.png";

const campaigns = [camImage1];
export const TopAlbum = () => {
  const navigate = useNavigate();
  const { data: albData, isLoading } = useFetchPublicAlbum(
    ["public-albums"] // Cache key for public albums
  );
  return (
    <PlayerLayout>
      <TopAlbumWrap>
        <div className="campaigns">
          <CampaignBanners banners={campaigns} />
        </div>
        <FlexibleDiv alignItems="flex-start" className="header">
          <h4 className="subheader">Top Album</h4>
        </FlexibleDiv>
        <div className="top__albums">
          {isLoading ? (
            <FlexibleDiv minHeight="300px">
              <Skeleton active={isLoading} />
            </FlexibleDiv>
          ) : (
            albData?.map((alb, index) => {
              return (
                <MediaCard
                  rank={index + 1}
                  type="album"
                  album={alb}
                  key={index}
                  handleNavigate={() =>
                    navigate(`/playlist/${alb.id}?t=alb`, {
                      state: {
                        module: "album",
                      },
                    })
                  }
                />
              );
            })
          )}
        </div>
      </TopAlbumWrap>
    </PlayerLayout>
  );
};
