import { FlexibleDiv } from "components/lib/Box"
import styled from "styled-components"

export interface TLIWrapProps {
  minimize?: boolean
  collab?: boolean
}

export const TLIWrap = styled(FlexibleDiv)<TLIWrapProps>`
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding: 1.5rem 1rem;
  height: 73px;
  transition: all 0.2s ease-in;
  /* border-bottom: ${({ minimize }) =>
    minimize
      ? "0.1px solid var(--borderVLight)"
      : "0.5px solid var(--borderLight)"}; */

  .inner__border__bottom {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: ${({ minimize }) =>
      minimize
        ? "0.1px solid var(--borderVLight)"
        : "0.5px solid var(--borderLight)"};
  }

  &:hover {
    background-color: rgba(251, 186, 18, 0.2) !important;

    .player__icon__wrap {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.8);

      svg {
        opacity: 1;
      }
    }

    .mobile__display__only {
      .mobile__title__and__album {
        .mobile__artist {
          color: var(--uduxGrayLight);
        }
      }
    }

    .number__idx {
      color: var(--uduxGrayLight);
    }
  }

  .number__idx {
    width: 2%;
    height: 100%;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    color: var(--primaryGray);
  }

  .name__and__cover {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 2;
  }

  .cover__art {
    width: 45px;
    height: 45px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .player__icon__wrap {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;

    svg {
      opacity: 0;
      transition: opacity 0.5s ease-in;
    }
  }

  .is__playing__track {
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: var(--overlayMain);

    svg {
      opacity: 1;
      display: inline-flex;
    }
  }

  .title__and__album {
    margin-left: 2rem;

    p {
      margin: 0;
    }

    .title {
      font-family: "Bw Modelica", sans-serif;
      font-size: 1rem;

      width: 140px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .artist,
  .spins,
  .album {
    flex: 2;
    font-size: 1rem;
    text-transform: capitalize;
    font-family: "Bw Modelica", sans-serif;
    width: 140px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (max-width: 765px) {
      display: none;
    }
  }

  .title__and__album__mini {
    margin-left: 2rem;
    display: flex;
    // border: 2px solid red;
    flex-direction: column;

    p {
      margin: 0;
    }

    .title {
      font-family: "Bw Modelica", sans-serif;
      font-size: 1rem;

      // width: 140px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .artist-mini {
    font-size: 0.65rem;
    text-transform: capitalize;
    font-family: "Bw Modelica", sans-serif;
  }

  .duration {
    flex: 1;
    text-align: center;
    font-family: "Bw Modelica", sans-serif;

    @media screen and (max-width: 765px) {
      display: none;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    flex-wrap: ${({ collab }) => (collab ? "nowrap" : "nowrap")};
    column-gap: ${({ collab }) => (collab ? "8px" : "10px")};
    justify-content: space-evenly;
    color: var(--primaryGray);

    .suggestion__add__btn {
      border: 0.5px solid #c5c5c5;
      color: #d9d9d9;
      min-width: 34px;
      font-family: var(--mRegular);
      padding: 5px 20px;
      text-align: center;
      min-height: 19px;

      &:hover {
        background-color: var(--tb5);
      }
    }

    svg {
      path {
        transition: all 0.3s ease-in;
      }

      :hover {
        path {
          fill: var(--uduxYellowPrimary);
        }
      }
    }

    /* collab actions */
    .collab_action_icon {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 5px;
      background-color: #c9ff79;
      display: flex;
      align-items: center;
      justify-content: center;

      .collab_icon {
        color: #000000;
        cursor: pointer;
        font-weight: 900;
      }
    }

    .red_collab_action_icon {
      background-color: red;
    }
  }

  .mobile__display__only {
    width: 55%;
    height: 45px;
    display: flex;
    align-items: center;

    .number__idx {
      display: none;
    }

    .name__and__cover {
      flex: 0;
    }

    .mobile__title__and__album {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 0.5rem;
      flex-grow: 1;
      height: 100%;

      p {
        margin: 0;
      }

      .mobile__artist {
        font-size: 10px;
        color: var(--primaryGray);
      }
    }
  }

  .icons__wrap {
    height: 100%;
    cursor: pointer;
  }

  .add__action {
    padding: 2px 8px;
    min-width: 60px;
    display: flex;
    font-size: smaller;
    align-items: center;
    align-items: center;
    justify-content: center;
    font-family: "Bw Modelica", sans-serif;
    border: 0.5px solid var(--dampWhiteLight);

    svg {
      font-size: 1rem;
      margin-left: 0.2rem;
    }

    :hover {
      border: 0.5px solid var(--dampWhite);
      color: var(--dampWhite);
    }
  }

  .added {
    border-color: var(--yellowDeep);
    color: var(--yellowDeep);
  }

  @media screen and (max-width: 600px) {
    padding: 0.2rem 0.6rem;

    .number__idx {
      color: var(--uduxGrayLight);
      display: none;
    }

    :hover {
      color: var(--secondaryWhite);

      .duration,
      .actions {
        color: var(--uduxGrayLight);
      }
    }

    .duration {
      flex: 1;
      text-align: center;
      color: var(--uduxGrayLight);
    }

    .actions {
      flex-grow: 1;
      color: var(--primaryGray);
    }

    .mobile__display__only {
      flex: 2;

      .number__idx {
        display: none;
      }

      .name__and__cover {
        flex: 0;
      }

      .mobile__title__and__album {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 0.5rem;
        flex-grow: 1;
        height: 100%;

        p {
          margin: 0;
          width: 100px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: "Bw Modelica", sans-serif;
        }

        .mobile__artist {
          font-size: 10px;
          font-family: "Bw Modelica", sans-serif;
          color: var(--uduxGrayLight);
        }
      }
    }

    .title__and__album__mini {
      margin-left: 1rem;
    }
  }
`

export const TLIHeaderWrap = styled(FlexibleDiv)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1.5rem 1rem;
  height: 73px;
  transition: all 0.2s ease-in;
  @media screen and (max-width: 765px) {
    display: none;
  }
  .heading {
    text-transform: uppercase;
  }

  .number__idx {
    width: 2%;
    height: 100%;
    display: flex;
    margin-right: 1rem;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    color: var(--primaryGray);
  }

  .name__and__cover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0.8;
    flex: 2;
    text-transform: uppercase;
  }

  .artist,
  .spins,
  .album {
    flex: 2;
    font-size: 1rem;
    font-family: "Bw Modelica", sans-serif;
    width: 140px;
    overflow: hidden;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    opacity: 0.8;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .duration {
    flex: 1;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.8;
    font-family: "Bw Modelical", sans-serif;
  }

  .actions {
    flex: 1;
    display: flex;
    opacity: 0.8;
    justify-content: space-evenly;
    color: var(--primaryGray);
  }
`
