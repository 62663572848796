import { DominantColors, Gender, SocialLinks, SourceService } from "context"
import { ReactionType } from "./social"
import { StreamLogSource } from "./stream"
import { StringNumberUnion } from "./misc"
// import { PlaylistStatus } from "./playlist";

export type SignUpPayload = {
  gender: Gender
  password: string
  username: string
  dateOfBirth: string
  countryCode: string
  phoneNumber: string
  emailAddress: string
  sourceService: SourceService
  acceptedTermsAndConditions: boolean

  firstName?: string
  lastName?: string
  redirectUrl?: string
  isMTNRegistration?: boolean
  isBuyingStreamPass?: string
}

export type LoginPayload = {
  password: string
  redirectUrl: string
  emailAddress: string
  isBuyingStreamPass: string
  sourceService: SourceService

  lat?: number
  lng?: number
}

export type ResetPasswordRequestPayload = {
  emailAddress: string
  redirectUrl?: string
  isBuyingStreamPass?: string
  sourceService: SourceService
}

export type VerifyOTPPayload = {
  otp: string
  phoneNumber: string
  redirectUrl?: string
  isBuyingStreamPass?: string
  sourceService: SourceService
}

export type InitiatePaymentPayload = {
  billingPlanId: string
  emailAddress: string
  callbackUrl?: string
  successUrl?: string
  failedUrl?: string
  cancelUrl?: string
}

export type ChangePasswordPayload = {
  password: string
  oldPassword: string
}

export type ResetPasswordConfirmTokenPayload = {
  token: string
  emailAddress: string
}

export type UpdatePasswordPayload = {
  password: string
  emailAddress: string
}

export type ConfirmEmailUpdateStatus = {
  redirectUrl?: string
  emailAddress: string
  confirmEmailToken: string
  isBuyingStreamPass?: string
  sourceService: SourceService
}

export type UpdateUserPayload = {
  bio?: string
  gender?: Gender
  coverArt?: string
  lastName?: string
  firstName?: string
  dateOfBirth?: string
  phoneNumber?: string
  countryCode?: string
  profilePicture?: string
  socialLinks?: SocialLinks
  countryOfResidence?: string
  coverArtDominantColors?: DominantColors
  profilePictureDominantColors?: DominantColors
}

export type PreSignedURLPayload = {
  fileName: string
  category: UploadCategory
  shouldNotPrefixFileName?: boolean
}

export enum UploadCategory {
  TRACKS = "tracks",
  PROFILE_PICTURES = "profile-pictures",
  ARTWORKS = "artworks",
  OTHER = "other",
}

export type AddAsFavoritePayload = {
  type: ReactionType
  entityType: string
  entity: string
}

export type RemoveFavoritePayload = Omit<AddAsFavoritePayload, "type">

export type FeaturedArtistPayload = {
  isMainArtist: boolean
  sequence: number
  artist: string
  id: string
}

export type SoloPlaylistPayload = {
  title: string
  // status: PlaylistStatus;
  // coverArt: string;
  // artist: string;
  description?: string
  // coverArtDominantColors: DominantColors;
  // featuredArtists: FeaturedArtistPayload[];
  tracks: TrackPayload[]
  // moods: Array<string>;
  // tags: Array<string>;
  // descriptionAsSummary: boolean;
  // releaseDate: string;
}

export type UpdateSoloPlaylistPayload = {
  tracks?: TrackPayload[]
  coverArt?: string
  coverArtDominantColors?: DominantColors
  description?: string
}

export type StandardPlaylistPayload = {
  coverArt?: string
  description?: string
  title: string
  type: string
}

export type CollabPlaylistPayload = {
  private: boolean
  title: string
  description?: string
}

export type AddTrackToCollabPlaylistPayload = {
  private?: boolean
  title?: string
  tracks: [TrackPayload]
}

export type TrackPayload = {
  sequence?: number
  track?: string
  id?: string
}

export type AddToMultiplePlaylistsPayload = {
  tracks: TrackPayload[]
  playlists: StringNumberUnion[]
}

export type StreamLogPayload = {
  track?: string
  playlist?: string
  userAgent?: string
  createdAt?: string
  updatedAt?: string
  streamTime?: number
  playlistTrack?: string
  source?: StreamLogSource
}

export type OAuthPayload = {
  agent: AuthAgent
  accessID: string
  sourceService: SourceService
  redirectUrl: string
}

export type AuthAgent = "UDUX_WEB_AGENT" | "UDUX_MOBILE_AGENT"

export type DeactivateAccountPayload = {
  reason: string
}

export type ChangeUsernamePayload = {
  newUsername: string
}
