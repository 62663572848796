import {
  useCollabRequestsReceivedService,
  useCollabRequestsSentService,
} from "network/hooks"
import { CollabRequestTabWrap } from "./collab-request-tab.styles"
import { CollabRequestItem } from "./CollabRequestItem"
import {
  CollabPlaylistTabHeader,
  Loader,
  NoDataDisplay,
  TabContent,
} from "components"
import { useState } from "react"
import { MdCallReceived, MdCallMade } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

export const CollabRequestTab = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("#received")
  const { data: rrData, isLoading: rrLoading } =
    useCollabRequestsReceivedService()
  const { data: srData, isLoading: srLoading } = useCollabRequestsSentService()

  const invitesRequestTabs = [
    {
      icon: <MdCallReceived />,
      title: "Received",
      hash: "#received",
    },
    {
      icon: <MdCallMade />,
      title: "Sent",
      hash: "#sent",
    },
  ]

  return rrLoading || srLoading ? (
    <Loader />
  ) : (
    <CollabRequestTabWrap>
      <CollabPlaylistTabHeader
        width="100%"
        activeTab={activeTab}
        tabs={invitesRequestTabs}
        padding="0.2rem"
        setActiveTab={tab => setActiveTab(tab)}
      />

      <TabContent>
        {renderReceivedInvites()}
        {renderSentInvites()}
      </TabContent>
    </CollabRequestTabWrap>
  )

  function renderSentInvites() {
    if (activeTab === "#sent") {
      if (srData?.data?.length) {
        return srData?.data.map(sr => {
          const daysLeft = dayjs(sr.expires).get("days")

          return (
            <CollabRequestItem
              collabId={sr.id}
              key={sr.id}
              status={sr.status}
              text={
                <>
                  <b>{sr.sender.username}</b> invited you to{" "}
                  <b
                    className="playlist__title"
                    onClick={() => navigate(`/playlist/${sr.playlist.id}?t=pl`)}
                  >
                    {sr.playlist.title}
                  </b>{" "}
                  playlist
                </>
              }
              timestamp={dayjs(sr.createdAt).fromNow()}
              expiry={`expires in ${daysLeft} ${daysLeft > 1 ? "days" : "day"}`}
            />
          )
        })
      } else {
        return <NoDataDisplay text="You have not sent out any invites yet." />
      }
    }
  }

  function renderReceivedInvites() {
    if (activeTab === "#received") {
      if (rrData?.data?.length) {
        return rrData?.data.map(rr => {
          const daysLeft = dayjs(rr.expires).get("days")

          return (
            <CollabRequestItem
              collabId={rr.id}
              key={rr.id}
              status={rr.status}
              text={
                <>
                  <b>{rr.sender.username}</b> invited you to{" "}
                  <b
                    className="playlist__title"
                    onClick={() => navigate(`/playlist/${rr.playlist.id}?t=pl`)}
                  >
                    {rr.playlist.title}
                  </b>{" "}
                  playlist
                </>
              }
              timestamp={dayjs(rr.createdAt).fromNow()}
              expiry={`expires in ${daysLeft} ${daysLeft > 1 ? "days" : "day"}`}
            />
          )
        })
      } else {
        return <NoDataDisplay text="You have not received any invites yet." />
      }
    }
  }
}
