import { GenreCWrap } from "./genre-card.styles";

export type GenreCardProps = {
  title?: string;
  artboard?: {
    url: string;
  };
  gradientBgColor?: string;
};
export const GenreCard = ({
  title,
  artboard,
  gradientBgColor,
}: GenreCardProps) => {
  return (
    <GenreCWrap artboard={artboard} gradientBgColor={gradientBgColor}>
      <span>{title}</span>
    </GenreCWrap>
  );
};
