import { CollabPlaylistTabHeader, TabContent } from "components"
import { useEffect, useState } from "react"
import { BsGraphUpArrow } from "react-icons/bs"
import { IoMusicalNotes } from "react-icons/io5"
import { MdLeaderboard } from "react-icons/md"
import { PiMusicNotesPlusBold } from "react-icons/pi"
import { CollabPlaylistOrganizerWrap } from "./collab-playlist-organizer.styles"
import { CollabSongsTabDisplay, CollabStatsTabDisplay } from "./components"
import { CollabLeaderboardTabDisplay } from "./components/LeaderboardTab/CollabLeaderboardTabDisplay"
import { CollabTabDisplay } from "./components/CollabTab/CollabTabDisplay"
import { Playlist } from "types/playlist"
import { Track } from "types"

export type CollabPlaylistTabRoutes = typeof collabPlaylistTabRoutes
const collabPlaylistTabRoutes = [
  {
    icon: <IoMusicalNotes />,
    title: "Songs",
    hash: "#songs",
  },
  {
    icon: <BsGraphUpArrow />,
    title: "Stats",
    hash: "#stats",
  },
  {
    icon: <MdLeaderboard />,
    title: "Leaderboard",
    hash: "#leaderboard",
  },
  {
    icon: <PiMusicNotesPlusBold />,
    title: "Collaborators",
    hash: "#collaborators",
  },
]

export type CollabPlaylistOrganizerProps = {
  isOwner: boolean
  playlist?: Playlist
  suggestedTracks?: Track[]
}

export const CollabPlaylistOrganizer = ({
  playlist,
  isOwner,
  suggestedTracks,
}: CollabPlaylistOrganizerProps) => {
  const [activeTab, setActiveTab] = useState("#songs")
  const activePlaylist = playlist ? playlist.editors.length > 1 : false

  useEffect(() => {
    const currentUrl = window.location.href.split("#")[0] // get the current URL without any hash
    const newUrl = `${currentUrl}${activeTab}` // append the activeTab
    window.history.replaceState(null, "", newUrl)
  }, [activeTab])

  return (
    <CollabPlaylistOrganizerWrap>
      <CollabPlaylistTabHeader
        width="100%"
        activeTab={activeTab}
        tabs={collabPlaylistTabRoutes}
        padding="0.2rem"
        setActiveTab={tab => setActiveTab(tab)}
      />

      <TabContent>
        {activeTab === "#songs" && (
          <CollabSongsTabDisplay
            playlist={playlist}
            isOwner={isOwner}
            suggestedTracks={suggestedTracks}
          />
        )}
        {activeTab === "#stats" && <CollabStatsTabDisplay />}
        {activeTab === "#leaderboard" && (
          <CollabLeaderboardTabDisplay isActive={activePlaylist} />
        )}
        {activeTab === "#collaborators" && <CollabTabDisplay />}
      </TabContent>
    </CollabPlaylistOrganizerWrap>
  )
}
