import { getCollabPlaylistStats } from "network/playlist"
import { useQuery, useQueryClient } from "react-query"
import { ResponseCollabPlaylistStats } from "types"

export const useCollabPlaylistStatsService = (collabPlaylistId?: string) => {
  const queryClient = useQueryClient()

  return useQuery<ResponseCollabPlaylistStats>(
    ["collab-playlist-stats", collabPlaylistId], // Cache key
    () => getCollabPlaylistStats(collabPlaylistId), // Query with dynamic filters
    {
      enabled: !!collabPlaylistId,
      initialData: () => {
        // Check if data is cached already
        const cachedData =
          queryClient.getQueryData<ResponseCollabPlaylistStats>([
            "collab-playlist-stats",
            collabPlaylistId,
          ])
        return cachedData
      },
      staleTime: 5 * 60 * 1000, // 5 minutes cache freshness time
      cacheTime: 10 * 60 * 1000, // 10 minutes cache lifespan
    }
  )
}
