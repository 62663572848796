import { Modal } from "antd"
import { FlexibleDiv } from "components"
import { DominantColors } from "context"
import styled from "styled-components"

export interface FJWrapProps {
  bg?: string
  dominantColors?: DominantColors
}
export const FJWrap = styled(FlexibleDiv)<FJWrapProps>`
  height: 320px;
  padding: 0 5%;
  justify-content: space-between;
  background: ${({ bg }) =>
    bg
      ? `linear-gradient(0deg, rgba(13, 8, 8, 0.65), rgba(0, 0, 0, 0.96)), url(${bg})`
      : "var(--gradientPrimary)"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: ${({ dominantColors }) =>
    dominantColors?.length
      ? `10px solid ${dominantColors[0]}`
      : "10px solid var(--gradientPrimary)"};

  .info-section,
  .cta-section {
    width: 40%;
  }

  .info-section {
    align-items: flex-start;

    .title {
      color: var(--primaryText);
      font-size: 2rem;
    }

    .lead-text {
      font-size: 1rem;
    }
  }

  .cta-section {
    justify-content: flex-end;

    button {
      min-width: 200px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 220px;
    padding: 1rem;
    justify-content: center;

    .info-section,
    .cta-section {
      width: 90%;
      align-items: center;
      justify-content: center;
    }

    .info-section {
      .title {
        color: var(--primaryText);
        font-size: 1.5rem;
      }

      .lead-text {
        text-align: center;
      }
    }
  }
`

export const FDWrap = styled(FlexibleDiv)<FJWrapProps>`
  margin-top: 1rem;
  position: relative;

  :after {
    position: absolute;
    content: "";
    top: 23%;
    width: 50%;
    height: 80%;
    background: ${({ bg }) =>
      bg &&
      `linear-gradient(0deg, rgba(13, 8, 8, 0.88), rgba(0, 0, 0, 0.96)), url(${bg})`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .feed-data-display {
    justify-content: flex-start;
    gap: 3%;
    z-index: 10;
  }

  .header-info {
    width: 100%;
    justify-content: flex-start;
    margin: 30px 0px 10px 0px;

    .your-x {
      color: var(--primaryText);
      font-size: 1.35rem;
    }
  }

  .see__all {
    padding-right: 0.5rem;
    text-decoration: underline;
    cursor: pointer;

    :hover {
      color: var(--uduxYellowPrimary);
    }
  }

  .category__filter {
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    &__content {
      width: 35%;
    }
  }

  .invite_notice_box {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    height: 45px;
    padding: 0px 100px 0px 0px;
    margin: 20px 0px 40px 3px;
    background-color: #2daea2;
    color: #fcfcfc;

    p {
      flex: 1;
      padding-top: 16px;
    }

    img {
      margin: 0 30px 0 20px;
      height: 16px;
      width: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .category__filter {
      &__content {
        width: 70%;
      }
    }

    .see__all {
      font-size: 0.65rem;
    }

    .feed-data-display {
      display: grid;
      overflow-y: hidden;
      /* grid-template-columns: repeat(2, 1fr); */

      .invite_notice_box {
        p {
          font-size: 14px;
        }
      }
    }
  }
`

export const CPDWrap = styled(Modal)`
  .ant-modal-content {
    color: var(--dampWhiteLight);
    background-color: var(--uduxGraySecondary);

    .ant-modal-close-x {
      color: var(--dampWhiteLight);
    }
  }

  .ant-modal-header {
    color: var(--primaryWhite);
    background-color: var(--uduxGraySecondary);
    border-bottom: 0.5px solid var(--primaryGray);
  }

  .ant-modal-title {
    text-transform: capitalize;
    color: var(--primaryWhite);
    font-family: "Baguede", sans-serif;
  }

  .textfield__wrap {
    padding-bottom: 1.5rem;
  }

  .btn__box {
    margin-top: 2rem;
  }
`
