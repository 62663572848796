import { FlexibleDiv } from "components";
import styled from "styled-components";

export const PlOrgHelperWrap = styled(FlexibleDiv)`
  @media screen and (max-width: 765px) {
    padding: 0 20px;
  }
  .explore__title {
    font-weight: 700;
    font-weight: "Bw Modelica";
    font-size: 1.2rem;
    text-align: left;
    margin: 3rem 0rem 0.8rem 0rem;
    line-height: 38px;
    width: 100%;
    white-space: nowrap;
    @media screen and (max-width: 765px) {
      font-size: 0.9rem;
      margin: 0.6rem 0rem 0.8rem 0rem;
    }
  }
  .mapped__content {
    column-gap: 30px;
    overflow-y: auto;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      display: none;
    }

    h5 {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 29px;
      color: var(--uduxWhitePrimary);
      font-family: "Inter", sans-serif;
    }

    a {
      color: var(--uduxWhitePrimary);
      font-size: 12px;
      text-decoration: underline;
    }

    .playlist {
      width: 700px;
      min-width: 700px;
      height: 390px;
      display: flex;
      padding: 40px;
      align-items: flex-end;
      column-gap: 40px;

      .vibes__digest__wrap{
        height: calc(100% - 80px);

        .vibes__tracks__wrap{
            width: 100%;
        }
      }

    }
  }
`;

export const PlOrgTrackItemWrap = styled(FlexibleDiv)`
  width: 100%;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-wrap: nowrap;
  border-bottom: 0.5px solid #282828;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .image__info__wrap {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex: 0;
    flex-wrap: nowrap;
    column-gap: 20px;
    width: 200px;
    min-width: 200px;

    .image__wrap {
      width: 45px;
      min-width: 45px;
      min-height: 45px;
      height: 45px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info__wrap {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 5px;

      p {
        margin-bottom: 0;
      }

      .title {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        font-size: 0.7rem;
      }
      .artist {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        opacity: 0.6;
        font-size: 0.7rem;
      }
      .id {
        font-family: "Bw Modelica", sans-serif;
        font-size: 0.8rem;
        color: var(--primaryYellow);
      }
    }
  }

  .no__of__plays {
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    small {
      font-size: 0.8rem;
      color: var(--primaryWhite);
    }
  }

  .track__duration {
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    small {
      font-size: 0.8rem;
      color: var(--primaryYellow);
    }
  }
`;
