import { IconProps } from "types";

export const RepeatOneIcon = ({
  color = "#FBBA12",
  width,
  height,
}: IconProps & { fullScreen?: boolean }) => (
  <svg
    width={width || "35"}
    height={height || "24"}
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.793 1L26.8793 4.82032L22.793 8.64064"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5177 4.82031H7.81047C6.00588 4.82535 4.27674 5.4978 3.0007 6.69079C1.72465 7.88378 1.00539 9.50037 1 11.1875V12.461"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8968 22.6485L7.81055 18.8281L11.8968 15.0078"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.17188 18.8301H26.8791C28.6837 18.8251 30.4128 18.1526 31.6889 16.9596C32.9649 15.7666 33.6842 14.15 33.6896 12.4629V11.1895"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.99 15C16.99 15.1381 17.1019 15.25 17.24 15.25H18C18.1381 15.25 18.25 15.1381 18.25 15V8C18.25 7.86193 18.1381 7.75 18 7.75H17.41C17.3588 7.75 17.3088 7.76573 17.2668 7.79506L14.8568 9.47885C14.8016 9.51744 14.7643 9.57669 14.7533 9.64317C14.7424 9.70966 14.7587 9.77774 14.7987 9.832L15.1887 10.3617C15.2686 10.4703 15.4201 10.4959 15.5313 10.4197L16.99 9.42029V15Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
      strokeLinejoin="round"
    />
  </svg>
);
