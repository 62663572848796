import { FlexibleDiv } from "components"
import styled from "styled-components"

export const PlaylistWrap = styled(FlexibleDiv)`
  padding-bottom: 0;
  position: relative;

  .mobile__filter__and__controls {
    display: none;
    margin: 1rem 0;
    padding: 0 20px;
    flex-wrap: nowrap;
    gap: 20px;
    @media screen and (max-width: 765px) {
      display: flex;
      .mobile__search__input {
        width: 90%;
        max-width: 400px;
        padding: 10px 5px;
        flex-wrap: nowrap;
        flex-direction: row;
        border: 0.5px solid var(--borderMain);

        > input {
          border: none;
          outline: none;
          width: 90%;
          padding-left: 5px;
          color: var(--primaryWhite);
          background-color: transparent;
          font-family: "Bw Modelica", sans-serif;
        }
      }
    }
  }

  .tracks__added__wrap {
    width: 100%;
    @media screen and (max-width: 765px) {
      background: linear-gradient(
        180deg,
        rgba(26, 49, 37, 0.2) 0%,
        #030304 100%
      );
      padding: 0 20px;
    }
    .filter__playlist__box {
      margin: 3.5rem 0 2rem 0;
      width: 100%;
      padding: 0 3rem 0 0;
      @media screen and (max-width: 765px) {
        justify-content: flex-end;
        margin: 1rem 0;
        padding: 1rem 0;

        .search__input {
          max-width: 280px;
          padding: 5px;

          > input {
            font-size: 0.85rem;
          }
        }

        .organize__by {
          padding: 7px 2px;
          width: 307px;
          max-width: 150px;

          > p {
            margin: 0;
            font-size: 0.65rem;
            font-family: "Bw Modelica", sans-serif;
            color: rgba(217, 217, 217, 0.7);
          }

          > img {
            width: 13px;
            margin-right: 5px;
          }
        }

        /* .display__result {
          padding: 1rem 0 2rem;

          :not(:first-child) {
            margin: 1rem auto 2rem;
          }

          .result__title {
            margin-bottom: 1rem;
          }

          .heading__text {
            font-size: 1rem;
          }
        } */
      }
      .search__input {
        width: 50%;
        max-width: 300px;
        padding: 10px 5px;
        flex-wrap: nowrap;
        flex-direction: row;
        border: 0.5px solid var(--borderMain);
        @media screen and (max-width: 765px) {
          display: none;
        }

        > input {
          border: none;
          outline: none;
          width: 90%;
          padding-left: 5px;
          color: var(--primaryWhite);
          background-color: transparent;
          font-family: "Bw Modelica", sans-serif;
        }
      }

      .organize__by {
        cursor: pointer;
        display: flex;
        border: 0.5px solid var(--borderLight);
        padding: 10px 2px;
        width: 307px;
        max-width: 150px;

        > p {
          margin: 0;
          font-family: "Bw Modelica", sans-serif;
          color: rgba(217, 217, 217, 0.7);
          white-space: nowrap;
        }

        > img {
          width: 18px;
          margin-left: 15px;
        }
      }
    }
  }

  .no__tracks__added__wrap {
    width: 100%;
    @media screen and (max-width: 765px) {
      background: linear-gradient(
        180deg,
        rgba(26, 49, 37, 0.2) 0%,
        #030304 100%
      );
      padding: 0 20px;
    }

    .filter__playlist__box {
      display: none;
      @media screen and (max-width: 765px) {
        display: flex;
        justify-content: flex-end;
        margin: 1rem 0;
        padding: 1rem 0;
      }

      .organize__by {
        cursor: pointer;
        display: flex;
        border: 0.5px solid var(--borderLight);
        padding: 10px 2px;
        width: 307px;
        max-width: 150px;

        > p {
          margin: 0;
          font-family: "Bw Modelica", sans-serif;
          color: rgba(217, 217, 217, 0.7);
          white-space: nowrap;
        }

        > img {
          width: 18px;
          margin-left: 15px;
        }
      }
    }

    .suggestion__title,
    .perfect__title {
      font-weight: 700;
      font-weight: "Bw Modelica";
      font-size: 1.2rem;
      text-align: left;
      margin: 3rem 0rem 0.8rem 0rem;
      line-height: 38px;
      width: 100%;
      white-space: nowrap;
      @media screen and (max-width: 765px) {
        font-size: 0.9rem;
        margin: 0.6rem 0rem 0.8rem 0rem;
      }
    }

    .perfect__title {
      margin-bottom: 1rem;
      text-align: center;
    }

    .search__input__no__tracks {
      width: 50%;
      max-width: 550px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      margin: 0 auto;
      flex-direction: row;
      border: 0.5px solid var(--borderMain);
      @media screen and (max-width: 765px) {
        width: 80%;
      }
      > input {
        border: none;
        outline: none;
        width: 90%;
        padding-left: 5px;
        color: var(--primaryWhite);
        background-color: transparent;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .playlist--search {
    width: 50%;
    .search__input {
      width: 70%;
      max-width: 380px;
      padding: 10px 5px;
      flex-wrap: nowrap;
      flex-direction: row;
      border: 0.5px solid var(--borderMain);

      > input {
        border: none;
        outline: none;
        width: 90%;
        padding-left: 5px;
        background-color: transparent;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .display__result {
    justify-content: flex-start;
    overflow: hidden;
    padding: 1rem 0 2rem;

    :not(:first-child) {
      margin: 1rem auto 3rem;
    }

    .result__title {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 1rem;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        color: var(--primaryText);
        font-size: 1.35rem;
        text-align: left;
      }
    }

    .heading__text {
      color: white;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }

  .atp__display__result {
    justify-content: space-between;
    overflow: hidden;
    padding: 1rem 0 2rem;

    :not(:first-child) {
      margin: 1rem auto 2rem;
    }

    .result__title {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1rem;

      h2 {
        color: var(--primaryText);
        font-size: 1.35rem;
        text-align: left;
      }
    }
  }

  .category__filter {
    width: 100%;
    margin-bottom: 4rem;
    justify-content: space-between;

    &__content {
      width: 140px;
    }
  }

  .response__banner__wrap {
    padding: 1rem;
    height: 80px;
    position: relative;
    align-items: center;
    margin-top: -1.5rem;
  }
`

interface TrackListWrapProps {
  pt?: string
  bgImg?: string
  mt?: string
}
export const TrackListWrap = styled(FlexibleDiv)<TrackListWrapProps>`
  display: flex;
  padding-top: ${({ pt }) => pt || "4rem"};
  margin-top: ${({ mt }) => mt || "0rem"};
  margin-bottom: 4rem;
  flex-direction: column;
  width: 100%;

  .sorry__message {
    color: var(--uduxGrayLight);
    opacity: 0.8;
    font-family: var(--mRegular);
  }
`
