import { FlexibleDiv, FlexibleDivProps } from "components"
import styled from "styled-components"

interface ManageQueueWrapProps extends FlexibleDivProps {
  activeTab?: boolean
  isMobile?: boolean
}
export const ManageQueueWrap = styled(FlexibleDiv)<ManageQueueWrapProps>`
  width: 100%;
  overflow-y: hidden;
  z-index: 9;
  margin: 1.5rem auto 2rem auto;

  .tabchange__wrap__mobile {
    justify-content: space-between;
    margin: 10px 0;

    h4 {
      font-size: 15px;
      color: var(--primaryText);
      margin: 0;
      white-space: nowrap;
      font-family: "Bw Modelica", sans-serif;
    }

    p {
      color: var(--primaryText);
      font-size: 14px;
      white-space: nowrap;
      font-family: "Bw Modelica", sans-serif;
    }

    .tab__mobile {
      padding: 4px;
      border: 0.5px solid var(--uduxGrayLightAlpha);
      width: max-content;
      transition: all 0.3s ease-in;

      p {
        margin: 0;
        padding: 4px 10px;
        transition: all 0.3s ease;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: "Bw Modelica", sans-serif;
      }

      .currenttab {
        color: var(--black);
        background-color: var(--uduxYellowPrimary);
      }
    }

    @media screen and (min-width: 950px) {
      display: none;
    }
  }

  .tabchange__wrap__web {
    margin-bottom: 15px;
    h4 {
      font-size: 15px;
      color: var(--primaryText);
      margin: 0;
      white-space: nowrap;
      font-family: var(--mBold);
    }

    p {
      color: var(--primaryText);
      font-size: 14px;
      white-space: nowrap;
      font-family: var(--mLight);
    }
    .tab__web {
      padding: 4px;
      width: max-content;
      border: 0.5px solid var(--uduxGrayLightAlpha);
      transition: all 0.3s ease-in;

      p {
        margin: 0;
        padding: 4px 10px;
        transition: all 0.3s ease;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: var(--mLight);
      }
      .currenttab {
        color: var(--black);
        background-color: var(--uduxYellowPrimary);
      }
    }

    @media (max-width: 1240px) {
      flex-direction: column;
      align-items: flex-end;

      .headertext__wrap {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  .managequeue__contentwrap {
    height: calc(100vh - 420px);
    justify-content: flex-start;
    overflow-y: auto;
    row-gap: 0;
  }

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    overflow: hidden;
    margin: 0;

    .managequeue__contentwrap {
      margin-top: 220px;
      height: calc(100vh - 200px);
      width: 100%;
      max-width: 100%;
      margin-top: 10px;
    }
  }

  @media (max-height: 740px) and (max-width: 950px) {
    .tabchange__wrap__mobile {
      height: 180px;

      h4 {
        font-size: 14px;
      }
      .tab__mobile {
        p {
          font-size: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .tabchange__wrap__mobile {
      height: 60px;

      h4 {
        font-size: 14px;
      }
      .tab__mobile {
        p {
          font-size: 10px;
        }
      }
    }

    .managequeue__contentwrap {
      height: auto;
      justify-content: flex-start;
      overflow-y: auto;
      margin-top: 0;
      max-width: 100%;
      row-gap: 0;
    }
  }
`
