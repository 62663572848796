import { Playlist } from "types/playlist"
import { InActivePlaylist } from "../InActivePlaylist"
import { CollabSongsTabDisplayWrap } from "../styles"
import { FlexibleDiv, NoDataDisplay, TrackListItem } from "components"
import { Dropdown, Menu } from "antd"
import { BiSearchAlt2 } from "react-icons/bi"
import { TrackListWrap } from "pages/Playlist/playlist.styles"
import OrganizeSvg from "assets/images/general/organize.svg"
import { useState } from "react"
import { QueueIcon } from "assets/images/player/SVGExports"
import { addToQueue, playTrack } from "utils"
import { useMainContext } from "context"
import { Track } from "types"
import { SearchTracks } from "components/lib/SearchTracks"
import { useSearchTracksService } from "network/hooks"
import { useAddTrackToCollabPlaylist } from "network/hooks/use-add-track-to-playlist"

export type CollabSongsTabDisplayProps = {
  isOwner: boolean
  playlist?: Playlist
  suggestedTracks?: Track[]
}
export const CollabSongsTabDisplay = ({
  isOwner,
  playlist,
  suggestedTracks,
}: CollabSongsTabDisplayProps) => {
  const ploTracks = playlist?.tracks
  const [, setOrganizePlaylist] = useState("custom")
  const tracksAddedToPlaylist =
    (playlist?.tracks && playlist.tracks.length > 0) || false
  const [searchValue, setSearchValue] = useState("")
  const { dispatch, playerRef } = useMainContext()
  const { data, isLoading } = useSearchTracksService(searchValue, {
    enabled: !!searchValue,
  })
  const { mutate: addToPlaylistMutation } = useAddTrackToCollabPlaylist()
  const isPlaylistActive = playlist ? playlist.editors.length > 1 : false

  const handleOrganizePlaylist = (type: string) => {
    setOrganizePlaylist(type)
  }

  const searchResults = data && data.data.length

  const organizeDetailsMenu = (
    <Menu
      style={{ backgroundColor: "#000000", border: "1px solid #0F0F0F" }}
      items={[
        {
          key: "1",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("custom")}
            >
              <div>
                <QueueIcon width={18} />
              </div>
              <p>Custom Order</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("title")}
            >
              <QueueIcon width={18} />
              <p>Title</p>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("creator")}
            >
              <QueueIcon width={18} />
              <p>Creator</p>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("album")}
            >
              <QueueIcon width={18} />
              <p>Album</p>
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div
              className="menu_box"
              onClick={() => handleOrganizePlaylist("duration")}
            >
              <QueueIcon width={18} />
              <p>Duration</p>
            </div>
          ),
        },
      ]}
    />
  )

  const handleAddTrackToPlaylist = (trackId: string) => {
    if (playlist) {
      addToPlaylistMutation({
        collabId: playlist.id,
        title: playlist.title,
        tracks: [
          {
            id: trackId,
          },
        ],
      })
    }
  }

  return (
    <CollabSongsTabDisplayWrap>
      {!tracksAddedToPlaylist && (
        <div className="no__tracks__added__wrap">
          <FlexibleDiv
            className="filter__playlist__box"
            justifyContent="flex-end"
          >
            <Dropdown
              trigger={["click"]}
              overlay={organizeDetailsMenu}
              placement="bottomLeft"
            >
              <FlexibleDiv className="organize__by" alignItems="center">
                <p>Organize by</p>
                <img src={OrganizeSvg} alt="filter icon" />
              </FlexibleDiv>
            </Dropdown>
          </FlexibleDiv>
          <h3 className="perfect__title">
            The perfect playlist is just clicks away...
          </h3>

          <SearchTracks handleSearch={value => setSearchValue(value)} />
        </div>
      )}

      {!tracksAddedToPlaylist && (
        <div className="no__tracks__added__wrap">
          {searchResults && (
            <TrackListWrap
              pt="0rem"
              mt="4rem"
              bgColor="none"
              bgImg={playlist?.coverArt?.url}
              className="tracks__display"
            >
              {data && data.data.length > 0 ? (
                data.data.map((track, idx) => (
                  <TrackListItem
                    key={track.id}
                    handlePlayTrack={handlePlayTrack}
                    idx={idx}
                    track={track}
                    searchTrackListItem
                    fullView
                    {...track}
                  />
                ))
              ) : data && data.data.length === 0 ? (
                <NoDataDisplay
                  height="250px"
                  text="No track matched your search."
                />
              ) : (
                <FlexibleDiv className="sorry__message">
                  Sorry, This playlist has no songs.
                </FlexibleDiv>
              )}
            </TrackListWrap>
          )}

          {/* Showing suggested tracks for empty playlist */}
          <>
            <h3 className="suggestion__title">
              Some suggestions for your playlist
            </h3>
            <TrackListWrap
              pt="0rem"
              bgImg={playlist?.coverArt?.url}
              className="tracks__display"
            >
              {suggestedTracks && suggestedTracks.length > 0 ? (
                suggestedTracks.map((track, idx) => (
                  <TrackListItem
                    key={track.id}
                    handlePlayTrack={handlePlayTrack}
                    handleAddToPlaylist={() =>
                      handleAddTrackToPlaylist(track.id)
                    }
                    idx={idx}
                    track={track}
                    fullView
                    {...track}
                  />
                ))
              ) : ploTracks && ploTracks.length === 0 ? (
                <NoDataDisplay
                  height="250px"
                  text="No track matched your search."
                />
              ) : (
                <FlexibleDiv className="sorry__message">
                  Sorry, This playlist has no songs.
                </FlexibleDiv>
              )}
            </TrackListWrap>
          </>
        </div>
      )}

      {!isPlaylistActive && (
        <InActivePlaylist
          title="Your Collab Playlist isn't Active"
          description={
            isOwner
              ? "You won't be able to add songs to this playlist till someone says yes!"
              : "Start adding your favourite tracks"
          }
        />
      )}
    </CollabSongsTabDisplayWrap>
  )

  function handlePlayTrack(payload: any) {
    playTrack(dispatch, playerRef, payload)
    // playTrack(dispatch, playerRef, { track: payload, entity })

    if (playlist?.tracks) {
      addToQueue(dispatch, playlist.tracks)
    }
  }
}
