import { FlexibleDiv } from "components";
import { DominantColors } from "context";
import styled from "styled-components";

export const HistoryWrap = styled(FlexibleDiv)`
  .history__header {
    .subheader {
      flex-basis: 50%;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 29px;
      color: var(--primaryWhite);
      font-family: "Inter", sans-serif;
      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
  }

  .filter__history__box {
    margin: 10px 0;
    .searchinput__wrap {
      height: 45px;
      overflow: hidden;
      width: 270px;
      position: relative;
      align-items: center;
      padding: 0 0.5rem;
      flex-wrap: nowrap;
      transition: all 0.3s ease;
      justify-content: flex-start;
      transition: all 0.3s ease;
      border: 0.5px solid var(--uduxGrayLightAlpha);

      .searchinput__icon__wrap {
        cursor: pointer;
        transition: all 0.3s ease;
        width: max-content;
        margin-right: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        :hover {
          transform: scale(1.1);
        }
      }

      .search__input {
        flex: 1;
        outline: 0;
        height: 100%;
        border: none;
        font-size: 0.85rem;
        padding-left: 0.5rem;
        color: var(--textPrimary);
        background-color: transparent;
        font-family: "Bw Modelica", sans-serif;
      }
    }
  }

  .content__wrap {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 120px;
    align-items: flex-start;
    overflow: auto;
    height: 550px;
    min-height: 550px;
    .history__track__list__wrap {
      flex: 2;
      height: 400px;
    }
    .explore__vibes__wrap {
      flex: 1;
      width: 400px;
      min-width: 400px;
      height: 400px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 20px;

    .content__wrap {
      column-gap: 0;
    }
    .history__track__list__wrap {
      flex: 1;
      width: 100%;
      min-width: 100%;
      height: 100%;
    }
    .explore__vibes__wrap {
      display: none;
    }
  }

  @media (max-width: 765px) {
    .filter__history__box {
      .searchinput__wrap {
        width: 100%;
        height: 35px;
      }
    }
  }
`;

export interface ExploreVibesWrapProps {
  dominantColors?: DominantColors;
}
export const ExploreVibesWrap = styled(FlexibleDiv)<ExploreVibesWrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 20px;
  padding: 20px 10px 20px 20px;
  background: ${({ dominantColors }) =>
    dominantColors?.length
      ? `linear-gradient(180deg, #000 0%, #${dominantColors[0]} 100%)`
      : "#0b0b0a"};

  h4 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 29px;
    color: var(--uduxWhitePrimary);
    font-family: "Inter", sans-serif;
  }

  h5 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 29px;
    color: var(--uduxWhitePrimary);
    font-family: "Inter", sans-serif;
  }

  a {
    color: var(--uduxWhitePrimary);
    font-size: 12px;
    text-decoration: underline;
  }
`;

export const VibesTrackItemWrap = styled(FlexibleDiv)`
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-wrap: nowrap;
  border-bottom: 0.5px solid #282828;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .image__info__wrap {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex: 0;
    flex-wrap: nowrap;
    column-gap: 20px;
    width: 200px;
    min-width: 200px;

    .image__wrap {
      width: 80px;
      min-width: 80px;
      min-height: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info__wrap {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 5px;

      p {
        margin-bottom: 0;
      }

      .title {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        font-size: 1rem;
      }
      .artist {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        opacity: 0.6;
        font-size: 0.8rem;
      }
      .id {
        font-family: "Bw Modelica", sans-serif;
        font-size: 0.8rem;
        color: var(--primaryYellow);
      }
    }
  }

  .no__of__plays {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    small {
      font-size: 1rem;
      color: var(--primaryWhite);
    }
  }

  .track__duration {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    small {
      font-size: 1rem;
      color: var(--primaryYellow);
    }
  }

  @media screen and (max-width: 600px) {
  }
`;

export const HTLIWrap = styled(FlexibleDiv)`
  width: 100%;
  padding: 1.2rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-wrap: nowrap;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .image__info__wrap {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex: 0;
    flex-wrap: nowrap;
    column-gap: 20px;
    width: 200px;
    min-width: 200px;

    .image__wrap {
      width: 80px;
      min-width: 80px;
      min-height: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info__wrap {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 5px;

      p {
        margin-bottom: 0;
      }

      .title {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        font-size: 1rem;
      }
      .artist,
      .type {
        white-space: nowrap;
        text-transform: capitalize;
        font-family: "Bw Modelica", sans-serif;
        opacity: 0.6;
        font-size: 0.8rem;
      }
    }
  }

  .last__played__date {
    white-space: nowrap;
    text-transform: capitalize;
    font-family: "Bw Modelica", sans-serif;
    opacity: 0.6;
  }

  .play__btn {
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 150px;
    min-width: 150px;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease, transform 0.3s ease;

    .icon__wrap {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
      background: var(--primaryYellow);
    }
  }

  .actions__wrap {
    flex: 1;
    justify-content: flex-end;
    flex-wrap: nowrap;
    column-gap: 20px;

    .remove__icon {
      font-size: 12px;
      width: fit-content;
    }
  }

  @media screen and (max-width: 1024px) {
    .image__info__wrap {
      width: 200px;
      min-width: 200px;
      .image__wrap {
        width: 60px;
        min-width: 60px;
        min-height: 60px;
        height: 60px;
      }

      .title {
        font-size: 0.8rem;
      }
      .artist,
      .type {
        font-size: 0.6rem;
      }
    }

    .last__played__date {
      display: none;
    }

    .play__btn {
      flex: 1;
      width: 50px;
      min-width: 50px;
    }

    .actions__wrap {
      width: 100px;
      min-width: 100px;
      align-items: flex-end;
      justify-content: flex-end;

      .remove__icon {
        font-size: 16px;
      }
    }
  }
`;