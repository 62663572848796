import { FlexibleDiv } from "components"
import styled from "styled-components"

export type StatsCardWrapProps = {
  bg?: string
  hoverColor?: string
  hideDesc?: boolean
}
export const StatsCardWrap = styled(FlexibleDiv)<StatsCardWrapProps>`
  background-color: ${({ bg }) => bg || "var(--secondaryBlack)"};
  width: 100%;
  padding: 1rem 2rem;
  height: 400px;
  align-items: ${({ hideDesc }) => (hideDesc ? "center" : "flex-start")};

  .header {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 1rem;

    .title__desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .total {
        font-size: 3rem;
      }
      .subtitle {
        font-size: 0.85rem;
      }
    }
  }

  .chart__layer {
    flex: 1;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem;
    height: 300px;

    .header {
      .title__desc {
        .total {
          font-size: 2.5rem;
        }
        .subtitle {
          font-size: 0.65rem;
        }
      }
    }
  }
`
